import { IEcommerceItemList } from '../analytics.types';
import productToEcommerceItem from './product-to-ecommerce-item.helper';

import { IProduct } from 'modules/product/product.types';

/**
 * Converts a product's suggested pairing list into a google analytics ecommerce item list
 */
export default function suggestedProductToEcommerceItemList(
	product: IProduct,
): IEcommerceItemList {
	const itemListId = `suggested-pairing-${product}`;
	return {
		item_list_id: itemListId,
		item_list_name: `${product} suggested pairing list`,
		items:
			product.suggestedPairingList?.suggestedProducts.map((suggestedProduct) =>
				productToEcommerceItem(suggestedProduct, itemListId),
			) || [],
	};
}
