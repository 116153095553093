import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import {
	isMenuAvailable,
	isMenuVisible,
} from './helpers/menu-availability.helper';
import { IMenu, IMenuState } from './menu.types';

import { TServiceType } from 'modules/basket/basket.types';

// Create initial state
export const initialMenuState: IMenuState = {
	eventsInProgress: 0,
	isSearchVisible: false,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
	currentCategoryId: null,
};

const menuSlice = createSlice({
	name: 'menu',
	initialState: initialMenuState,
	reducers: {
		SET_ACTIVE_MENU(state, action) {
			return {
				...state,
				activeMenu: action.payload,
			};
		},
		GET_MENUS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_MENUS_SUCCESS(state: IMenuState, action) {
			// If we have retrieved menus
			// And we have an active menu which is included in the retrieved menus
			// Then we need to update the active menu to make sure we are
			// displaying the correct information in the UI.
			// Otherwise we will have a stale active menu.
			// This was causing users to not see updated stock state for items if the refreshed the page
			const activeMenuId = state.activeMenu?.id;
			let updatedActiveMenu;
			if (activeMenuId) {
				const activeMenu = action.payload?.data?.find(
					(menu: IMenu) => menu.id === activeMenuId,
				);
				if (activeMenu) {
					updatedActiveMenu = activeMenu;
				}
			}

			return {
				...state,
				menus: action.payload?.data || [],
				eventsInProgress: state.eventsInProgress - 1,
				activeMenu: updatedActiveMenu || state.activeMenu,
			};
		},
		GET_MENUS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_MENU_STATE() {
			return { ...initialMenuState };
		},
		SET_IS_SEARCH_VISIBLE(state, action) {
			return {
				...state,
				isSearchVisible: action.payload,
			};
		},
		UPDATE_AVAILABLE_MENUS(state, action) {
			return {
				...state,
				availableMenus:
					state.menus
						?.filter((menu: IMenu) =>
							isMenuVisible(menu, action.payload.serviceType),
						)
						.map((menu: IMenu) => ({
							...menu,
							isAvailable: isMenuAvailable(
								menu,
								action.payload.timeslot,
								action.payload.serviceType,
							),
						})) || [],
			};
		},
		SET_CURRENT_CATEGORY_ID(
			state,
			action: PayloadAction<IMenuState['currentCategoryId']>,
		) {
			return {
				...state,
				currentCategoryId: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_MENUS,
	GET_MENUS_FAIL,
	GET_MENUS_SUCCESS,
	RESET_MENU_STATE,
	SET_ACTIVE_MENU,
	SET_IS_SEARCH_VISIBLE,
	UPDATE_AVAILABLE_MENUS,
	SET_CURRENT_CATEGORY_ID,
} = menuSlice.actions;

/** Reset state */
export const resetMenuState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_MENU_STATE());
};

/** Set active menu */
export const setActiveMenu = (menu: IMenu) => async (dispatch: Dispatch) => {
	await dispatch(SET_ACTIVE_MENU(menu));
};

/** Get menus */
export const getMenus = (venueId: string) => async (dispatch: Dispatch) => {
	// Create request
	await dispatch(
		GET_MENUS({
			request: {
				method: 'get',
				url: `1/venues/${venueId}/menu`,
				timeout: process.env.REACT_APP_MENU_TIMEOUT,
			},
		}),
	);
};

export const setIsSearchVisible = (isSearchVisible: boolean) => async (
	dispatch: Dispatch,
) => {
	dispatch(SET_IS_SEARCH_VISIBLE(isSearchVisible));
};

export const updateAvailableMenus = (
	serviceType?: TServiceType,
	timeslot?: Date,
) => async (dispatch: Dispatch) =>
	dispatch(UPDATE_AVAILABLE_MENUS({ serviceType, timeslot }));

export const setCurrentCategoryId = (
	categoryId: IMenuState['currentCategoryId'],
) => {
	return async (dispatch: Dispatch) => {
		dispatch(SET_CURRENT_CATEGORY_ID(categoryId));
	};
};

export default menuSlice.reducer;
