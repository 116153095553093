import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { resetLoadingStates } from './loading.slice';
import { StyledLoadingComponent, StyledLoadingMessage, StyledLoadingTitle, StyledLoadingAnimation, StyledLoadingText, StyledLoadingContainer } from './loading.styles';

import LogoAnimation from 'components/animations/logo-animation.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';


/** Renders Loading component */
const Loading: FunctionComponent = () => {
	// Get hooks
	const dispatch = useReduxDispatch();

	const [isLoading, setIsLoading] = useState(false);

	// Get whole state object from store
	const fullStateObject: RootState = useSelector((state: RootState) => state);

	// Get data from loading config
	const { loadingConfig } = useSelector((state: RootState) => state.loading);
	const { loadingMessage, loadingTimeout, isOnTop: loadingOnTop } =
		loadingConfig || {};

	useEffect(() => {
		// Get loading timeout from config or environment variable
		const loadingTimeoutTime: number =
			loadingTimeout || parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!);

		// Get all eventsInProgress > 0 from store
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const events = Object.entries(fullStateObject).filter((obj: any[]) => {
			if (obj[1] && 'eventsInProgress' in obj[1]) {
				return (
					obj[1]?.eventsInProgress !== undefined && obj[1].eventsInProgress > 0
				);
			}
			return false;
		});

		// Update isLoading state
		setIsLoading(!!events.length);

		// timeout to clear loading state
		const loadingStateTimeout = setTimeout(async () => {
			// If events still in progress, reset loading state
			!!events.length && (await dispatch(resetLoadingStates()));
			// Run timeout length
		}, loadingTimeoutTime);

		// useEffect cleanup
		return () => {
			setIsLoading(false);
			clearTimeout(loadingStateTimeout);
		};
	}, [dispatch, fullStateObject, loadingTimeout]);

	const showMessage = useMemo(
		() =>
			Boolean(loadingMessage && (loadingMessage.title || loadingMessage.text)),
		[loadingMessage],
	);

	return (
		<StyledLoadingComponent isLoading={isLoading} isOnTop={!!loadingOnTop}>
			{
				// Will only be true if loadingMessage is true
				showMessage ? (
					<StyledLoadingMessage>
						{loadingMessage!.title && (
							<StyledLoadingTitle>{loadingMessage!.title}</StyledLoadingTitle>
						)}
						<StyledLoadingAnimation data-testid="loading-spinner">
							<LogoAnimation />
						</StyledLoadingAnimation>
						{loadingMessage!.text && (
							<StyledLoadingText>{loadingMessage!.text}</StyledLoadingText>
						)}
					</StyledLoadingMessage>
				) : (
					<StyledLoadingContainer data-testid="loading-spinner">
						<LogoAnimation />
					</StyledLoadingContainer>
				)
			}
		</StyledLoadingComponent>
	);
};

export default Loading;
