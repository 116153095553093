import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import structure from 'assets/styles/variables/structure';
import Icon from 'components/icons/icon.component';

const StyledContainer = styled.div`
	width: 100%;
	background: ${brand.primary};
	padding: 14px ${structure.container.mobilePadding};
	max-width: ${structure.container.maxWidth};
	position: fixed;
	bottom: 0;
	cursor: pointer;
	z-index: 2;
`;

const StyledContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	> div {
		display: flex;
		align-items: center;
	}
`;

const StyledCopy = styled.div`
	margin: 0 0 0 17px;

	h3,
	h4 {
		color: ${brand.white};
		margin: 0;
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.regular};
	}

	h3 {
		font-weight: ${fonts.weights.semibold};
	}
`;

interface IComponentProps {
	venueName: string;
}

const ActiveTabBanner: FunctionComponent<IComponentProps> = ({ venueName }) => {
	const history = useHistory();
	return (
		<StyledContainer onClick={() => history.push('/basket')}>
			<StyledContent>
				<div>
					<Icon name="orderNo" colour={brand.white} width={25} height={27} />
					<StyledCopy>
						<h3>
							<FormattedMessage id="activeTab.banner.title" />
						</h3>
						<h4>
							<FormattedMessage
								id="activeTab.banner.text"
								values={{ venueName }}
							/>
						</h4>
					</StyledCopy>
				</div>
				<Icon name="chevron" colour={brand.white} width={9} height={18} />
			</StyledContent>
		</StyledContainer>
	);
};

export default ActiveTabBanner;
