import { IEcommercePurchaseDetails } from '../analytics.types';
import orderItemToEcommerceItem from './order-item-to-ecommerce-item.helper';
import priceToEcommercePrice from './price-to-ecommerce-price.helper';

import { IOrder } from 'modules/basket/basket.types';

/**
 * Converts an IOrder object to a google anaytics ecommerce purchase details object
 */
export default function orderToEcommercePurchaseDetails(
	order: IOrder,
): IEcommercePurchaseDetails | null {
	if (!order.id) return null;
	const price = priceToEcommercePrice(order.total);
	return {
		...price,
		transaction_id: order.id,
		items: order.items.map((item) => orderItemToEcommerceItem(item)),
	};
}
