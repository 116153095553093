import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ITimeslot, IVenueListFilters, IVenueState } from './venue.types';

// Create initial state
export const initialVenueState: IVenueState = {
	timeslots: [],
	tableNumbers: [],
	venues: [],
	eventsInProgress: 0,
	isPaymentOptionPickerVisible: false,
	pagination: {
		pageSize: 20,
		pageNumber: 0,
		pageCount: 1,
	},
	feeditbackUrl: undefined,
};

const venueSlice = createSlice({
	name: 'venue',
	initialState: initialVenueState,
	reducers: {
		GET_VENUES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUES_SUCCESS(state, action) {
			return {
				...state,
				pagination: action.payload.data.pagination,
				venues: action.payload.data.venues,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_SUCCESS(state, action) {
			return {
				...state,
				activeVenue: action.payload?.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_TIMESLOTS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_TIMESLOTS_SUCCESS(state, action) {
			const timeSlots = action.payload?.data?.timeslots || [];

			return {
				...state,
				timeslots: timeSlots.filter(
					(timeSlot: ITimeslot) => timeSlot.isAvailable,
				),
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_TIMESLOTS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_TABLE_NUMBERS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_TABLE_NUMBERS_SUCCESS(state, action) {
			return {
				...state,
				tableNumbers: action.payload?.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_TABLE_NUMBERS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_MARKETING(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_VENUE_MARKETING_SUCCESS(state, action) {
			return {
				...state,
				activeVenueMarketing: action.payload?.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_VENUE_MARKETING_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_VENUE_MARKETING(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		POST_VENUE_MARKETING_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_VENUE_MARKETING_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_FEEDITBACK_LINK(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_FEEDITBACK_LINK_SUCCESS(state, action) {
			return {
				...state,
				feeditbackUrl: action.payload?.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_FEEDITBACK_LINK_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_VENUE_STATE() {
			return { ...initialVenueState };
		},
		RESET_FEEDITBACK_STATE(state) {
			return {
				...state,
				feeditbackUrl: undefined,
			};
		},
		SET_PAYMENT_OPTION_PICKER_VISIBILITY(state, action) {
			return {
				...state,
				isPaymentOptionPickerVisible: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_VENUES,
	GET_VENUES_FAIL,
	GET_VENUES_SUCCESS,
	GET_VENUE,
	GET_VENUE_FAIL,
	GET_VENUE_SUCCESS,
	GET_VENUE_TIMESLOTS,
	GET_VENUE_TIMESLOTS_FAIL,
	GET_VENUE_TIMESLOTS_SUCCESS,
	GET_VENUE_TABLE_NUMBERS,
	GET_VENUE_TABLE_NUMBERS_FAIL,
	GET_VENUE_TABLE_NUMBERS_SUCCESS,
	GET_VENUE_MARKETING,
	GET_VENUE_MARKETING_FAIL,
	GET_VENUE_MARKETING_SUCCESS,
	GET_FEEDITBACK_LINK,
	GET_FEEDITBACK_LINK_FAIL,
	GET_FEEDITBACK_LINK_SUCCESS,
	POST_VENUE_MARKETING,
	POST_VENUE_MARKETING_FAIL,
	POST_VENUE_MARKETING_SUCCESS,
	RESET_VENUE_STATE,
	RESET_FEEDITBACK_STATE,
	SET_PAYMENT_OPTION_PICKER_VISIBILITY,
} = venueSlice.actions;

/** Reset state */
export const resetVenueState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_VENUE_STATE());
};

export const resetFeeditbackState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_FEEDITBACK_STATE());
};

/** Get venues */
export const getVenues = (filters: IVenueListFilters) => async (
	dispatch: Dispatch,
) => {
	// Create request
	const response = await dispatch(
		GET_VENUES({
			request: {
				method: 'get',
				url: '1/venues',
				params: {
					...filters,
					sortBy: filters?.sortBy || 'distance',
					sortOrder: filters?.sortOrder || 'ASC',
				},
			},
		}),
	);

	return response?.payload?.status === 200
		? response?.payload?.data?.venues
		: false;
};

/** Get venue */
export const getVenue = (venueId: string) => async (dispatch: Dispatch) => {
	// Create request
	const response = await dispatch(
		GET_VENUE({
			request: {
				method: 'get',
				url: `1/venues/${venueId}`,
			},
		}),
	);

	return response?.payload?.status === 200 ? response?.payload?.data : false;
};

/** Get venue timeslots */
export const getVenueTimeslots = (
	serviceType: string,
	venueId: string,
) => async (dispatch: Dispatch) => {
	// Create request
	const response = await dispatch(
		GET_VENUE_TIMESLOTS({
			request: {
				method: 'get',
				url: `1/venues/${venueId}/${serviceType}/timeslots`,
			},
		}),
	);

	return response?.payload?.status === 200 ? response?.payload?.data : false;
};

/** Get venue table numbers */
export const getVenueTableNumbers = (venueId: string) => async (
	dispatch: Dispatch,
) => {
	// Create request
	const response = await dispatch(
		GET_VENUE_TABLE_NUMBERS({
			request: {
				method: 'get',
				url: `1/venues/${venueId}/tables`,
			},
		}),
	);

	return response?.payload?.status === 200 ? response?.payload?.data : false;
};

/** Get venue marketing opt in */
export const getVenueMarketingOptIn = (venueId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_VENUE_MARKETING({
			request: {
				method: 'get',
				url: '1/marketing/optin',
				params: { venueId },
			},
		}),
	);

	return response?.payload?.status === 200 ? response?.payload?.data : false;
};

/** post venue marketing opt in */
export const postVenueMarketingOptIn = (venueId: string) => async (
	dispatch: Dispatch,
) => {
	// Create request
	const response = await dispatch(
		POST_VENUE_MARKETING({
			request: {
				method: 'post',
				url: '1/marketing/optin',
				data: { venueId },
			},
		}),
	);

	return response?.payload?.status === 200 ?? false;
};

/** Get feed it back link */
export const getFeeditbackLink = (orderId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_FEEDITBACK_LINK({
			request: {
				method: 'get',
				url: `1/marketing/survey-link/feed-it-back/${orderId}`,
			},
		}),
	);

	return response?.payload?.status === 200 ? response?.payload?.data : false;
};

/** Show/hide payment option picker */
export const setPaymentOptionPickerVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PAYMENT_OPTION_PICKER_VISIBILITY(visible));
};

export default venueSlice.reducer;
