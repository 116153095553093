import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { IToastStore, IToastMessage } from './toast.types';

// Create initial state for toast slice
export const initialToastState: IToastStore = {
	toasts: [],
};

const toastSlice = createSlice({
	name: 'toast',
	initialState: initialToastState,
	reducers: {
		TOAST_ADD(state, action) {
			return {
				...state,
				toasts: [
					...state.toasts.filter(
						// This removes any toasts with an Id matching the new toast
						(toast) => !toast.id || toast.id !== action.payload.toast.id,
					),
					action.payload.toast,
				],
			};
		},
		TOAST_REMOVE(state, action) {
			return {
				...state,
				toasts: state.toasts.filter(
					(toast: IToastMessage) => toast.id !== action.payload.id,
				),
			};
		},
		TOAST_CLEAR(state) {
			return {
				...state,
				toasts: [],
			};
		},
	},
});

// Destructure and export the plain action creators
export const { TOAST_ADD, TOAST_REMOVE, TOAST_CLEAR } = toastSlice.actions;

/** Thunk to process add toast request */
export const addToast = (item: IToastMessage, timeout = 10_000) => async (
	dispatch: Dispatch,
) => {
	// Get uuid for toast
	const toast = {
		id: await uuidv4(),
		...item,
	};

	// Add toast
	await dispatch(
		TOAST_ADD({
			toast,
		}),
	);

	// if timeout is 0, then don't remove it, otherwise wait 10 secs
	timeout &&
		(await setTimeout(async () => {
			// Remove toast
			await dispatch(TOAST_REMOVE(toast));
		}, timeout));
};

/** Thunk to process remove toast request */
export const removeToast = (id: string) => (dispatch: Dispatch) => {
	return dispatch(
		TOAST_REMOVE({
			id,
		}),
	);
};

/** Thunk to process clear toasts request */
export const clearToasts = () => (dispatch: Dispatch) => {
	return dispatch(TOAST_CLEAR());
};

export default toastSlice.reducer;
