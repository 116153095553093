import { History } from 'history';
import { IntlShape } from 'react-intl';

import { resetLoadingStates } from 'components/loading/loading.slice';
import { ReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import {
	activeBasketFromFullBasket,
	setActivePaymentOption,
	setBasketVenue,
} from 'modules/basket/basket.slice';
import { IActiveBasket, IBasket, IOrder } from 'modules/basket/basket.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { getVenue } from 'modules/venue/venue.slice';
import { ETableServiceFeature } from 'modules/venue/venue.types';

/** Check if active basket matches payment options supplied */
export const matchesActiveBasketPaymentOption = (
	activeBasket: IActiveBasket,
	paymentOption: (keyof typeof ETableServiceFeature)[],
) =>
	activeBasket.paymentOption &&
	paymentOption.includes(activeBasket.paymentOption);

/** Check if full basket matches table service feature supplied */
export const matchesFullBasketTableServiceFeature = (
	fullBasket: IBasket,
	paymentOption: (keyof typeof ETableServiceFeature)[],
) =>
	fullBasket.tableServiceFeature &&
	paymentOption.includes(fullBasket.tableServiceFeature);

/** Check if order matches table service features supplied */
export const matchesOrderTableServiceFeature = (
	order: IOrder,
	tableServiceFeature: (keyof typeof ETableServiceFeature)[],
) =>
	order.tableServiceFeature &&
	tableServiceFeature.includes(order.tableServiceFeature);

/** Check if full basket contains an ONPL order */
export const hasActiveOnplOrder = (fullBasket: IBasket) =>
	!!fullBasket.order?.items.length &&
	fullBasket.tableServiceFeature === 'OrderNowPayLater';

/** If user has an active order, notify them and take them to the menu page */
export const returnToActiveOnplOrder = async (
	intl: IntlShape,
	fullBasket: IBasket,
	history: History,
	dispatch: ReduxDispatch,
	showAlert: boolean,
) => {
	// Show full page loading state
	history.push('/loading/');

	// Reset loading states
	dispatch(resetLoadingStates());

	if (showAlert) {
		// Alert the user
		await fireDialog({
			title: intl.formatMessage({
				id: 'venue.servicetype.hasActiveOrder.title',
			}),
			text: intl.formatMessage({
				id: 'venue.servicetype.hasActiveOrder.text',
			}),
			confirmButtonText: intl.formatMessage({
				id: 'venue.servicetype.hasActiveOrder.button',
			}),
			allowOutsideClick: false,
		});
	}

	// Create activeBasket from fullBasket in state
	await dispatch(activeBasketFromFullBasket());

	// Get venue
	const venue = await dispatch(getVenue(fullBasket.venueId));
	// If we have venue, set it in active basket
	venue && (await dispatch(setBasketVenue(venue)));

	// Set payment option to ONPL
	await dispatch(setActivePaymentOption('OrderNowPayLater'));

	// Go to the order's venue menu page
	history.push(`/menu/${fullBasket.venueId}/`);
};
