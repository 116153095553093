// Get correct url for current environment
export const environmentUrl = (
	subDomain: string = 'order',
	port: string = '3002',
): string => {
	switch (process.env.REACT_APP_ENVIRONMENT) {
	case 'production':
		return `https://${subDomain}.orderpay.com`;
	case 'staging':
		return `https://${subDomain}.staging.orderpay.com`;
	case 'alpha':
		return `https://${subDomain}.alpha.orderpay.com`;
	case 'preview':
		return (
			process.env.REACT_APP_PREVIEW_URL || `https://${subDomain}.orderpay.com`
		);
	case 'ngrok-james':
		return 'https://op-james.eu.ngrok.io/';
	case 'ngrok':
		return 'https://orderpay-webordering.ngrok.io';
	default:
		return `https://localhost:${port}`;
	}
};

// Get the host name for the current environment
export const hostName = (subDomain: string = 'order') => {
	switch (process.env.REACT_APP_ENVIRONMENT) {
	case 'production': {
		return `${subDomain}.orderpay.com`;
	}
	case 'staging': {
		return `${subDomain}.staging.orderpay.com`;
	}
	case 'alpha': {
		return `${subDomain}.alpha.orderpay.com`;
	}
	case 'ngrok-james': {
		return 'op-james.eu.ngrok.io';
	}
	default: {
		return `${subDomain}.orderpay.com`;
	}
	}
};

// eslint-disable-next-line no-console
console.log('environmentUrl', environmentUrl());
