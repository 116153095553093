/** Function to check if day number is today */
export const isDayNumberToday = (dayNumber: number) => {
	// Get current day number
	const currentDayNumber = new Date().getDay();

	// If current day - 1 is the same as day number, return true
	if (currentDayNumber - 1 === dayNumber) {
		return true;
	}

	// If current day is 0 (sunday) and the day number is 6 (sunday) return true
	if (currentDayNumber === 0 && dayNumber === 6) {
		return true;
	}

	return false;
};

export default isDayNumberToday;
