import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Container from 'components/container/container.component';
import Icon from 'components/icons/icon.component';
import PoweredBy from 'components/powered-by/powered-by.component';
import dayjs from 'helpers/dayjs.helper';

const StyledFooter = styled.footer<IComponentProps>`
	padding: 50px 0 45px;
	background: ${({ variant }) =>
		variant === 'white' ? brand.white : brand.neutralMid};
`;

const StyledContainer = styled(Container)`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const StyledDivider = styled.div`
	height: 1px;
	background: ${brand.borders};
	width: 100%;
	margin: 50px 0 30px;
`;

const StyledSocials = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 0 50px;
`;

const StyledSocialList = styled.ul`
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	align-items: center;

	li {
		&:not(:last-child) {
			margin-right: 25px;
		}
	}
`;

const StyledChatSupport = styled.div`
	display: flex;
	align-items: center;
`;

const StyledFreshworks = styled.div`
	margin: 0 0 0 14px;
	cursor: pointer;
`;

const StyledLinks = styled.ul`
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	align-items: center;

	li:not(:last-child):after {
		content: '';
		background: ${brand.black};
		width: 1px;
		height: 10px;
		margin: 0 10px;
		display: inline-block;
	}

	a {
		text-decoration: none;
		color: ${brand.black};
		font-weight: ${fonts.weights.medium};
		font-size: ${fonts.sizes.semiSmall};
	}
`;

const StyledCopyright = styled.div`
	margin: 10px 0 0;
	font-weight: ${fonts.weights.light};
	font-size: ${fonts.sizes.semiSmall};
`;

interface IComponentProps {
	className?: string;
	variant?: 'white';
}

/** Renders generic footer component */
const GenericFooter: FunctionComponent<IComponentProps> = ({
	className,
	variant,
}) => (
	<StyledFooter className={className} variant={variant}>
		<StyledContainer>
			<PoweredBy />
			<StyledDivider />
			<StyledSocials>
				<StyledSocialList>
					<li>
						<a
							aria-label="OrderPay facebook"
							href="https://www.facebook.com/OrderPayUK/"
							target="_blank"
							rel="noreferrer"
						>
							<Icon
								name="facebook"
								width={11}
								height={23}
								colour={brand.black}
							/>
						</a>
					</li>
					<li>
						<a
							aria-label="OrderPay twitter"
							href="https://twitter.com/OrderpayUK/"
							target="_blank"
							rel="noreferrer"
						>
							<Icon
								name="twitter"
								width={25}
								height={20}
								colour={brand.black}
							/>
						</a>
					</li>
					<li>
						<a
							aria-label="OrderPay instagram"
							href="https://www.instagram.com/orderpayuk/"
							target="_blank"
							rel="noreferrer"
						>
							<Icon
								name="instagram"
								width={28}
								height={28}
								colour={brand.black}
							/>
						</a>
					</li>
				</StyledSocialList>
				<StyledChatSupport>
					<FormattedMessage id="footer.getHelp" />
					<StyledFreshworks
						onClick={() => {
							// Open freshdesk widget
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							(window as any).FreshworksWidget('open');
						}}
					>
						<Icon
							name="freshWorks"
							width={36}
							height={36}
							colour={brand.primary}
						/>
					</StyledFreshworks>
				</StyledChatSupport>
			</StyledSocials>
			<StyledLinks>
				<li>
					<a
						aria-label="OrderPay terms and conditions"
						href="https://orderpay.com/terms-and-conditions/"
						target="_blank"
						rel="noreferrer"
					>
						<FormattedMessage id="footer.links.termsConditions" />
					</a>
				</li>
				<li>
					<a
						aria-label="OrderPay privacy policy"
						href="https://orderpay.com/privacy-policy/"
						target="_blank"
						rel="noreferrer"
					>
						<FormattedMessage id="footer.links.privacy" />
					</a>
				</li>
				<li>
					<a
						aria-label="OrderPay cookie policy"
						href="https://orderpay.com/cookie-policy/"
						target="_blank"
						rel="noreferrer"
					>
						<FormattedMessage id="footer.links.cookies" />
					</a>
				</li>
			</StyledLinks>
			<StyledCopyright>
				&copy;{dayjs().format('YYYY')}{' '}
				<FormattedMessage id="footer.copyright" />
			</StyledCopyright>
		</StyledContainer>
	</StyledFooter>
);

export default GenericFooter;
