import { motion } from 'framer-motion';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

export const HeroUnit = styled.section`
	padding: 18px;
	background-image: url('https://production-orderpay.s3.eu-west-1.amazonaws.com/images/gbk-o2-hero.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center right;
`;

export const Caption = styled(motion.h1)`
	width: 222px;
	margin-bottom: 15px;
	color: ${brand.text};
	font-size: ${fonts.sizes.larger};

	strong {
		font-weight: ${fonts.weights.bold};
	}
`;

export const LogoWrapper = styled.div`
	display: flex;
`;

export const Logo = styled(motion.div)`
	width: 74px;
	height: 29px;
	margin-right: 5px;
	border-radius: 3px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

	&:first-child {
		background-image: url('https://production-orderpay.s3.eu-west-1.amazonaws.com/images/gbk-tastecard-logo.png');
	}

	&:nth-child(2) {
		background-image: url('https://production-orderpay.s3.eu-west-1.amazonaws.com/images/gbk-o2-logo.png');
	}
`;
