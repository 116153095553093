import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IUpsellData, IUpsellState } from './upsell.types';

import { IBasketToSubmit } from 'modules/basket/basket.types';
import { ETableServiceFeature } from 'modules/venue/venue.types';

export const initialUpsellState: IUpsellState = {
	upsellVisible: false,
	upsellProductsVisible: false,
	submittingOrder: false,
	eventsInProgress: 0,
	products: [],
	title: '',
	message: '',
	isEnabled: false,
	automaticPopOverEnabled: false,
};

const upsellSlice = createSlice({
	name: 'upsell',
	initialState: initialUpsellState,
	reducers: {
		SET_UPSELL_VISIBLE(state, action) {
			return {
				...state,
				upsellVisible: action.payload,
			};
		},
		SET_UPSELL_PRODUCTS_VISIBLE(state, action) {
			return {
				...state,
				upsellProductsVisible: action.payload,
			};
		},
		GET_UPSELL_LIST(state, action) {
			return {
				...state,
			};
		},
		GET_UPSELL_LIST_SUCCESS(state, action) {
			const { data } = action.payload;

			const {
				title,
				message,
				products,
				isEnabled,
				automaticPopOverEnabled,
			} = data as IUpsellData;

			return {
				...state,
				message,
				title,
				products,
				isEnabled: Boolean(isEnabled),
				upsellProductsVisible: Boolean(automaticPopOverEnabled),
			};
		},
		GET_UPSELL_LIST_FAIL(state) {
			return {
				...state,
			};
		},
		SUBMIT_UPSELL_ORDER(state, action) {
			return {
				...state,
				submittingOrder: true,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		SUBMIT_UPSELL_ORDER_SUCCESS(state, action) {
			return {
				...state,
				submittingOrder: false,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SUBMIT_UPSELL_ORDER_FAIL(state, action) {
			return {
				...state,
				submittingOrder: false,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_ACTIVE_UPSELL_PRODUCT_DETAILS(state, action) {
			return {
				...state,
				activeUpsellProductDetails: action.payload,
			};
		},
	},
});

export const {
	SET_UPSELL_VISIBLE,
	SET_UPSELL_PRODUCTS_VISIBLE,
	GET_UPSELL_LIST,
	GET_UPSELL_LIST_SUCCESS,
	GET_UPSELL_LIST_FAIL,
	SUBMIT_UPSELL_ORDER,
	SUBMIT_UPSELL_ORDER_SUCCESS,
	SUBMIT_UPSELL_ORDER_FAIL,
	SET_ACTIVE_UPSELL_PRODUCT_DETAILS,
} = upsellSlice.actions;

/**
 * Sets the open state of the upsell bottom sheet. (Doesn't make products visible, handled by setUpsellProductsVisible)
 */
export const setUpsellVisible = (value: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_UPSELL_VISIBLE(value));
};

/**
 * Sets the open state of the products within the upsell bottom sheet. Use setUpsellVisible to show the bottom sheet.
 */
export const setUpsellProductsVisible = (value: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_UPSELL_PRODUCTS_VISIBLE(value));
};

/**
 * Loads the upsell list for a venue
 */
export const getUpsellList = (
	venueId: string,
	tableServiceType: ETableServiceFeature = ETableServiceFeature.PayAndGo,
) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_UPSELL_LIST({
			request: {
				method: 'GET',
				url: `1/upsell/venue/${venueId}?tableServiceType=${tableServiceType}`,
			},
		}),
	);
	return response?.payload?.status === 200 ? response?.payload?.data : false;
};

/**
 * Submits a basket with upsell items
 */
export const sumbitUpsellOrder = (basket: IBasketToSubmit) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		SUBMIT_UPSELL_ORDER({
			request: {
				method: 'POST',
				url: `2/basket/${basket.id}/upsell`,
				data: basket,
			},
		}),
	);
	return response?.payload?.status === 200 ? response?.payload?.data : false;
};

export const setActiveUpsellProductDetails = (details?: {
	productId: string;
	menuId: string;
	venueId: string;
}) => async (dispatch: Dispatch) => {
	dispatch(SET_ACTIVE_UPSELL_PRODUCT_DETAILS(details));
};

export default upsellSlice.reducer;
