import { AnimatePresence, motion } from 'framer-motion';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { fireDialog } from '../dialog/dialog.service';
import { RootState } from '../state/root.reducer';
import DefaultTheme from '../theme/default-theme-provider.component';
import NavItem from './components/nav-item.component';
import NavProfile from './components/nav-profile.component';
import buildNavTree from './helpers/build-nav-tree.helper';
import { closeNav } from './navigation.slice';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Container from 'components/container/container.component';
import Icon from 'components/icons/icon.component';
import PoweredBy from 'components/powered-by/powered-by.component';
import { addToast } from 'components/toast/toast.slice';
import { environmentUrl } from 'helpers/environment-url.helper';
import useScrollBlock from 'hooks/use-scroll-block.hook';
import { processLogout } from 'modules/auth/auth.slice';

/**
 * Fixed position element which sets the z-index for the navigation
 * Z-index of 1059 used to put it above the rest of the app, but below the sweet alert,
 * which has a z-index of 1059 @link {https://github.com/sweetalert2/sweetalert2/issues/296#issuecomment-248569095}
 */
const StyledNavWrap = styled(motion.div)`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1059;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	background: ${brand.white};
`;

const StyledUl = styled.ul`
	margin: 0;
	padding: 0;
	display: block;
	list-style: none;
`;
const StyledLi = styled.li`
	margin: 0;
	padding: 0;
	display: block;
`;

const StyledCloseButton = styled.button`
	padding: 12px;
	margin: 12px -15px -10px auto;
	display: block;
	background: transparent;
	border: none;
	cursor: pointer;
`;

const StyledButton = styled(Button)`
	width: 100%;
	margin: 54px auto;
	flex: 1 1 auto;
	text-align: center;
`;

const StyledPoweredBy = styled(PoweredBy)`
	margin-top: auto;
`;

const StyledContainer = styled(Container)`
	margin: 0 auto;
`;

const StyledNavGroupHeading = styled.span`
	display: block;
	margin: 17px 0 4px;
	font-weight: ${fonts.weights.light};
	font-size: ${fonts.sizes.med};
	color: ${brand.text};
`;

const Navigation: FunctionComponent = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const { user, hasAuth } = useSelector((state: RootState) => state.auth);
	const { activeBasket } = useSelector((state: RootState) => state.basket);
	const { activeSession } = useSelector((state: RootState) => state.payGo);
	const { activeOrder: activePayGoOrder } = activeSession;
	const { open } = useSelector((state: RootState) => state.navigation);

	const navRef = useRef<HTMLDivElement>(null);

	const handleLogout = useCallback(async () => {
		await dispatch(processLogout());
		dispatch(
			addToast({
				type: 'info',
				id: 'log-out',
				closeOnClick: true,
				message: intl.formatMessage({ id: 'navigation.signOutToast' }),
			}),
		);
	}, [dispatch, intl]);

	const navigationTree = useMemo(() => {
		return buildNavTree({
			activeBasket,
			activePayGoOrder,
			pathname,
			loggedIn: hasAuth,
			signOut: async () => {
				const confirmLogout = await fireDialog({
					text: intl.formatMessage({
						id: 'navigation.signOutConfirmation',
					}),
					confirmButtonText: intl.formatMessage({
						id: 'navigation.signOut',
					}),
					showCancelButton: true,
				});

				if (confirmLogout.isConfirmed) {
					handleLogout();
				}
			},
		});
	}, [activeBasket, pathname, hasAuth, intl, handleLogout]);

	const { blockScroll, allowScroll } = useScrollBlock();

	/**
	 * Close the navigation
	 */
	function close() {
		dispatch(closeNav());
	}

	/**
	 * Sign in
	 */
	function signIn() {
		window.location.assign(
			`${environmentUrl()}/auth?previousUrl=${window.location.href}`,
		);
	}

	/**
	 * Handle scroll behaviour
	 */
	useEffect(() => {
		if (open) blockScroll();
		// Call allowScroll with callback, rather than when open is false,
		// so that we can't end up in a situation where the scroll is
		// blocked and the navigation component isn't rendered
		return allowScroll;
	}, [open, blockScroll, allowScroll]);

	/**
	 * Close when changing route
	 */
	useEffect(() => {
		close();
	}, [pathname]);

	/**
	 * Close when the user presses the escape key
	 */
	useEffect(() => {
		const closeOnEsc = (event: KeyboardEvent) => {
			event.code === 'Escape' && close();
		};
		window.addEventListener('keydown', closeOnEsc);
		return () => {
			window.removeEventListener('keydown', closeOnEsc);
		};
	}, []);

	/**
	 * If opens the navigation, bring cursor focus to within the navigation element
	 */
	useEffect(() => {
		if (!open || !navRef.current) return () => {};
		const request = requestAnimationFrame(() => {
			(navRef.current?.querySelector('a, button') as HTMLElement)?.focus();
		});
		return () => cancelAnimationFrame(request);
	}, [open, navRef]);

	return (
		<DefaultTheme>
			<AnimatePresence>
				{open && (
					<StyledNavWrap
						aria-live="polite"
						key="nav-wrap"
						initial={{ x: '50vw', opacity: 0 }}
						exit={{ x: '50vw', opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ duration: 0.25 }}
						ref={navRef}
					>
						<StyledContainer>
							<StyledCloseButton type="button" onClick={close}>
								<Icon name="close" width={18} height={18} />
							</StyledCloseButton>
							<NavProfile user={user} />
							<nav>
								<StyledUl>
									{navigationTree.map((group) => (
										<StyledLi key={group.id}>
											{group.groupMessageId && (
												<StyledNavGroupHeading>
													<FormattedMessage id={group.groupMessageId} />
												</StyledNavGroupHeading>
											)}
											<StyledUl>
												{group.items.map(
													({
														id,
														onClick,
														preventCloseOnClick,
														...options
													}) => (
														<StyledLi key={id}>
															<NavItem
																{...options}
																onClick={(e) => {
																	onClick?.(e);

																	if (!preventCloseOnClick) {
																		close();
																	}
																}}
															/>
														</StyledLi>
													),
												)}
											</StyledUl>
										</StyledLi>
									))}
								</StyledUl>
							</nav>

							{!hasAuth && (
								<StyledButton onClick={signIn}>
									<FormattedMessage id="navigation.signIn" />
								</StyledButton>
							)}
						</StyledContainer>

						<StyledPoweredBy />
					</StyledNavWrap>
				)}
			</AnimatePresence>
		</DefaultTheme>
	);
};

export default Navigation;
