import { Severity } from '@sentry/react';
import { Store, Dispatch, AnyAction } from 'redux';

import customEvent, { ECustomEvent } from '../events/custom.event';
import {
	addToCart,
	removeFromCart,
	selectItem,
	viewItemList,
	purchase,
	viewCart,
} from '../events/ecommerce.event';
import basketToEcommerceCart from '../helpers/basket-to-eccommerce-cart.helper';
import menuToEcommerceItemList from '../helpers/menu-to-ecommerce-item-list.helper';
import orderItemToEcommerceItem from '../helpers/order-item-to-ecommerce-item.helper';
import orderToEcommercePurchaseDetails from '../helpers/order-to-ecommerce-purchase-details.helper';
import productToEcommerceItem from '../helpers/product-to-ecommerce-item.helper';
import suggestedProductToEcommerceItemList from '../helpers/suggested-product-to-ecommerce-item-list.helper';
import {
	isPayAndGo,
	isSplitUsed,
	trackSplitMethod,
} from '../helpers/track-pay-go-split-method.helper';

import {
	ADD_BASKET_ITEM,
	GET_BASKET_SUCCESS,
	REMOVE_BASKET_ITEM,
	SET_SERVICECHARGE,
	SET_TIP_AMOUNT,
	UPDATE_BASKET_ITEM,
} from 'modules/basket/basket.slice';
import {
	IBasket,
	IOrder,
	IOrderItem,
	IServiceCharge,
	ITip,
} from 'modules/basket/basket.types';
import { SET_ACTIVE_ORDER } from 'modules/checkout/checkout.slice';
import errorLogger from 'modules/core/error/helpers/error-logger.helper';
import { SET_ACTIVE_MENU } from 'modules/menu/menu.slice';
import { IMenu } from 'modules/menu/menu.types';
import { CREATE_PAYMENT_REQUEST_SUCCESS } from 'modules/payment/payment.slice';
import { IProduct } from 'modules/product/product.types';
import { GET_PRODUCT_SUCCESS } from 'modules/product/slices/product-api.slice';
import { SET_SUGGESTED_PAIRING_VISIBLE } from 'modules/product/slices/product.slice';

const actionsToTrack: {
	[
	actionType: string
	]: // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| ((payload: any) => void)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	| ((payload: any, store: Store) => void);
} = {
	[GET_PRODUCT_SUCCESS.type]: ({ data }: { data: IProduct }) => {
		const ecommerceItem = productToEcommerceItem(data);
		selectItem(ecommerceItem);
	},
	[SET_ACTIVE_MENU.type]: (menu: IMenu) => {
		const ecommerceItemList = menuToEcommerceItemList(menu);
		viewItemList(ecommerceItemList);
	},
	[SET_SUGGESTED_PAIRING_VISIBLE.type]: (isVisible: boolean, store: Store) => {
		const state = store.getState();
		if (
			!isVisible ||
			!state?.product.activeProduct?.suggestedPairingList?.suggestedProducts ||
			state.product.activeProduct.suggestedPairingList.suggestedProducts
				.length < 1
		)
			return;

		const itemList = suggestedProductToEcommerceItemList(
			state.product.activeProduct,
		);
		viewItemList(itemList);
	},
	[ADD_BASKET_ITEM.type]: (item: IOrderItem) => {
		const ecommerceItem = orderItemToEcommerceItem(item);
		addToCart(ecommerceItem);
	},
	[UPDATE_BASKET_ITEM.type]: (item: IOrderItem) => {
		const ecommerceItem = orderItemToEcommerceItem(item);
		addToCart(ecommerceItem);
	},
	[REMOVE_BASKET_ITEM.type]: (item: IOrderItem) => {
		const ecommerceItem = orderItemToEcommerceItem(item);
		removeFromCart(ecommerceItem);
	},
	[SET_TIP_AMOUNT.type]: (tip: ITip) => {
		// TODO: compare current to previous value to make sure we're not duplicating this
		if (!tip.amount) {
			customEvent(ECustomEvent.removeTip, true);
		} else {
			customEvent(ECustomEvent.setTip, true, { value: tip.amount.units / 100 });
		}
	},
	[SET_SERVICECHARGE.type]: (serviceCharge: IServiceCharge) => {
		// TODO: compare current to previous value to make sure we're not duplicating this
		if (
			!serviceCharge.amount ||
			(typeof serviceCharge.amount !== 'number' &&
				serviceCharge.amount.units === 0)
		) {
			customEvent(ECustomEvent.removeServiceCharge, true);
		} else {
			const amount =
				typeof serviceCharge.amount === 'number'
					? serviceCharge.amount
					: serviceCharge.amount.units;
			customEvent(ECustomEvent.setServiceCharge, true, { value: amount / 100 });
		}
	},
	[SET_ACTIVE_ORDER.type]: (order: IOrder) => {
		const details = orderToEcommercePurchaseDetails(order);
		details && purchase(details);
	},
	[GET_BASKET_SUCCESS.type]: ({ data: basket }: { data: IBasket }) => {
		const cart = basketToEcommerceCart(basket);
		cart && viewCart(cart);
	},
	[CREATE_PAYMENT_REQUEST_SUCCESS.type]: (data: unknown, store: Store) => {
		const state = store.getState();
		if (isPayAndGo(state) && isSplitUsed(state)) {
			trackSplitMethod(state);
		}
	},
};

/**
 * Listens to actions on the store and tracks ecommerce events using google analytics
 */
const ecommerceStateTrackingMiddleware = (store: Store) => (
	next: Dispatch,
) => async (action: AnyAction) => {
	// Try catch is catch all for any errors that might arise.
	// Any errors should be handled gracefully in the background.
	try {
		if (typeof actionsToTrack[action.type] === 'function') {
			actionsToTrack[action.type](action.payload, store);
		}
	} catch (error: unknown) {
		errorLogger(
			{
				name: 'Issue tracking event',
				message: `Action - ${action.type} \n Data - ${JSON.stringify(error)}`,
			},
			Severity.Info,
		);
	}
	return next(action);
};

export default ecommerceStateTrackingMiddleware;
