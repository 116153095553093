import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from 'modules/core/state/root.reducer';

// create new type to Type useDispatch
export type ReduxDispatch = ThunkDispatch<RootState, null, Action>;

// export use redux dispatch
export function useReduxDispatch(): ReduxDispatch {
	return useDispatch<ReduxDispatch>();
}
