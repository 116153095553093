import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IProductState } from '../product.types';

import { IOrderItem } from 'modules/basket/basket.types';

// Create initial state
export const initialProductState: IProductState = {
	isSuggestedPairingVisible: false,
	shouldCloseModal: false,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
};

const productSlice = createSlice({
	name: 'product',
	initialState: initialProductState,
	reducers: {
		SET_SHOULD_CLOSE(state, action) {
			return {
				...state,
				shouldCloseModal: action.payload,
			};
		},
		SET_ACTIVE_BASKET_ITEM(state, action) {
			return {
				...state,
				activeBasketItem: action.payload,
			};
		},
		RESET_PRODUCT_STATE() {
			return { ...initialProductState };
		},
		SET_SUGGESTED_PAIRING_VISIBLE(state, action) {
			return {
				...state,
				isSuggestedPairingVisible: Boolean(action.payload),
			};
		},
		SET_BASE_PRODUCT(state, action) {
			return {
				...state,
				baseProduct: action.payload,
			};
		},
		CLEAR_BASE_PRODUCT(state) {
			// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
			const { baseProduct, ...newState } = state;
			return newState;
		},
	},
});

// Destructure and export the plain action creators
export const {
	SET_SHOULD_CLOSE,
	SET_ACTIVE_BASKET_ITEM,
	SET_SUGGESTED_PAIRING_VISIBLE,
	RESET_PRODUCT_STATE,
	SET_BASE_PRODUCT,
	CLEAR_BASE_PRODUCT,
} = productSlice.actions;

/** Reset state */
export const resetProductState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_PRODUCT_STATE());
};

/** Show suggested pairing */
export const setSuggestedPairingVisible = (open: boolean) => async (
	dispatch: Dispatch,
) => {
	// Create request
	await dispatch(SET_SUGGESTED_PAIRING_VISIBLE(open));
};

/** Set the base product for suggested pairing */
export const setBaseProduct = (id: string, menuId: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_BASE_PRODUCT({ id, menuId }));
};

/** Clear the base product for suggested pairing */
export const clearBaseProduct = () => async (dispatch: Dispatch) => {
	await dispatch(CLEAR_BASE_PRODUCT());
};

/**
 * Sets whether the modal should close
 */
export const setShouldClose = (shouldClose: boolean) => (
	dispatch: Dispatch,
) => {
	return dispatch(SET_SHOULD_CLOSE(shouldClose));
};

/**
 * Set or clear the active basket item.
 * The active basket item is set when we open the modal from the basket
 */
export const setActiveBasketItem = (basketItem?: IOrderItem) => (
	dispatch: Dispatch,
) => {
	return dispatch(SET_ACTIVE_BASKET_ITEM(basketItem));
};

export default productSlice.reducer;
