import { Severity } from '@sentry/react';
import { useEffect, useRef, useState } from 'react';

import errorLogger from 'modules/core/error/helpers/error-logger.helper';

/**
 * Hook for checking if an element is visible on the screen.
 *
 * Returns a ref and isVisible.
 * - ref should be added to a component (<SomeComp ref={ref}/>)
 * - isVisible will change to true when the element is visible to the user.
 *
 * Removes observer from element if the ref changes
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
export default function useIntersectionObserver<T extends HTMLElement>(
	options?: IntersectionObserverInit,
) {
	const [isVisible, setIsVisible] = useState(false);
	const [observer, setObserver] = useState<IntersectionObserver | null>(null);

	const ref = useRef<T | null>(null);

	// Runs when the options change
	useEffect(() => {
		const newObserver = new IntersectionObserver((entities) => {
			// We only support 1 element, so we setIsVisible if that entity exists and is visible
			setIsVisible(Boolean(entities[0]) && entities[0].isIntersecting);
		}, options);
		setObserver(newObserver);
		return () => newObserver.disconnect();
	}, [options]);

	// Runs if ref target changes, or observer changes
	useEffect(() => {
		// Do nothing if ref or observer isn't ready
		if (!ref.current || !observer) {
			return () => {};
		}
		// Store target in local variable to
		const target = ref.current;
		observer.observe(target);
		return () => {
			try {
				observer.unobserve(target);
			} catch (error) {
				errorLogger(
					{
						name: 'Unable to unobserve target',
						message: JSON.stringify({ target, error }),
					},
					Severity.Info,
				);
			}
		};
	}, [ref.current, observer]);

	return {
		ref,
		isVisible,
	};
}
