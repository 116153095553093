import { createSlice, Dispatch } from '@reduxjs/toolkit';

export const initialNavigationState = {
	open: false,
};

const navigationSlice = createSlice({
	name: 'navigation',
	initialState: initialNavigationState,
	reducers: {
		SET_NAV_OPEN(state, action) {
			return {
				...state,
				open: action.payload,
			};
		},
	},
});

export const { SET_NAV_OPEN } = navigationSlice.actions;

export const setNavOpen = (value: boolean) => async (dispatch: Dispatch) => {
	return dispatch(SET_NAV_OPEN(value));
};
export const openNav = () => setNavOpen(true);
export const closeNav = () => setNavOpen(false);

export default navigationSlice.reducer;
