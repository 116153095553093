import { ITip } from 'modules/basket/basket.types';

interface ICalculateTipAmountArgs {
	tipAmount?: ITip;
	subTotal?: number;
}
const calculateTipAmount = ({
	tipAmount,
	subTotal,
}: ICalculateTipAmountArgs): number => {
	if (tipAmount?.percent && subTotal) {
		return Math.round(subTotal * (tipAmount?.percent / 100));
	}

	if (tipAmount?.amount) {
		return tipAmount?.amount.units;
	}

	return 0;
};

export default calculateTipAmount;
