import { createTransform } from 'redux-persist';

const ResetLoadingFlags = createTransform(
	// Transform state being rehydrated
	// Removes loading flags from the store
	(outboundState: {
		checkoutHasLoaded?: boolean;
		fetchingBasket?: boolean;
	}) => {
		const state = { ...outboundState };
		if (state.checkoutHasLoaded) {
			state.checkoutHasLoaded = false;
		}
		if (state.fetchingBasket) {
			state.fetchingBasket = false;
		}
		return state;
	},
);

export default ResetLoadingFlags;
