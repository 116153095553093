import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { removeToast } from './toast.slice';
import { IToastMessage } from './toast.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';

const StyledToasts = styled.div`
	display: flex;
	flex-flow: column-reverse;
	align-items: center;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	z-index: 10;
`;

const StyledToast = styled.div<IToastMessage>`
	width: 100%;
	overflow: hidden;
	margin-top: 5px;
	background: ${brand.primary};
	position: relative;
	display: flex;
	justify-content: center;
`;

const StyledToastContent = styled.div`
	padding: 15px 21px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 800px;
`;

const StyledIcon = styled(Icon)`
	margin-right: 15px;
`;

const StypedToastCopy = styled.div`
	flex-grow: 1;

	h4 {
		margin: 0;
		color: ${brand.white};
		font-size: ${fonts.sizes.h4};
		font-weight: ${fonts.weights.semibold};
	}

	p {
		font-size: ${fonts.sizes.standard};
		color: ${brand.white};
		font-weight: ${fonts.weights.regular};

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const StyledButton = styled.button`
	line-height: 1;
	border: none;
	cursor: pointer;
	padding: 12px 16px;
	font-size: ${fonts.sizes.standard};
	color: ${brand.text};
	font-weight: ${fonts.weights.semibold};
	background: ${brand.white};
	border-radius: 6px;
	&:focus {
		outline: 0;
	}
`;

const ToastMessage: FunctionComponent<IToastMessage> = ({
	title,
	message,
	type,
	icon,
	id,
	onClose,
	closeOnClick,
	actionText,
}) => {
	// Get redux dispatch
	const dispatch = useReduxDispatch();

	const closeToast = useCallback(() => {
		if (onClose) {
			onClose();
			return;
		}
		id && dispatch(removeToast(id));
	}, [id, onClose]);

	return (
		<StyledToast type={type} onClick={() => !!closeOnClick && closeToast()}>
			<StyledToastContent>
				{icon && (
					<StyledIcon name={icon} width={31} height={31} colour={brand.white} />
				)}

				<StypedToastCopy>
					{title && <h4>{title}</h4>}
					{message && <p>{message}</p>}
				</StypedToastCopy>

				{actionText && (
					<StyledButton
						type="button"
						className="button-close"
						aria-label="Close"
						onClick={(event) => {
							event.stopPropagation();
							closeToast();
						}}
					>
						{actionText}
					</StyledButton>
				)}

				{!actionText && closeOnClick && (
					<Icon name="cross" colour="white" width={13} height={13} />
				)}
			</StyledToastContent>
		</StyledToast>
	);
};

/** Renders Toast component */
const Toasts: FunctionComponent = () => {
	const toasts: IToastMessage[] = useSelector(
		(state: RootState) => state.toast.toasts,
	);
	return (
		<StyledToasts>
			{toasts.map((toast: IToastMessage) => (
				<ToastMessage {...toast} key={toast.id} />
			))}
		</StyledToasts>
	);
};

export default Toasts;
