/** Set storage item with an expiry time */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setWithExpiry(key: string, value: any, ttl: number) {
	const item = {
		value,
		expiry: new Date().getTime() + ttl,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

/** Get storage item by key with expiry time */
export function getWithExpiry(key: string) {
	const itemString = window.localStorage.getItem(key);
	if (!itemString) return null;

	const item = JSON.parse(itemString);
	const isExpired = new Date().getTime() > item.expiry;

	if (isExpired) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
}
