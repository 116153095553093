import { IEcommerceCart } from '../analytics.types';
import orderItemToEcommerceItem from './order-item-to-ecommerce-item.helper';
import priceToEcommercePrice from './price-to-ecommerce-price.helper';

import { IBasket } from 'modules/basket/basket.types';

/** Converts a basket object to an ecommerce cart */
export default function basketToEcommerceCart(
	basket: IBasket,
): IEcommerceCart | null {
	if (!basket.total && !basket.paymentSummary.subTotal) return null;

	const price = priceToEcommercePrice(
		basket.total || basket.paymentSummary.subTotal,
	);
	return {
		...price,
		items: basket.items.map((product) => orderItemToEcommerceItem(product)),
	};
}
