import { createTransform } from 'redux-persist';

/** Reset eventsInProgress for all reducers when rehydrating store */
export const LoadingStateTransformer = createTransform(
	// Transform state being rehydrated
	(outboundState: { eventsInProgress?: number }) => {
		if (outboundState.eventsInProgress) {
			return { ...outboundState, eventsInProgress: 0 };
		}
		return outboundState;
	},
);
