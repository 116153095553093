import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ILoyaltyApiState } from '../loyalty.types';

export const initialLoyaltyApiState: ILoyaltyApiState = {
	eventsInProgress: 0,
	brandLoyaltySchemes: [],
};

const loyaltyApiSlice = createSlice({
	name: 'loyaltyApi',
	initialState: initialLoyaltyApiState,
	reducers: {
		GET_BRAND_LOYALTY_SCHEMES(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BRAND_LOYALTY_SCHEMES_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				brandLoyaltySchemes: action.payload.data.loyaltySchemes,
			};
		},
		GET_BRAND_LOYALTY_SCHEMES_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ACTIVE_SCHEME_DETAILS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ACTIVE_SCHEME_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeLoyaltySchemeDetails: action.payload.data,
			};
		},
		GET_ACTIVE_SCHEME_DETAILS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_LOYALTY_API_STATE(state) {
			return {
				...initialLoyaltyApiState,
			};
		},
	},
});

export const {
	GET_BRAND_LOYALTY_SCHEMES,
	GET_BRAND_LOYALTY_SCHEMES_FAIL,
	GET_BRAND_LOYALTY_SCHEMES_SUCCESS,
	GET_ACTIVE_SCHEME_DETAILS,
	GET_ACTIVE_SCHEME_DETAILS_FAIL,
	GET_ACTIVE_SCHEME_DETAILS_SUCCESS,
	RESET_LOYALTY_API_STATE,
} = loyaltyApiSlice.actions;

/**
 * Get loyalty schemes associated to a brand
 * @param brandId
 * @returns
 */
export const getBrandLoyaltySchemes = (brandId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_BRAND_LOYALTY_SCHEMES({
			request: {
				method: 'get',
				url: `/1/loyalty/brand/${brandId}`,
			},
		}),
	);

	return response.payload?.data.loyaltySchemes;
};

export const getActiveSchemeDetails = (schemeId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_ACTIVE_SCHEME_DETAILS({
			request: {
				method: 'get',
				url: `/1/loyalty/${schemeId}`,
			},
		}),
	);

	return response.payload?.status === 200;
};

export const resetLoyaltyApiState = () => async (dispatch: Dispatch) =>
	dispatch(RESET_LOYALTY_API_STATE());

export default loyaltyApiSlice.reducer;
