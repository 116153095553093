import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IVouchersState,
	IVoucherToAdd,
	TVoucherStatus,
} from './vouchers.types';

export const initialVouchersState: IVouchersState = {
	vouchersSheetVisible: false,
	vouchers: [],
	preventApplyingVouchers: true,
	scannerVisible: false,
	updated: false,
};

const vouchersSlice = createSlice({
	name: 'vouchers',
	initialState: initialVouchersState,
	reducers: {
		SET_VOUCHERS(state: IVouchersState, action: { payload: IVoucherToAdd[] }) {
			return {
				...state,
				vouchers: action.payload,
				preventApplyingVouchers: true,
				updated: false,
			};
		},
		ADD_VOUCHER(state: IVouchersState, action: { payload: IVoucherToAdd }) {
			return {
				...state,
				vouchers: [...state.vouchers, action.payload],
				preventApplyingVouchers: false,
				updated: true,
			};
		},
		REMOVE_VOUCHER(state: IVouchersState, action: { payload: string }) {
			return {
				...state,
				// Removes the voucher
				preventApplyingVouchers: false,
				vouchers: state.vouchers.map((voucher) =>
					voucher.code === action.payload
						? { ...voucher, status: 'removed' as TVoucherStatus }
						: voucher,
				),
				updated: true,
			};
		},
		CLEAR_VOUCHERS(state: IVouchersState) {
			return {
				...state,
				vouchers: [],
			};
		},
		SET_VOUCHER_SHEET_VISIBLE(
			state: IVouchersState,
			action: { payload: boolean },
		) {
			return {
				...state,
				vouchersSheetVisible: Boolean(action.payload),
			};
		},
		SET_SCANNER_VISIBLE(state: IVouchersState, action: { payload: boolean }) {
			return {
				...state,
				scannerVisible: Boolean(action.payload),
			};
		},
	},
});

export const {
	SET_VOUCHERS,
	ADD_VOUCHER,
	REMOVE_VOUCHER,
	CLEAR_VOUCHERS,
	SET_VOUCHER_SHEET_VISIBLE,
	SET_SCANNER_VISIBLE,
} = vouchersSlice.actions;

/**
 * Opens and closes the voucher bottom sheet
 */
export const setVoucherSheetVisibility = (val: boolean) => (
	dispatch: Dispatch,
) => {
	dispatch(SET_VOUCHER_SHEET_VISIBLE(val));
};

/** Overwrites the vouchers array. Used to pass in values from the activeBasket */
export const setVouchers = (vouchers: IVoucherToAdd[]) => async (
	dispatch: Dispatch,
) => {
	return dispatch(SET_VOUCHERS(vouchers));
};

/** Adds a voucher to the vouchers array */
export const addVoucher = (voucher: IVoucherToAdd) => async (
	dispatch: Dispatch,
) => {
	return dispatch(ADD_VOUCHER(voucher));
};

/** Removes a voucher from the vouchers array by code (as ID is optional) */
export const removeVoucher = (code: string) => async (dispatch: Dispatch) => {
	return dispatch(REMOVE_VOUCHER(code));
};

export const setScannerVisible = (val: boolean) => (dispatch: Dispatch) => {
	return dispatch(SET_SCANNER_VISIBLE(val));
};

export default vouchersSlice.reducer;
