import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import featureToggleReducer, {
	initialFeatureTogglesState,
} from '../feature-toggles/feature-toggles.slice';

import loadingReducer from 'components/loading/loading.slice';
import toastReducer, { initialToastState } from 'components/toast/toast.slice';
import askForAngelaReducer, {
	initialAskForAngelaState,
} from 'modules/ask-for-angela/ask-for-angela.slice';
import authReducer from 'modules/auth/auth.slice';
import basketReducer, { initialBasketState } from 'modules/basket/basket.slice';
import brandReducer, { initialBrandState } from 'modules/brand/brand.slice';
import checkoutReducer, {
	initialCheckoutState,
} from 'modules/checkout/checkout.slice';
import appPreferencesReducer from 'modules/core/app-preferences/app-preferences.slices';
import navigationReducer, {
	initialNavigationState,
} from 'modules/core/navigation/navigation.slice';
import storeVersionReducer from 'modules/core/state/store-version/store-version.slice';
import themeReducer, {
	initialThemeState,
} from 'modules/core/theme/theme.slice';
import discountReducer, {
	initialDiscountState,
} from 'modules/discounts/discount.slice';
import feedbackReducer, {
	initialFeedbackState,
} from 'modules/feedback/feedback.slice';
import giftingApiReducer, {
	initialGiftingApiState,
} from 'modules/gifting/gifting-state/gifting-api.slice';
import giftingReducer, {
	initialGiftingState,
} from 'modules/gifting/gifting-state/gifting.slice';
import loyaltyApiReducer, {
	initialLoyaltyApiState,
} from 'modules/loyalty/loyalty-state/loyalty-api.slice';
import menuReducer, { initialMenuState } from 'modules/menu/menu.slice';
import orderHistoryApiReducer, {
	initialOrderHistoryApiState,
} from 'modules/order-history/order-history-api.slice';
import orderHistoryReducer, {
	initialOrderHistoryState,
} from 'modules/order-history/order-history.slice';
import orderReducer, { initialOrderState } from 'modules/order/order.slice';
import payGoReducer, { initialPayGoState } from 'modules/pay-go/pay-go.slice';
import paymentReducer, {
	initialPaymentState,
} from 'modules/payment/payment.slice';
import productApiReducer, {
	initialProductApiState,
} from 'modules/product/slices/product-api.slice';
import productReducer, {
	initialProductState,
} from 'modules/product/slices/product.slice';
import profileReducer, {
	initialProfileState,
} from 'modules/profile/profile.slice';
import serviceWaitTimeReducer, {
	initialServiceWaitTimeState,
} from 'modules/service-wait-time/service-wait-time.slice';
import splitBillReducer, {
	initialSplitBillState,
} from 'modules/split-bill/split-bill.slice';
import subscriptionReducer, {
	initialSubscriptionState,
} from 'modules/subscription/subscription.slice';
import tabPickupReducer, {
	initialTabPickupState,
} from 'modules/tab-pickup/tab-pickup.slice';
import upsellReducer, { initialUpsellState } from 'modules/upsell/upsell.slice';
import venueReducer, { initialVenueState } from 'modules/venue/venue.slice';
import vouchersApiReducer, {
	initialVouchersApiState,
} from 'modules/vouchers/vouchers-api.slice';
import vouchersReducer, {
	initialVouchersState,
} from 'modules/vouchers/vouchers.slice';

// Define root reducer
const combinedReducers = combineReducers({
	appPreferences: appPreferencesReducer,
	askForAngela: askForAngelaReducer,
	auth: authReducer,
	basket: basketReducer,
	brand: brandReducer,
	checkout: checkoutReducer,
	discount: discountReducer,
	featureToggles: featureToggleReducer,
	feedback: feedbackReducer,
	gifting: giftingReducer,
	giftingApi: giftingApiReducer,
	loading: loadingReducer,
	loyaltyApi: loyaltyApiReducer,
	menu: menuReducer,
	navigation: navigationReducer,
	order: orderReducer,
	orderHistory: orderHistoryReducer,
	orderHistoryApi: orderHistoryApiReducer,
	payGo: payGoReducer,
	payment: paymentReducer,
	product: productReducer,
	productApi: productApiReducer,
	profile: profileReducer,
	splitBill: splitBillReducer,
	storeVersion: storeVersionReducer,
	subscription: subscriptionReducer,
	tabPickup: tabPickupReducer,
	theme: themeReducer,
	toast: toastReducer,
	upsell: upsellReducer,
	venue: venueReducer,
	vouchers: vouchersReducer,
	vouchersApi: vouchersApiReducer,
	serviceWaitTime: serviceWaitTimeReducer,
});

// Create and export a type for root reducer
export type RootState = ReturnType<typeof combinedReducers>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
	// Create current state varible
	let currentState = state;
	// if logout, set state to undefined
	action.type === 'auth/LOGOUT' && (currentState = undefined);
	// If new version, keep user logged in but reset other stores
	action.type === 'storeVersion/NEW_VERSION' &&
		(currentState = {
			...state,
			askForAngela: initialAskForAngelaState,
			basket: initialBasketState,
			brand: initialBrandState,
			checkout: initialCheckoutState,
			discount: initialDiscountState,
			featureToggles: initialFeatureTogglesState,
			feedback: initialFeedbackState,
			gifting: initialGiftingState,
			giftingApi: initialGiftingApiState,
			loyaltyApi: initialLoyaltyApiState,
			menu: initialMenuState,
			navigation: initialNavigationState,
			order: initialOrderState,
			orderHistory: initialOrderHistoryState,
			orderHistoryApi: initialOrderHistoryApiState,
			payGo: initialPayGoState,
			payment: initialPaymentState,
			product: initialProductState,
			productApi: initialProductApiState,
			profile: initialProfileState,
			splitBill: initialSplitBillState,
			subscription: initialSubscriptionState,
			tabPickup: initialTabPickupState,
			theme: initialThemeState,
			toast: initialToastState,
			upsell: initialUpsellState,
			venue: initialVenueState,
			vouchers: initialVouchersState,
			vouchersApi: initialVouchersApiState,
			serviceWaitTime: initialServiceWaitTimeState,
		});

	// If reset loading states, set events in progress to 0
	action.type === 'loading/RESET_LOADING_STATES' &&
		(currentState = {
			...state,
			auth: {
				...state.auth,
				eventsInProgress: 0,
				refreshToken: {
					...state.auth.refreshToken,
					refreshing: false,
				},
			},
			askForAngela: {
				...state.askForAngela,
			},
			loading: {
				...state.loading,
				eventsInProgress: 0,
			},
			basket: {
				...state.basket,
				eventsInProgress: 0,
			},
			brand: {
				...state.brand,
				eventsInProgress: 0,
			},
			feedback: {
				...state.feedback,
				eventsInProgress: 0,
			},
			giftingApi: {
				...state.giftingApi,
				eventsInProgress: 0,
			},
			gifting: {
				...state.gifting,
			},
			menu: {
				...state.menu,
				eventsInProgress: 0,
			},
			navigation: { ...state.navigation },
			order: {
				...state.order,
				eventsInProgress: 0,
			},
			orderHistory: {
				...state.orderHistory,
				eventsInProgress: 0,
			},
			orderHistoryApi: {
				...state.orderHistoryApi,
				eventsInProgress: 0,
			},
			payment: {
				...state.payment,
				eventsInProgress: 0,
			},
			payGo: {
				...state.payGo,
				eventsInProgress: 0,
			},
			product: {
				...state.product,
			},
			productApi: {
				...state.productApi,
				eventsInProgress: 0,
			},
			splitBill: {
				...state.splitBill,
				eventsInProgress: 0,
			},
			subscription: {
				...state.subscription,
				eventsInProgress: 0,
			},
			tabPickup: {
				...state.tabPickup,
				eventsInProgress: 0,
			},
			venue: {
				...state.venue,
				eventsInProgress: 0,
			},
			upsell: {
				...state.upsell,
				eventsInProgress: 0,
			},
			profile: {
				...state.profile,
				eventsInProgress: 0,
			},
			vouchersApi: {
				...state.vouchersApi,
				eventsInProgress: 0,
			},
			serviceWaitTime: {
				...state.serviceWaitTime,
				eventsInProgress: 0,
			},
		});
	// return root reducer
	return combinedReducers(currentState, action);
};

export default rootReducer;
