import { createSlice, Dispatch } from '@reduxjs/toolkit';

export const initialAskForAngelaState = {
	open: false,
	venueWaveTraining: false,
};

const askForAngelaSlice = createSlice({
	name: 'askForAngela',
	initialState: initialAskForAngelaState,
	reducers: {
		SET_OPEN(state, action) {
			return {
				...state,
				open: action.payload.open,
				venueWaveTraining: Boolean(action.payload.venueWaveTraining),
			};
		},
	},
});

export const { SET_OPEN } = askForAngelaSlice.actions;

/** Set ask for angela open or closed */
export const setAskForAngelaOpen = (config: {
	open: boolean;
	venueWaveTraining?: boolean;
}) => async (dispatch: Dispatch) => {
	dispatch(SET_OPEN(config));
};

export default askForAngelaSlice.reducer;
