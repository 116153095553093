import { Severity } from '@sentry/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import {
	fullBrowserVersion,
	osName,
	osVersion,
	browserName,
} from 'react-device-detect';
import { Store } from 'redux';
import Cookies from 'universal-cookie/lib/Cookies';
import * as uuid from 'uuid';

import { handleApiError } from '../error/error.service';
import eventLogger from '../error/helpers/event-logger.helper';
import { history } from '../routing/app-router.component';

import { isInAndroidWebview } from 'helpers/device.helper';
import { processLogout } from 'modules/auth/auth.slice';

// Instantiate universal cookie
const cookies = new Cookies();
// Get client session id cookie
let clientId = cookies.get('clientId');
// If we don't have a session already
if (!clientId) {
	// Create a session id
	clientId = uuid.v4();
	// Set the session id in a cookie
	cookies.set('clientId', uuid.v4());
}

const inAndroid = isInAndroidWebview();

/** Create and configure axios instance */
const httpClient = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	timeout: parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!),
	headers: inAndroid
		? {
			'x-app-version': process.env.REACT_APP_VERSION,
			'x-device-model': fullBrowserVersion,
			'x-device-manufacturer': 'Android',
			'x-device-os': `${osName}:${osVersion}`,
			'x-device-identifier': clientId,
			'x-device-serial-number': clientId,
		  }
		: {
			'x-app-name': process.env.REACT_APP_NAME,
			'x-app-version': process.env.REACT_APP_VERSION,
			'x-device-model': fullBrowserVersion,
			'x-device-manufacturer': browserName,
			'x-device-os': `${osName}:${osVersion}`,
			'x-device-identifier': clientId,
			'x-device-serial-number': clientId,
		  },
});

/** Create a mocked axios client */
export const mockHttpClient = new MockAdapter(httpClient, {
	// Only mock matched/configured routes
	onNoMatch: 'passthrough',
});

/** Mocked endpoints */
const mockedEndpoints = () => {};

// If api mocking is enabled - mock api
process.env.REACT_APP_API_MOCKING === 'true' && mockedEndpoints();

// Set the retry wait time to axios timeout / 5
const retryWaitTime = parseFloat(process.env.REACT_APP_AXIOS_TIMEOUT!) / 5;

const retryBlacklist: string[] = [];
const ignoreAuthHeaderList: string[] = [];

// Axios redux middleware config
export const httpMiddlewareConfig = {
	interceptors: {
		request: [
			({ getState }: Store, req: AxiosRequestConfig) => {
				// Create a copy of request to work with
				const request = { ...req };

				// If we have an access token
				if (
					!ignoreAuthHeaderList.includes(request.url!) &&
					getState().auth.accessToken.token
				) {
					// Add it to the request headers
					request.headers = {
						...req.headers,
						Authorization: `Bearer ${getState().auth.accessToken.token}`,
					};
				}

				return request;
			},
		],
		response: [
			{
				async error({ getState, dispatch }: Store, error: AxiosError) {
					// If, error was a 401
					if (
						error?.response?.status === 401 &&
						!retryBlacklist.includes(error.response?.config.url!)
					) {
						// If access token refreshing
						if (getState().auth.refreshToken.refreshing) {
							// Retry the request 5 times
							for (let i = 0; i < 5; i++) {
								// eslint-disable-next-line no-await-in-loop
								await new Promise((resolve) =>
									setTimeout(resolve, retryWaitTime),
								);

								try {
									// eslint-disable-next-line no-await-in-loop
									const retry = await axios(error.config);

									if (retry.status === 200) {
										return retry;
									}
								} catch {
									//
								}
							}
						}

						try {
							// eslint-disable-next-line no-await-in-loop
							await new Promise((resolve) =>
								setTimeout(resolve, retryWaitTime),
							);

							// Retry request one last time
							const finalTry = await axios(error.config);

							if (finalTry.status === 200) {
								return finalTry;
							}
						} catch (e) {
							// If user logged in
							if (getState().auth?.user?.id) {
								// Track 401 logout event
								eventLogger({
									message: 'Logout (HTTP)',
									level: Severity.Warning,
									extra: { error: e, authState: getState().auth },
								});

								// @ts-ignore // Call logout thunk
								await dispatch(processLogout());
								// Redirect user to login
								history.push('/');

								return handleApiError(error);
							}
						}
					}

					return handleApiError(error);
				},
			},
		],
	},
};

// Export auth API as object
export default httpClient;
