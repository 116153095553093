import trackerRedux from '@openreplay/tracker-redux';
import { PayloadAction } from '@reduxjs/toolkit';

import { tracker } from '../logging/initialise-logs.helper';
import { RootState } from './root.reducer';

// Configure OpenReplay redux middleware
const openReplayMiddleware = tracker.use(
	trackerRedux({
		actionTransformer: (action: PayloadAction) => {
			// Remove auth and persist requests as they have sensitive information within
			if (action.type.includes('auth/') || action.type.includes('persist/')) {
				return null;
			}
			return action;
		},
		stateTransformer: (state: RootState) => {
			return {
				...state,
				auth: undefined,
			};
		},
	}),
);

export default openReplayMiddleware;
