import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { motion } from 'framer-motion';
import { rgba } from 'polished';
import React, { useState, useRef, FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Button from 'components/button/button.component';
import Icon from 'components/icons/icon.component';
import animations from 'helpers/animations.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setUserCoords } from 'modules/auth/auth.slice';

const librariesToLoad: Libraries = ['places'];

const inputTypeography = css`
	color: ${brand.black};
	font-weight: ${fonts.weights.medium};
	font-size: ${fonts.sizes.large};
	line-height: ${fonts.lineHeight.small};
`;

const StyledWrap = styled.div`
	position: relative;
	flex: 1;
`;

const StyledInput = styled.input<{ buttonPosition: 'left' | 'right' }>`
	padding: 0;
	border-radius: 0;
	height: 54px;
	width: 100%;
	border: none;
	outline: none;
	${inputTypeography}

	${({ buttonPosition }) => {
		if (buttonPosition === 'left') {
			return css`
				padding-left: 28px;
			`;
		}
		return css`
			padding-right: 20px;
		`;
	}}

	&:focus {
		border-color: ${({ theme }) => theme.colors.link};
		outline: none;
	}
	&.has-error {
		background: ${rgba(brand.validationError, 0.05)};
		border-color: ${brand.validationError};
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: ${brand.black};
		opacity: 0.5;
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		color: ${brand.black};
		opacity: 0.5;
	}
	:-ms-input-placeholder {
		/* IE 10+ */
		color: ${brand.black};
		opacity: 0.5;
	}
	:-moz-placeholder {
		/* Firefox 18- */
		color: ${brand.black};
		opacity: 0.5;
	}
`;

const StyledSearchIcon = styled(Icon)<{ buttonPosition: 'left' | 'right' }>`
	position: absolute;
	top: 0;
	bottom: 0;
	width: 20px;
	height: 100%;
	pointer-events: none;

	${({ buttonPosition }) => {
		if (buttonPosition === 'left') {
			return css`
				left: 0;
			`;
		}
		return css`
			right: 0;
		`;
	}}
`;

const StyledCloseButton = styled(Button)`
	margin: 0;
	width: 20px;
	padding: 0;
`;

const StyledClose = styled(motion.div)<{ buttonPosition: 'left' | 'right' }>`
	top: 0;
	background: ${brand.white};
	bottom: 0;
	position: absolute;
	width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ buttonPosition }) => {
		if (buttonPosition === 'left') {
			return css`
				left: 0;
			`;
		}
		return css`
			right: 0;
		`;
	}}
`;

const StyledAutocomplete = styled(Autocomplete)`
	width: 100%;
`;

// Inerface for component props
interface IComponentProps {
	fieldVisible?: boolean;
	setVisibility: (visibility: boolean) => void;
	buttonPosition?: 'left' | 'right';
}

/** Venue list search */
const VenueListSearchComponent: FunctionComponent<IComponentProps> = ({
	fieldVisible,
	setVisibility,
	buttonPosition = 'right',
}) => {
	// Get hooks
	const dispatch = useReduxDispatch();
	const intl = useIntl();
	const { search } = useLocation();
	const [inputVal, setInputVal] = useState('');
	const [autoComplete, setAutoComplete] = useState<
		google.maps.places.Autocomplete
	>();
	const inputRef = useRef<HTMLInputElement>(null);

	/** Callback for searching for a place */
	const handlePlaceChange = async () => {
		if (!autoComplete) return;

		// Get place from places API
		const place = autoComplete.getPlace();
		// Get coords from place
		const latitude = place.geometry?.location?.lat();
		const longitude = place.geometry?.location?.lng();

		// If we have coords
		if (!latitude || !longitude) return;

		// Create coords object
		const coords = { latitude, longitude };

		// Set the coords as user's location
		await dispatch(setUserCoords(coords));

		// Hide the search component
		!!setVisibility && setVisibility(false);
	};

	/** Callback for the close button */
	const handleClose = () => {
		setInputVal('');
		setVisibility(false);
	};

	useEffect(() => {
		if (!inputRef.current) return;

		if (fieldVisible) {
			// Focus the input when it is "opened"
			inputRef.current.focus();
		}
		// Clear the input when it gets "closed"
		setInputVal('');
	}, [fieldVisible, inputRef.current]);

	// If the url is suffixed with ?autofocus=true, then we should focus the input
	useEffect(() => {
		// URLSearchParams basically turns a query string into a Map<string, string> of the search params
		const searchParams = new URLSearchParams(search);
		if (searchParams.get('autofocus') === 'true') {
			const af = requestAnimationFrame(() => {
				setVisibility(true);
			});
			return () => cancelAnimationFrame(af);
		}
		return () => {};
	}, [search, setVisibility]);

	return (
		<LoadScript
			googleMapsApiKey={process.env.REACT_APP_GMAPS_KEY!}
			libraries={librariesToLoad}
			loadingElement={null}
		>
			<StyledWrap>
				{/*
					The input element below isn't a controlled element, as the Autocomplete component handles that.
					onChange just lets us observe changes to the value of the input.
				*/}
				<StyledAutocomplete
					onLoad={(ref) => setAutoComplete(ref)}
					onPlaceChanged={handlePlaceChange}
					options={{
						fields: ['geometry.location'],
						types: ['establishment', 'geocode'],
						componentRestrictions: { country: ['GB', 'GG', 'JE'] },
					}}
				>
					<StyledInput
						buttonPosition={buttonPosition}
						value={inputVal}
						onChange={(event) => {
							event.preventDefault();
							setInputVal(event.target.value);
						}}
						ref={inputRef}
						data-testid="location-search"
						type="text"
						placeholder={intl.formatMessage({ id: 'venue.search.title' })}
						onFocus={() => setVisibility(true)}
					/>
				</StyledAutocomplete>
				{buttonPosition === 'left' ? (
					<StyledSearchIcon
						buttonPosition="left"
						name="search"
						width={20}
						height={20}
						{...animations.slideInLeft}
						transition={{ delay: 0.3, duration: 0.2 }}
					/>
				) : (
					<StyledSearchIcon
						buttonPosition="right"
						name="search"
						width={20}
						height={20}
						{...animations.slideInRight}
						transition={{ delay: 0.3, duration: 0.2 }}
					/>
				)}

				<StyledClose
					buttonPosition={buttonPosition}
					style={{
						pointerEvents: fieldVisible ? 'auto' : 'none',
					}}
					animate={
						fieldVisible && !!inputVal
							? {
								rotate: 180,
								opacity: 1,
							  }
							: {
								rotate: 0,
								opacity: 0,
							  }
					}
					transition={{ duration: 0.3 }}
				>
					<StyledCloseButton
						variant="tertiary"
						onClick={handleClose}
						aria-label="Close"
					>
						<Icon name="close" width={20} height={20} />
					</StyledCloseButton>
				</StyledClose>
			</StyledWrap>
		</LoadScript>
	);
};

export default VenueListSearchComponent;
