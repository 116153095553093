import { setContext, setUser } from '@sentry/react';

import { tracker } from './initialise-logs.helper';

interface IIdentifiedUser {
	id: string;
	name: string;
	email: string;
}

const identifyUser = (user: IIdentifiedUser) => {
	// Identify user in sentry
	setUser({
		id: user.id,
		email: user.email,
		username: user.name,
	});
	setContext('user', user);

	// Identify user in OpenReplay
	tracker.setUserID(user.email);
	tracker.setMetadata('userId', user.id);
	tracker.setMetadata('email', user.email);
	tracker.setMetadata('username', user.name);
};

export default identifyUser;
