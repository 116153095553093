import { isDayNumberToday } from 'helpers/current-day-number.helper';
import dayjs from 'helpers/dayjs.helper';
import { TServiceType } from 'modules/basket/basket.types';
import { IMenu } from 'modules/menu/menu.types';

/** Check if a menu is available */
export const isMenuAvailable = (
	menu: IMenu,
	timeSlot?: Date,
	serviceType?: TServiceType,
) => {
	// Set service time to timeslot, or now
	let serviceTime = timeSlot;
	if (serviceType === 'tableService' || serviceType === 'selfService') {
		serviceTime = dayjs().toDate();
	}

	if (serviceTime && serviceType) {
		// Get menu times for selected service type
		const menuTimes = menu.serviceTypes.find(
			(type) => type.name === serviceType,
		);

		if (!menuTimes) {
			return false;
		}

		// Check if timeslot is between menu opening and closing times
		const isAvailable = !!menuTimes.availability.find(
			(menuTime) =>
				(dayjs(serviceTime).isSame(dayjs(menuTime.openTime)) ||
					dayjs(serviceTime).isAfter(dayjs(menuTime.openTime))) &&
				dayjs(serviceTime).isBefore(dayjs(menuTime.closeTime)),
		);

		return isAvailable || menuTimes.availability.length === 0;
	}
	return false;
};

/** Check if a menu should be hidden (not active service type) */
export const isMenuVisible = (menu: IMenu, serviceType?: TServiceType) => {
	if (serviceType) {
		// Get menu times for selected service type
		return menu.serviceTypes.some((type) => type.name === serviceType);
	}
	return false;
};

/** Get today's opening times for a menu */
export const menuOpeningTimesToday = (
	menu: IMenu,
	serviceType?: TServiceType,
) => {
	if (serviceType) {
		// Get menu times for selected service type
		const menuTimes = menu.serviceTypes.find(
			(type) => type.name === serviceType,
		);

		if (!menuTimes) {
			return false;
		}

		// Get today's opening times
		const todaysOpeningTimes = menuTimes.availability.find((day) =>
			isDayNumberToday(day.day),
		);

		return todaysOpeningTimes;
	}
	return false;
};
