import { createTransform } from 'redux-persist';

/** Reset pairing visible when rehydrating store */
export const PairingVisibleTransformer = createTransform(
	// Transform state being rehydrated
	(outboundState: { isSuggestedPairingVisible?: boolean }) => {
		if (outboundState.isSuggestedPairingVisible) {
			return { ...outboundState, isSuggestedPairingVisible: false };
		}
		return outboundState;
	},
);
