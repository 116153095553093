import rgba from 'polished/lib/color/rgba';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledCoverGradient = styled.div`
	top: 0;
	right: 0;
	left: 0;
	height: 66px;
	position: absolute;
`;

interface IComponentProps {
	color?: string;
}

const CoverGradient: FunctionComponent<IComponentProps> = ({ color }) => {
	if (!color) return null;
	return (
		<StyledCoverGradient
			role="presentation"
			style={{
				backgroundImage: `linear-gradient(
            180deg, 
            ${color} 0%, 
            ${rgba(color, 0)} 100%
        )`,
			}}
		/>
	);
};

export default CoverGradient;
