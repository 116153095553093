import React, { useEffect, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { setAskForAngelaOpen } from '../ask-for-angela.slice';

import waveLogo from 'assets/images/wave-logo.png';
import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { ReactComponent as Logo } from 'assets/svg/ask-for-angela/ask-for-angela-logo.svg';
import BottomSheet from 'components/bottom-sheet/bottom-sheet.component';
import Button from 'components/button/button.component';
import customEvent, {
	ECustomEvent,
} from 'modules/core/analytics/events/custom.event';
import { RootState } from 'modules/core/state/root.reducer';

const StyledLogo = styled(Logo)`
	width: 158px;
	height: 22px;
	margin-top: 40px;
	margin-bottom: 24px;
	margin-left: 30px;
`;

const StyledList = styled.ol`
	list-style: none;
	padding: 0;
	margin: 24px 0 40px;
`;

const StyledListItem = styled.li`
	padding-left: 25px;
	position: relative;
	margin-bottom: 16px;
	&::before {
		position: absolute;
		content: '';
		display: block;
		width: 9px;
		height: 9px;
		border-radius: 50%;
		background: ${brand.askForAngela};
		left: 0;
		top: 6px;
	}
`;

const StyledStepTitle = styled.span`
	font-weight: ${fonts.weights.medium};
	display: block;
`;

const StyledButton = styled(Button)`
	width: 100%;
	background: ${brand.text};
	color: ${brand.white};
`;

const StyledTextContainer = styled.div`
	padding: 0 30px;
	font-size: ${fonts.sizes.med};
`;

const StyledActionContainer = styled.div`
	padding: 0 15px 20px;
	width: 100%;
`;

const StyledWaveTrainingFooter = styled.div`
	margin-top: 40px;
	text-align: center;
	font-size: ${fonts.sizes.small};
	img {
		width: 50px;
		height: auto;
		display: block;
		margin: 0 auto 8px;
	}
	strong {
		font-weight: ${fonts.weights.semibold};
	}
	em {
		display: inline-block;
	}
`;

const AskForAngelaDetails: FunctionComponent = () => {
	const dispatch = useDispatch();
	const { open, venueWaveTraining } = useSelector(
		(state: RootState) => state.askForAngela,
	);

	const closeSheet = () =>
		dispatch(
			setAskForAngelaOpen({
				open: false,
			}),
		);

	useEffect(() => {
		open && customEvent(ECustomEvent.askForAngelaDetails);
	}, [open]);

	if (!open) return null;

	return (
		<BottomSheet hero={<StyledLogo />} handleClose={closeSheet}>
			<StyledTextContainer data-testid="ask-for-angela-details">
				<p>
					<FormattedMessage id="askForAngela.details.message" />
				</p>
				<StyledList>
					{Array.from(Array(3)).map((_, i) => (
						<StyledListItem key={`step-${i + 1}`}>
							<StyledStepTitle>
								<FormattedMessage
									id={`askForAngela.details.steps.${i + 1}.title`}
								/>
							</StyledStepTitle>
							<FormattedMessage
								id={`askForAngela.details.steps.${i + 1}.message`}
							/>
						</StyledListItem>
					))}
				</StyledList>
				{venueWaveTraining && (
					<StyledWaveTrainingFooter>
						<img src={waveLogo} alt="Wave Training" />
						<FormattedMessage tagName="p" id="askForAngela.details.footer" />
					</StyledWaveTrainingFooter>
				)}
			</StyledTextContainer>
			<StyledActionContainer>
				<StyledButton onClick={closeSheet}>
					<FormattedMessage id="askForAngela.details.action" />
				</StyledButton>
			</StyledActionContainer>
		</BottomSheet>
	);
};

export default AskForAngelaDetails;
