import { darken, lighten, rgba } from 'polished';

/** Brand colour variables */
const brand = {
	black: '#000000',
	opBlack: '#101010',
	white: '#ffffff',
	body: '#EDEEF2',
	neutral: '#f2f2f2',
	neutralDark: '#e7e9ec',
	neutralLight: '#fefefe',
	neutralMid: '#F4F6F9',
	waitLight: '#FAD9D7',
	keylineLight: '#E2E2E2',
	selectBackground: '#e9edf3',
	backgroundLight: '#B9C7ED',
	backgroundLighter: '#EBEEF3',
	backgroundGrey: '#DBE2ED',
	borders: rgba(0, 0, 0, 0.2),
	bordersLight: '#E6E6E6',
	bordersDark: '#D2D2DC',
	buttonDark: '#343434',
	text: '#101010',
	textLight: '#545454',
	textLighter: '#5B677A',
	placeholder: rgba('#000000', 0.6),
	get headings() {
		return this.text;
	},
	primary: '#116DFF',
	secondary: '#3f8434',
	secondaryLight: '#CDF4DF',
	checkoutPending: '#c75300',
	genericError: '#D63636',
	get link() {
		return this.primary;
	},
	get placeholderLight() {
		return rgba('#3C3C43', 0.6);
	},
	get linkHover() {
		return lighten(0.02, this.primary);
	},
	get linkHoverSecondary() {
		return lighten(0.02, this.secondary);
	},
	get linkActive() {
		return lighten(0.08, this.primary);
	},
	get validationDisabled() {
		return this.neutral;
	},
	get genericErrorLight() {
		return rgba(this.genericError, 0.2);
	},
	lightBlue: '#e5ecff',
	validationError: '#dc3545',
	validationSuccess: '#208738',
	validationWarning: '#E84239',
	validationPending: '#FF8A36',
	successTick: '#68BA5B',
	askForAngela: '#e7205d',
	get askForAngelaDark() {
		return darken(0.05, this.askForAngela);
	},
	askForAngelaBorder: 'rgba(234, 21, 83, 0.6)',
	askForAngelaBorderLighter: 'rgba(255, 116, 227, 0.6)',
	paymentOptionOrderAndPay: '#5AF091',
	ukraineYellow: '#f8d648',
	ukraineBlue: '#2559b4',
	gold: '#8F7247',
	tastecardLight: '#EDF6D9',
	tastecard: '#88C400',
	success: '#56B447',
};

export default brand;
