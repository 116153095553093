import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { configureScope, init } from '@sentry/react';

// Create new instance of OpenReplay tracker
export const tracker = new Tracker({
	projectKey: 'pM6J9o1bQST2eG8OYC7R', // TODO - from .env
	ingestPoint: 'https://openreplay.orderpay.com/ingest', // TODO - from .env
	revID: process.env.REACT_APP_VERSION,
	capturePerformance: true,
	network: {
		failuresOnly: false,
		sessionTokenHeader: false,
		capturePayload: true,
		captureInIframes: true,
		ignoreHeaders: [],
	},
	captureIFrames: true,
	obscureTextEmails: false,
	obscureInputEmails: false,
	defaultInputMode: 0,
	__DISABLE_SECURE_MODE: process.env.REACT_APP_ENVIRONMENT === 'local', // for local testing
});

/** Initialises logging tools */
const initialiseLogs = () => {
	// If we're not in local dev environment
	if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

	// eslint-disable-next-line no-console
	console.log(
		`%c  OrderPay Web Ordering (v${process.env.REACT_APP_VERSION}) - ${process.env.REACT_APP_ENVIRONMENT}`,
		[
			'background-color: #101010;',
			'background-image: url("https://cms.orderpay.com/apple-touch-icon.png")',
			'background-size: contain',
			'background-position: top left',
			'background-repeat: no-repeat',
			'padding: 20px 20px 20px 50px',
			'color: white',
			'font-size: 20px;',
		].join(';'),
	);

	try {
		// If we have sentry config
		if (process.env.REACT_APP_SENTRY_DSN) {
			// Initialise sentry
			init({
				dsn: process.env.REACT_APP_SENTRY_DSN,
				release: process.env.REACT_APP_VERSION,
				ignoreErrors: [
					// https://github.com/getsentry/sentry-javascript/issues/3440
					'Object Not Found Matching Id',
					// https://checkfer.sentry.io/issues/5880662277/
					'No Listener: pcp_refresh',
					// https://checkfer.sentry.io/issues/2344208822
					'Could not load "places_impl".',
					// https://checkfer.sentry.io/issues/6036030177 - This is happening inside the openreplay library, not affecting our code
					"Cannot read properties of null (reading 'apply')",
					"null is not an object (evaluating 'n.apply')",
					"Cannot read properties of null (reading 'apply')",
				],
			});
		}

		// Start OpenReplay tracker
		tracker.use(trackerAssist());
		tracker.start().then((data) => {
			configureScope((scope) => {
				// Add OpenReplay session URL to sentry extra data
				scope.setExtra('openReplayURL', tracker.getSessionURL());
			});
		});

		// eslint-disable-next-line no-console
		console.info(
			`Logging initialised: (v${process.env.REACT_APP_VERSION}) - ${process.env.REACT_APP_ENVIRONMENT}`,
		);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('Logging: Failed to load logging plugins.');
	}
};

export default initialiseLogs;
