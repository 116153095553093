import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	IGift,
	ICreatedGiftCard,
	IGiftingState,
	TGetGiftType,
} from '../gifting.types';

export const initialGiftingState: IGiftingState = {
	giftType: 'received',
	giftOtherAmountVisibility: false,
	createdGiftCard: {
		recipientName: '',
		amount: 0,
		title: '',
		message: '',
		imageUrl: '',
	},
	createdGiftCardAmount: 0,
	isGiftPaymentVisible: false,
	isGiftPaymentCardInputVisible: false,
	isPaymentProcessing: false,
	isGiftCreateTermsVisible: false,
};

const giftingSlice = createSlice({
	name: 'gifting',
	initialState: initialGiftingState,
	reducers: {
		SET_ACTIVE_GIFT(state, action) {
			return {
				...state,
				activeGift: action.payload.gift,
			};
		},
		SET_GIFT_TYPE(state, action) {
			return {
				...state,
				giftType: action.payload.giftType,
			};
		},
		SET_CREATED_GIFT_CARD_DETAILS(state, action) {
			return {
				...state,
				createdGiftCard: action.payload.giftCard,
			};
		},
		SET_GIFT_OTHER_VISIBILITY(state, action) {
			return {
				...state,
				giftOtherAmountVisibility: action.payload.visible,
			};
		},
		SET_GIFT_AMOUNT(state, action) {
			return {
				...state,
				createdGiftCardAmount: action.payload.amount,
			};
		},
		SET_GIFT_PAYMENT_VISIBILITY(state, action) {
			return {
				...state,
				isGiftPaymentVisible: action.payload.visible,
			};
		},
		SET_GIFT_PAYMENT_CARD_INPUT_VISIBILITY(state, action) {
			return {
				...state,
				isGiftPaymentCardInputVisible: action.payload.visible,
			};
		},
		SET_GIFT_PAYMENT_PROCESSING(state, action) {
			return {
				...state,
				isPaymentProcessing: Boolean(action.payload.processing),
			};
		},
		SET_GIFT_CREATE_TERMS_CONDITIONS_VISIBILITY(state, action) {
			return {
				...state,
				isGiftCreateTermsVisible: action.payload.visible,
			};
		},
	},
});

export const {
	SET_ACTIVE_GIFT,
	SET_GIFT_TYPE,
	SET_CREATED_GIFT_CARD_DETAILS,
	SET_GIFT_OTHER_VISIBILITY,
	SET_GIFT_AMOUNT,
	SET_GIFT_PAYMENT_VISIBILITY,
	SET_GIFT_PAYMENT_CARD_INPUT_VISIBILITY,
	SET_GIFT_PAYMENT_PROCESSING,
	SET_GIFT_CREATE_TERMS_CONDITIONS_VISIBILITY,
} = giftingSlice.actions;

/** Set the current active gift */
export const setActiveGift = (gift: IGift | null) => async (
	dispatch: Dispatch,
) => dispatch(SET_ACTIVE_GIFT({ gift }));

/** Set the current active gift */
export const setGiftType = (giftType: TGetGiftType) => async (
	dispatch: Dispatch,
) => dispatch(SET_GIFT_TYPE({ giftType }));

/** Set created gift card */
export const setCreatedGiftCard = (giftCard: ICreatedGiftCard) => async (
	dispatch: Dispatch,
) => dispatch(SET_CREATED_GIFT_CARD_DETAILS({ giftCard }));

/** Set Gift Card other visibility */
export const setGiftOtherVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => dispatch(SET_GIFT_OTHER_VISIBILITY({ visible }));

/** Set gift card amount */
export const setGiftAmount = (amount: number) => async (dispatch: Dispatch) =>
	dispatch(SET_GIFT_AMOUNT({ amount }));

/** reset created gift card details */
export const resetCreatedGiftCard = () => async (dispatch: Dispatch) =>
	dispatch(
		SET_CREATED_GIFT_CARD_DETAILS({
			giftCard: initialGiftingState.createdGiftCard,
		}),
	);

/** Set gift card payment visibility */
export const setGiftPaymentVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => dispatch(SET_GIFT_PAYMENT_VISIBILITY({ visible }));

/** Set gift payment card visibility */
export const setGiftPaymentCardInputVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => dispatch(SET_GIFT_PAYMENT_CARD_INPUT_VISIBILITY({ visible }));

/** Sets the gift payment processing status */
export const setGiftPaymentProcessing = (processing: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_GIFT_PAYMENT_PROCESSING({ processing }));
};

/** Sets the gift create T&Cs visibility */
export const setGiftCreateTermsVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_GIFT_CREATE_TERMS_CONDITIONS_VISIBILITY({ visible }));
};

export default giftingSlice.reducer;
