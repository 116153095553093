import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IOrderHistoryApiState } from './order-history.types';

export const initialOrderHistoryApiState: IOrderHistoryApiState = {
	eventsInProgress: 0,
};

const orderHistoryApiSlice = createSlice({
	name: 'orderHistoryApi',
	initialState: initialOrderHistoryApiState,
	reducers: {
		GET_ORDER_HISTORY(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDER_HISTORY_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				orderHistory: action.payload.data,
			};
		},
		GET_ORDER_HISTORY_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_DETAILS(state, action) {
			return { ...state, eventsInProgress: state.eventsInProgress + 1 };
		},
		GET_ORDER_DETAILS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				orderDetails: action.payload.data,
			};
		},
		GET_ORDER_DETAILS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CLEAR_ORDER_DETAILS(state) {
			return {
				...state,
				orderDetails: undefined,
				orderVenue: undefined,
			};
		},
	},
});

export const {
	GET_ORDER_HISTORY,
	GET_ORDER_HISTORY_SUCCESS,
	GET_ORDER_HISTORY_FAIL,
	GET_ORDER_DETAILS,
	GET_ORDER_DETAILS_SUCCESS,
	GET_ORDER_DETAILS_FAIL,
	CLEAR_ORDER_DETAILS,
} = orderHistoryApiSlice.actions;

/**
 * Gets the users order history
 */
export const getOrderHistory = (page = 0, pageSize = 20) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_ORDER_HISTORY({
			request: {
				method: 'get',
				// "+ 1" fixes off by one error, as the endpoint starts at page 1, not zero.
				url: `1/visit?pageNumber=${page + 1}&pageSize=${pageSize}`,
			},
		}),
	);
	return response.payload?.status === 200 ? response.payload?.data : false;
};

/**
 * Gets the details for a specific order in the users order history
 */
export const getOrderDetails = (id: string) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_ORDER_DETAILS({
			request: {
				method: 'get',
				url: `2/order/${id}`,
			},
		}),
	);
	return response.payload?.status === 200 ? response.payload?.data : false;
};

/** Clears the current order details */
export const clearOrderDetails = () => async (dispatch: Dispatch) =>
	dispatch(CLEAR_ORDER_DETAILS());

export default orderHistoryApiSlice.reducer;
