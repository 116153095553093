import { useEffect, useRef } from 'react';

/** Logs network connection details to sentry */
export default function useNetworkDiagnoistics() {
	const connectionRef = useRef(
		navigator.connection ||
			// @ts-ignore
			navigator.mozConnection ||
			// @ts-ignore
			navigator.webkitConnection,
	);

	useEffect(() => {
		let previousType = '';
		const handleConnectionChange = () => {
			const messages: Map<string, string> = new Map();
			const connection = connectionRef.current;

			if (!connection) return;

			const type = connection.type || connection.effectiveType;
			if (type) {
				messages.set('Connection', type);
				if (previousType) {
					messages.set('Previous connection', previousType);
				}
				previousType = type.toString();
			}

			if (connection.downlink !== undefined && connection.downlink !== null) {
				messages.set('Network speed', `${connection.downlink} Mbps`);
			}

			if (
				connection.downlinkMax !== undefined &&
				connection.downlinkMax !== null
			) {
				messages.set(
					'Estimated max network speed',
					`${connection.downlinkMax} Mbps`,
				);
			}

			// eslint-disable-next-line
			console.log({
				name: 'Network connection changed',
				message: JSON.stringify(Object.fromEntries(messages), null, 2),
			});
		};

		handleConnectionChange();

		connectionRef.current?.addEventListener('change', handleConnectionChange);

		// Removes the event listener
		return () => {
			connectionRef.current?.removeEventListener(
				'change',
				handleConnectionChange,
			);
		};
	}, [connectionRef]);
}
