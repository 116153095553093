import currenciesPrecision from './currencies-precision.data';
import { getUserlocales } from './generic-helpers.helper';

export type TCurrencyCode = keyof typeof currenciesPrecision;

/**
 * Type guard for TCurrencyCode
 */
export const isValidCurrencyCode = (code: string): code is TCurrencyCode => {
	return code in currenciesPrecision;
};

interface IArgs {
	currencyCode: TCurrencyCode;
	units: number;
	fromPence?: boolean;
	showSymbol?: boolean;
	minimumDigits?: number;
}

/** Formats a currency using the users locale and the currency code */
export const formatCurrency = ({
	currencyCode,
	units,
	fromPence = true,
	showSymbol = true,
	minimumDigits,
}: IArgs): string => {
	// Get the locale from the browser
	const locales = process.env.REACT_APP_ENABLE_CURRENCY_I18N
		? getUserlocales()
		: ['en-GB'];

	// Gets the best supported locale for formatting the number which is also supported by Intl
	const locale = Intl.NumberFormat.supportedLocalesOf(locales, {
		localeMatcher: 'lookup',
	})[0];

	const precision =
		currenciesPrecision[currencyCode] !== undefined
			? currenciesPrecision[currencyCode]
			: 2; // Default to 2 decimal places, as that's what most currencies use

	const options = showSymbol
		? {
			style: 'currency',
			currency: currencyCode,
			maximumFractionDigits: precision,
			minimumFractionDigits:
					minimumDigits !== undefined ? minimumDigits : precision,
		  }
		: {
			maximumFractionDigits: precision,
			minimumFractionDigits:
					minimumDigits !== undefined ? minimumDigits : precision,
		  };

	// Create the formatter
	const formatter = new Intl.NumberFormat(locale, options);

	// If the 'units' parameter is already the correct amount
	if (!fromPence || precision === 0) {
		return formatter.format(units);
	}

	// Convert units (e.g. pence or cents) into whole units (e.g. pounds or euros)
	const amount = units / 10 ** precision;

	// Format the amount
	return formatter.format(amount);
};
