import React, { FunctionComponent } from 'react';

import {
	Wrapper,
	StyledSearchBar,
	StyledContainer,
	GPSButton,
} from './search.styles';

import animations from 'helpers/animations.helper';
import VenueListSearchComponent from 'modules/venue/venue-list/venue-list-search/venue-list-search.component';

interface IComponentProps {
	getGeolocation: () => void;
}

const Search: FunctionComponent<IComponentProps> = ({ getGeolocation }) => {
	return (
		<Wrapper>
			<StyledSearchBar {...animations.slideInDown}>
				<StyledContainer>
					<VenueListSearchComponent
						fieldVisible={true}
						setVisibility={() => {}}
						buttonPosition="left"
					/>
					<GPSButton
						variant="icon"
						icon="map"
						onClick={() => getGeolocation()}
						iconWidth={15}
						iconHeight={18}
						{...animations.slideInRight}
					/>
				</StyledContainer>
			</StyledSearchBar>
		</Wrapper>
	);
};

export default Search;
