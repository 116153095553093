import { motion } from 'framer-motion';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { forceCheck } from 'react-lazyload';
import { useSelector } from 'react-redux';

import Search from '../search/search.component';

import animations from 'helpers/animations.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { RootState } from 'modules/core/state/root.reducer';
import VenueList from 'modules/venue/venue-list/venue-list/venue-list.component';
import { getVenues } from 'modules/venue/venue.slice';
import { IVenue } from 'modules/venue/venue.types';

interface IComponentProps {
	getGeolocation: () => void;
}

const List: FunctionComponent<IComponentProps> = ({ getGeolocation }) => {
	const dispatch = useReduxDispatch();

	// Local state for parsed menus array
	const [pageNumber, setPageNumber] = useState<number>(0);
	const [venueItems, setVenueItems] = useState<IVenue[]>([]);

	// Get venue pagination from state
	const { pagination } = useSelector((state: RootState) => state.venue);

	// Get coords from state
	const authState = useSelector((state: RootState) => state.auth);
	const userCoords = authState.user.coords;

	// Get active brand from brand state
	const { activeBrand } = useSelector((state: RootState) => state.brand);

	/** Request venues list data */
	const getData = async (resetList?: boolean) => {
		const hasCoords = userCoords?.latitude && userCoords?.longitude;

		// Get venues at coords/alphabetical for current page
		const requestedVenues = await dispatch(
			getVenues({
				lat: userCoords?.latitude || undefined,
				long: userCoords?.longitude || undefined,
				pageNumber: resetList ? 0 : pageNumber || 0,
				pageSize: pagination.pageSize,
				brandId: activeBrand?.id,
				sortBy: hasCoords ? 'distance' : 'name',
				sortOrder: 'ASC',
			}),
		);

		// If we get venues, add them to venueItems
		requestedVenues && resetList
			? setVenueItems(requestedVenues)
			: setVenueItems([...venueItems, ...(requestedVenues || [])]);

		// lazy load force re-check visible elements
		forceCheck();
	};

	/** Handle page change event */
	const handlePagination = () => {
		// If we have > 1 page of content and the current page isn't the last one
		pagination.pageCount > 1 &&
			(pageNumber < pagination.pageCount || !pageNumber) &&
			// Increment page number
			setPageNumber(pageNumber + 1);
	};

	// Gets venues when coords change
	useEffect(() => {
		getData(true);
	}, [dispatch, userCoords]);

	// Gets venues when pagenumber changes
	useEffect(() => {
		getData();
	}, [dispatch, pageNumber]);

	return (
		<motion.div {...animations.fadeIn}>
			<Search getGeolocation={getGeolocation} />
			<VenueList venueItems={venueItems} handlePagination={handlePagination} />
		</motion.div>
	);
};

export default List;
