import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { rgba } from 'polished';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setUserCoords } from 'modules/auth/auth.slice';

// This is a variable outside of the component tree so that the libraries are only loaded once
// LoadScripts does an array equality check (arr == arr), not a diff check to determine if scripts should be loaded or not
const librariesToLoad: Libraries = ['places'];
const StyledInputWrapper = styled.div`
	position: relative;

	input {
		width: 100%;
		line-height: 36px;
		padding: 5px 5px 5px 38px;
		background: ${brand.white};
		border: 1px solid ${brand.borders};
		border-radius: 10px;
		font-size: ${fonts.sizes.standard};

		&:focus {
			border-color: ${({ theme }) => theme.colors.link};
			outline: none;
		}

		&.has-error {
			background: ${rgba(brand.validationError, 0.05)};
			border-color: ${brand.validationError};
		}

		::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			color: ${brand.text};
		}
		::-moz-placeholder {
			/* Firefox 19+ */
			color: ${brand.text};
		}
		:-ms-input-placeholder {
			/* IE 10+ */
			color: ${brand.text};
		}
		:-moz-placeholder {
			/* Firefox 18- */
			color: ${brand.text};
		}
	}

	.icon-search {
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
`;

const StyledAutocomplete = styled(Autocomplete)`
	width: 100%;
`;

// Inerface for component props
interface IComponentProps {
	setMapCenter?: Function;
	setVisibility?: Function;
	mode: 'search' | 'location';
	inputPlaceholder: string;
	className?: string;
	searchIconColour?: keyof typeof brand;
}

/** Google maps autocomplete */
const MapAutoComplete: FunctionComponent<IComponentProps> = ({
	setMapCenter,
	setVisibility,
	mode,
	inputPlaceholder,
	className,
	searchIconColour = 'text',
}) => {
	// Get hooks
	const dispatch = useReduxDispatch();

	// Local state for autocomplete object
	const [autoComplete, setAutoComplete] = useState<
		google.maps.places.Autocomplete
	>();

	/** Handle place changing from autocomplete */
	const handlePlaceChange = async () => {
		if (autoComplete) {
			// Get place from places API
			const place = autoComplete.getPlace();
			// Get coords from place
			const latitude = place.geometry?.location?.lat();
			const longitude = place.geometry?.location?.lng();

			// If we have coords
			if (!!latitude && !!longitude) {
				// Create coords object
				const coords = { latitude, longitude };

				// Set center of map
				!!setMapCenter && setMapCenter(coords);

				if (mode === 'search') {
					// Set the coords as user's location
					await dispatch(setUserCoords(coords));
					// Hide the search component
					!!setVisibility && setVisibility(false);
				}
			}
		}
	};

	return (
		<LoadScript
			googleMapsApiKey={process.env.REACT_APP_GMAPS_KEY!}
			libraries={librariesToLoad}
			loadingElement={null}
		>
			<StyledAutocomplete
				onLoad={(ref) => setAutoComplete(ref)}
				onPlaceChanged={handlePlaceChange}
				options={{
					fields: ['geometry.location'],
					types: ['establishment', 'geocode'],
					componentRestrictions: { country: ['GB', 'GG', 'JE'] },
				}}
			>
				<StyledInputWrapper className={className}>
					<input
						data-testid="location-search"
						type="text"
						placeholder={inputPlaceholder}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={true}
					/>
					<Icon
						name="search"
						className="icon-search"
						width={20}
						height={20}
						colour={searchIconColour}
					/>
				</StyledInputWrapper>
			</StyledAutocomplete>
		</LoadScript>
	);
};

export default MapAutoComplete;
