import { pascalCase, isSomeEnumValue } from 'helpers/generic-helpers.helper';
import { ReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setActiveCheckInPage, setCheckInVisibility, setActiveTableNumber } from 'modules/basket/basket.slice';
import GenericError from 'modules/core/error/generic-error.class';
import { intl } from 'modules/core/i18n/i18n.config';
import { setPayGoTableNumber } from 'modules/pay-go/pay-go.slice';
import { setTabPickupTableNumber } from 'modules/tab-pickup/tab-pickup.slice';
import { getVenueTimeslots } from 'modules/venue/venue.slice';
import {
	IVenueServices,
	ETableServiceFeature,
} from 'modules/venue/venue.types';

/** Format string as keyof table service feature enum */
export const formatSelectedTableServiceFeature = (
	tableServiceFeature?: string,
): keyof typeof ETableServiceFeature | undefined => {
	if (!tableServiceFeature) return undefined;

	// Unique handler for order-now-pay-now
	if (tableServiceFeature === 'order-now-pay-now') {
		return 'OrderAndPay';
	}

	// Format feature name as pascalCase
	const formattedFeature = pascalCase(tableServiceFeature);

	// Typegard to check requested feature is supported
	if (!isSomeEnumValue(formattedFeature, ETableServiceFeature)) {
		return undefined;
	}

	return formattedFeature as keyof typeof ETableServiceFeature;
};

/**
 * Get availability for service types at the venue
 * and check if the user's selected type is available
 */
export const checkServiceTypeAvailability = async (
	dispatch: ReduxDispatch,
	venueId: string,
	services: IVenueServices,
	serviceType?: string,
) => {
	// Check availability of click + collect and table service
	const hasClickAndCollect: boolean = !!services.clickAndCollect?.isActive;
	const hasTableService: boolean = !!services.tableService?.isActive;

	if (serviceType === 'click-and-collect') {
		if (!hasClickAndCollect) {
			throw new GenericError({
				error: {
					name: 'venueLinkError - checkTableServiceFeatureEnabled',
					message: 'Click and collect not enabled',
				},
				dialog: {
					title: intl.formatMessage({
						id: 'errors.general.title',
					}),
					text: intl.formatMessage({
						id: 'venue.servicetype.unavailable.dialog.text',
					}),
				},
			});
		}

		// Get venue timeslots
		const { timeslots } = await dispatch(
			getVenueTimeslots(serviceType, venueId),
		);

		if (!timeslots || timeslots.length === 0) {
			throw new GenericError({
				error: {
					name: 'venueLinkError - checkTableServiceFeatureEnabled',
					message: 'No timeslots available',
				},
				dialog: {
					title: intl.formatMessage({
						id: 'errors.general.title',
					}),
					text: intl.formatMessage({
						id: 'errors.venueLink.tableServiceFeature',
					}),
				},
			});
		}

		return;
	}
	if (serviceType === 'table-service') {
		if (hasTableService) return;

		throw new GenericError({
			error: {
				name: 'venueLinkError - checkServiceTypeAvailability',
				message: 'Table service not enabled',
			},
			dialog: {
				title: intl.formatMessage({
					id: 'errors.general.title',
				}),
				text: intl.formatMessage({
					id: 'venue.servicetype.unavailable.dialog.text',
				}),
			},
		});
	}

	throw new GenericError({
		error: {
			name: 'venueLinkError - checkServiceTypeAvailability',
			message: `Service type (${serviceType}) not supported`,
		},
		dialog: {
			title: intl.formatMessage({
				id: 'errors.general.title',
			}),
			text: intl.formatMessage({
				id: 'errors.venueLink.tableServiceFeature',
			}),
		},
	});
};

/**
 * Sets requested table service feature if available
 * OR if none is provided:
 * - IF none are available, will throw
 * - IF only 1 is available, that will be set
 * - IF multiple are available, will return undefined
 */
export const setTableServiceFeature = (
	services: IVenueServices,
	selectedTableServiceFeature?: keyof typeof ETableServiceFeature,
): keyof typeof ETableServiceFeature | undefined => {
	// Get enabled table service features (payment options)
	const tableServiceFeatures = services.tableService!.tableServiceConfig
		?.tableServiceFeatures;

	// If there are no features available
	if (!tableServiceFeatures || tableServiceFeatures.length === 0) {
		throw new GenericError({
			error: {
				name: 'venueLinkError - checkTableServiceFeatureEnabled',
				message: 'No table service features enabled',
			},
			dialog: {
				title: intl.formatMessage({
					id: 'errors.general.title',
				}),
				text: intl.formatMessage({
					id: 'errors.venueLink.tableServiceFeature',
				}),
			},
		});
	}

	// If user has selected a feature
	if (selectedTableServiceFeature) {
		const isSelectedFeatureEnabled = tableServiceFeatures?.includes(
			selectedTableServiceFeature,
		);
		// If requested feature is not available
		if (!isSelectedFeatureEnabled) {
			throw new GenericError({
				error: {
					name: 'venueLinkError - checkTableServiceFeatureEnabled',
					message: `tableServiceFeature(${selectedTableServiceFeature}) not enabled: ${JSON.stringify(
						tableServiceFeatures,
					)}`,
				},
				dialog: {
					title: intl.formatMessage({
						id: 'errors.general.title',
					}),
					text: intl.formatMessage({
						id: 'errors.venueLink.tableServiceFeature',
					}),
				},
			});
		}

		return selectedTableServiceFeature;
	}
	// If only 1 feature is enabled, set it
	if (tableServiceFeatures.length === 1) {
		return tableServiceFeatures[0];
	}
	return undefined;
};

/** Set table number, or allow user to select it/check in */
export const setTableNumber = (
	dispatch: ReduxDispatch,
	tableNumber?: string,
	paymentOption?: keyof typeof ETableServiceFeature,
) => {
	if (tableNumber) {
		if (paymentOption === 'PayAndGo') {
			dispatch(setPayGoTableNumber(tableNumber));
			return;
		}
		if (paymentOption === 'TabPickup') {
			dispatch(setTabPickupTableNumber(tableNumber));
			return;
		}
		if (paymentOption === 'OrderNowPayLater') {
			dispatch(setActiveCheckInPage('guestCount'));
			dispatch(setCheckInVisibility(true));
		}

		dispatch(setActiveTableNumber(tableNumber));
		return;
	}
	if (paymentOption === 'OrderNowPayLater') {
		dispatch(setActiveCheckInPage('tableNumber'));
		dispatch(setCheckInVisibility(true));
	}
};
