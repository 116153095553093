import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';

export const StyledList = styled.ul`
	margin: 0;
	padding: 20px 15px;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	background: ${brand.neutralMid};

	${mq.tabletUp`padding: 20px;`}
`;

export const StyledBlankLi = styled.li`
	margin: 0 0 15px;
	width: 100%;
`;
