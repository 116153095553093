import { HTMLMotionProps, motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import structure from 'assets/styles/variables/structure';

const StyledContainer = styled(motion.div)`
	width: ${structure.container.mobileWidth};
	max-width: ${structure.container.maxWidth};
	padding: 0 ${structure.container.mobilePadding};
`;

interface IComponentProps extends HTMLMotionProps<'div'> {
	id?: string;
	className?: string;
}

/** Renders container component */
const Container: FunctionComponent<IComponentProps> = ({
	children,
	...props
}) => <StyledContainer {...props}>{children}</StyledContainer>;

export default Container;
