import { createSlice, Dispatch } from '@reduxjs/toolkit';

import {
	ICreatedGiftCard,
	IGiftingApiState,
	TGetGiftType,
} from '../gifting.types';

export const initialGiftingApiState: IGiftingApiState = {
	eventsInProgress: 0,
};

const giftingApiSlice = createSlice({
	name: 'giftingApi',
	initialState: initialGiftingApiState,
	reducers: {
		GET_RECEIVED_GIFTS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_RECEIVED_GIFTS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				receivedGifts: action.payload.data,
			};
		},
		GET_RECEIVED_GIFTS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PURCHASED_GIFTS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PURCHASED_GIFTS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				purchasedGifts: action.payload.data,
			};
		},
		GET_PURCHASED_GIFTS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ARCHIVE_GIFTS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ARCHIVE_GIFTS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				archiveGifts: action.payload.data,
			};
		},
		GET_ARCHIVE_GIFTS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_GIFT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_GIFT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_GIFT_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_DEFAULT_GIFT_SETTINGS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_DEFAULT_GIFT_SETTINGS_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				defaultGiftSettings: action.payload.data.gifting,
			};
		},
		GET_DEFAULT_GIFT_SETTINGS_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_A_GIFT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_A_GIFT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				createdGiftCardId: action.payload.data.id,
			};
		},
		CREATE_A_GIFT_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_GIFT_PAYMENT_REQUEST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_GIFT_PAYMENT_REQUEST_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_GIFT_PAYMENT_REQUEST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		POST_PAYMENT_TO_GIFT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		POST_PAYMENT_TO_GIFT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				successfullyPurchasedGift: action.payload.data,
			};
		},
		POST_PAYMENT_TO_GIFT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_PURCHASED_GIFT(state) {
			return {
				...state,
				successfullyPurchasedGift: undefined,
			};
		},
	},
});

export const {
	GET_RECEIVED_GIFTS,
	GET_RECEIVED_GIFTS_FAIL,
	GET_RECEIVED_GIFTS_SUCCESS,
	GET_PURCHASED_GIFTS,
	GET_PURCHASED_GIFTS_FAIL,
	GET_PURCHASED_GIFTS_SUCCESS,
	GET_ARCHIVE_GIFTS,
	GET_ARCHIVE_GIFTS_FAIL,
	GET_ARCHIVE_GIFTS_SUCCESS,
	DELETE_GIFT,
	DELETE_GIFT_SUCCESS,
	DELETE_GIFT_FAIL,
	GET_DEFAULT_GIFT_SETTINGS,
	GET_DEFAULT_GIFT_SETTINGS_FAIL,
	GET_DEFAULT_GIFT_SETTINGS_SUCCESS,
	CREATE_A_GIFT,
	CREATE_A_GIFT_FAIL,
	CREATE_A_GIFT_SUCCESS,
	CREATE_GIFT_PAYMENT_REQUEST,
	CREATE_GIFT_PAYMENT_REQUEST_FAIL,
	CREATE_GIFT_PAYMENT_REQUEST_SUCCESS,
	POST_PAYMENT_TO_GIFT,
	POST_PAYMENT_TO_GIFT_FAIL,
	POST_PAYMENT_TO_GIFT_SUCCESS,
	RESET_PURCHASED_GIFT,
} = giftingApiSlice.actions;

/**
 * get gifts sent to a user
 * @param giftType type of user gift
 */
export const getUserGifts = (giftType: TGetGiftType) => async (
	dispatch: Dispatch,
) => {
	let response;
	if (giftType === 'received') {
		response = await dispatch(
			GET_RECEIVED_GIFTS({
				request: {
					method: 'get',
					url: '/1/gift?usable=true&sent=false',
				},
			}),
		);
	} else if (giftType === 'purchased') {
		response = await dispatch(
			GET_PURCHASED_GIFTS({
				request: {
					method: 'get',
					url: '/1/gift?sent=true',
				},
			}),
		);
	} else {
		response = await dispatch(
			GET_ARCHIVE_GIFTS({
				request: {
					method: 'get',
					url: '/1/gift?usable=false',
				},
			}),
		);
	}

	return response.payload?.status === 200 ? response.payload?.data : false;
};

/**
 * delete gift in user wallet
 * @param giftId id of gift
 */
export const deleteGift = (giftId: string) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		DELETE_GIFT({
			request: {
				method: 'delete',
				url: `/1/gift/${giftId}`,
			},
		}),
	);

	return response.payload?.status === 200;
};

export const getDefaultGiftSettings = () => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_DEFAULT_GIFT_SETTINGS({
			request: {
				method: 'get',
				url: '/1/brands/gifting/default',
			},
		}),
	);

	return response.payload?.status === 200;
};

/**
 * Create a gift
 * @param gift gift object
 * @returns
 */
export const createAGift = (gift: ICreatedGiftCard) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		CREATE_A_GIFT({
			request: {
				method: 'post',
				url: '/1/gift',
				data: gift,
			},
		}),
	);

	return response.payload?.data;
};

/** Create a gift payment request */
export const createGiftPaymentRequest = (
	giftId: string,
	paymentMethodId: string,
) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		CREATE_GIFT_PAYMENT_REQUEST({
			request: {
				method: 'post',
				url: `/1/payment/payment-request/gift/${giftId}`,
				data: {
					paymentMethodId,
				},
				timeout: process.env.REACT_APP_POS_TIMEOUT,
			},
		}),
	);

	return response.payload?.data;
};

/**
 * attribute payment identifier to a gift
 * @param giftId
 * @returns
 */
export const setPaymentOnGift = (
	giftId: string,
	paymentToken: string,
) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		POST_PAYMENT_TO_GIFT({
			request: {
				method: 'put',
				url: `/1/gift/${giftId}/payment`,
				data: { paymentToken },
			},
		}),
	);

	return response.payload?.data;
};

/** Resets the successfully purchased gift in the state */
export const resetSuccessfullyPurchasedGift = () => async (
	dispatch: Dispatch,
) => dispatch(RESET_PURCHASED_GIFT());

export default giftingApiSlice.reducer;
