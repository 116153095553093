import { IntlShape } from 'react-intl';

import { resetBasketState, setBasketVenue } from '../basket.slice';

import { ReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { resetCheckoutState } from 'modules/checkout/checkout.slice';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { resetOrderState } from 'modules/order/order.slice';
import { resetPayGoState } from 'modules/pay-go/pay-go.slice';
import { resetPaymentState } from 'modules/payment/payment.slice';
import { resetTabPickupState } from 'modules/tab-pickup/tab-pickup.slice';
import { IVenue } from 'modules/venue/venue.types';

export const wipeCurrentBasket = async (
	dispatch: ReduxDispatch,
	venueDetails: IVenue,
) => {
	// Clear basket, order, payment and checkout states
	await dispatch(resetBasketState());
	await dispatch(resetPaymentState());
	await dispatch(resetOrderState());
	await dispatch(resetCheckoutState());
	await dispatch(resetPayGoState());
	await dispatch(resetTabPickupState());

	// Set active basket venue
	await dispatch(setBasketVenue(venueDetails));
};

/** Confirm if user wishes to start a new basket session */
const confirmBasketWipe = async (
	intl: IntlShape,
	dispatch: ReduxDispatch,
	venueDetails: IVenue,
	basketVenue?: IVenue,
): Promise<boolean> => {
	// Determine text for dialog
	const dialogText =
		!basketVenue || venueDetails.name === basketVenue?.name
			? intl.formatMessage(
				{ id: 'basket.replaceOrder.sameVenue.text' },
				{ basketVenue: venueDetails.name },
			  )
			: intl.formatMessage(
				{ id: 'basket.replaceOrder.text' },
				{
					activeVenue: venueDetails.name,
					basketVenue: basketVenue?.name,
				},
			  );

	// Show confirmation dialog
	const confirmResetBasket = await fireDialog({
		title: intl.formatMessage({
			id: 'basket.replaceOrder.title',
		}),
		text: dialogText,
		confirmButtonText: intl.formatMessage({
			id: 'basket.replaceOrder.confirmButtonText',
		}),
		showCancelButton: true,
	});

	// User wishes to create a new order
	if (confirmResetBasket.isConfirmed) {
		await wipeCurrentBasket(dispatch, venueDetails);

		return true;
	}
	return false;
};

export default confirmBasketWipe;
