import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IFeedback, IFeedbackState } from './feedback.types';

// Create initial state
export const initialFeedbackState: IFeedbackState = {
	eventsInProgress: 0,
	isFeedbackDetailVisible: false,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
};

const feedbackSlice = createSlice({
	name: 'feedback',
	initialState: initialFeedbackState,
	reducers: {
		RESET_FEEDBACK_STATE() {
			return { ...initialFeedbackState };
		},
		CLEAR_ACTIVE_FEEDBACK(state) {
			return { ...state, activeFeedback: undefined };
		},
		SET_FEEDBACK_DETAIL_VISIBILITY(state, action) {
			return {
				...state,
				isFeedbackDetailVisible: Boolean(action.payload),
			};
		},
		CREATE_FEEDBACK(state, action) {
			return {
				...state,
				activeFeedback: action.payload.request.data,
			};
		},
		CREATE_FEEDBACK_SUCCESS(state) {
			return {
				...state,
			};
		},
		CREATE_FEEDBACK_FAIL(state) {
			return {
				...state,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_FEEDBACK_STATE,
	CLEAR_ACTIVE_FEEDBACK,
	SET_FEEDBACK_DETAIL_VISIBILITY,
	CREATE_FEEDBACK,
	CREATE_FEEDBACK_SUCCESS,
	CREATE_FEEDBACK_FAIL,
} = feedbackSlice.actions;

/** Reset state */
export const resetFeedbackState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_FEEDBACK_STATE());
};

/** Clear active feedback */
export const clearActiveFeedback = () => async (dispatch: Dispatch) => {
	await dispatch(CLEAR_ACTIVE_FEEDBACK());
};

/** Show/hide feedback detail bottom sheet */
export const setFeedbackDetailVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_FEEDBACK_DETAIL_VISIBILITY(visible));
};

/** Create/update feedback for an order */
export const createFeedback = (orderId: string, feedback: IFeedback) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		CREATE_FEEDBACK({
			request: {
				method: 'post',
				url: `1/order/${orderId}/feedback`,
				data: feedback,
			},
		}),
	);
	return response?.payload;
};

export default feedbackSlice.reducer;
