import customEvent, { ECustomEvent } from '../events/custom.event';

import { RootState } from 'modules/core/state/root.reducer';

export function isPayAndGo(state: RootState) {
	return (
		state.basket.fullBasket?.serviceType === 'tableService' &&
		!!state.payGo.activeSession
	);
}

export function isSplitUsed(state: RootState) {
	return (
		state.splitBill.splitBillMethod != null &&
		Number(state.checkout.activeOrder?.splitBillAmount) > 0
	);
}

export function trackSplitMethod(state: RootState) {
	const method = state.splitBill.splitBillMethod;

	if (method) {
		customEvent(ECustomEvent.splitBillMethod, true, {
			method,
		});
	}
}
