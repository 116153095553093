import {
	IEcommerceCart,
	IEcommerceItemList,
	IEcommercePurchaseDetails,
	TEcommerceItem,
} from '../analytics.types';
import { pushEvent } from '../data-layer';

export function viewItemEvent(item: TEcommerceItem): void {
	// Clear the previous ecommerce object.
	pushEvent({ ecommerce: null });
	pushEvent({
		event: 'view_item',
		ecommerce: {
			items: [item],
		},
	});
}

export function viewItemList(itemList: IEcommerceItemList): void {
	// Clear the previous ecommerce object.
	pushEvent({ ecommerce: null });
	pushEvent({
		event: 'view_item_list',
		ecommerce: itemList,
	});
}

export function selectItem(item: TEcommerceItem): void {
	// Clear the previous ecommerce object.
	pushEvent({ ecommerce: null });
	pushEvent({
		event: 'select_item',
		ecommerce: {
			items: [item],
		},
	});
}

export function addToCart(item: TEcommerceItem): void {
	pushEvent({ ecommerce: null }); // Clear the previous ecommerce object.
	pushEvent({
		event: 'add_to_cart',
		ecommerce: {
			items: [item],
		},
	});
}

export function removeFromCart(item: TEcommerceItem): void {
	pushEvent({ ecommerce: null }); // Clear the previous ecommerce object.
	pushEvent({
		event: 'remove_from_cart',
		ecommerce: {
			items: [item],
		},
	});
}

export function viewCart(cart: IEcommerceCart): void {
	pushEvent({ ecommerce: null }); // Clear the previous ecommerce object.
	pushEvent({
		event: 'view_cart',
		ecommerce: cart,
	});
}

export function search(term: string): void {
	pushEvent({
		event: 'search',
		search_term: term,
	});
}

export function purchase(purchaseDetails: IEcommercePurchaseDetails): void {
	pushEvent({ ecommerce: null });
	pushEvent({
		event: 'purchase',
		ecommerce: purchaseDetails,
	});
}
