import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IGetProductParams, IProductApiState } from '../product.types';

export const initialProductApiState: IProductApiState = {
	eventsInProgress: 0,
};

const productApiSlice = createSlice({
	name: 'productApi',
	initialState: initialProductApiState,
	reducers: {
		GET_PRODUCT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PRODUCT_SUCCESS(state, action) {
			return {
				...state,
				activeProduct: action.payload?.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PRODUCT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_PRODUCT_API_STATE() {
			return { ...initialProductApiState };
		},
	},
});

export const {
	GET_PRODUCT,
	GET_PRODUCT_FAIL,
	GET_PRODUCT_SUCCESS,
	RESET_PRODUCT_API_STATE,
} = productApiSlice.actions;

/** Get product */
export const getProduct = ({
	venueId,
	menuId,
	productId,
}: IGetProductParams) => async (dispatch: Dispatch) => {
	// Create request
	await dispatch(
		GET_PRODUCT({
			request: {
				method: 'get',
				url: `1/venues/${venueId}/menu/${menuId}/product/${productId}`,
			},
		}),
	);
};

/** Reset state */
export const resetProductApiState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_PRODUCT_API_STATE());
};

export default productApiSlice.reducer;
