import { createSlice, Dispatch } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import {
	ESocialSignInFlow,
	IAuthState,
	ICoordinates,
	IJwt,
	IRegisterGuest,
	IRegisterUser,
	ISocialLoginSuccess,
	IUser,
	TSocials,
} from './auth.types';

import identifyUser from 'modules/core/logging/identify-user.helper';

// Create initial state
export const initialAuthState: IAuthState = {
	accessToken: {
		token: null,
		expiry: null,
	},
	eventsInProgress: 0,
	hasAuth: false,
	refreshToken: {
		token: null,
		expiry: null,
		refreshing: false,
	},
	user: {
		id: '',
	},
	socialSignInFlow: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState: initialAuthState,
	reducers: {
		RESET_AUTH_STATE() {
			return { ...initialAuthState };
		},
		SOCIAL_SIGN_IN(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		SOCIAL_SIGN_IN_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SOCIAL_SIGN_IN_SUCCESS(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_SOCIAL_SIGN_IN(state, action) {
			// Decode access + refresh tokens
			const decodedAccessToken: IJwt = jwt_decode(action.payload?.accessToken);
			const decodedRefreshToken: IJwt = jwt_decode(
				action.payload?.refreshToken,
			);

			return {
				...state,
				hasAuth: true,
				user: {
					...state.user,
					...action.payload?.user,
				},
				accessToken: {
					...state.accessToken,
					expiry: decodedAccessToken.exp,
					token: action.payload?.accessToken,
				},
				refreshToken: {
					...state.refreshToken,
					expiry: decodedRefreshToken.exp,
					token: action.payload?.refreshToken,
				},
			};
		},
		ATTACH_SOCIAL_SIGN_IN(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		ATTACH_SOCIAL_SIGN_IN_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ATTACH_SOCIAL_SIGN_IN_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REGISTER_USER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		REGISTER_USER_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REGISTER_USER_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				user: {
					...state.user,
					...action.payload?.data,
				},
			};
		},
		REGISTER_GUEST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
				user: {
					...state.user,
					email: action.payload.request.data?.email,
					firstName: action.payload.request.data?.firstName,
				},
			};
		},
		REGISTER_GUEST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REGISTER_GUEST_SUCCESS(state, action) {
			// Decode access + refresh tokens
			const accessToken: IJwt = jwt_decode(action.payload?.data?.accessToken);
			const refreshToken: IJwt = jwt_decode(action.payload?.data?.refreshToken);

			return {
				...state,
				hasAuth: true,
				accessToken: {
					...state.accessToken,
					expiry: accessToken.exp,
					token: action.payload?.data?.accessToken,
				},
				refreshToken: {
					...state.refreshToken,
					expiry: refreshToken.exp,
					token: action.payload?.data?.refreshToken,
				},
				user: {
					...state.user,
					id: action?.payload?.data?.id,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_USER_COORDS(state, action) {
			return {
				...state,
				user: {
					...state.user,
					coords: action.payload,
				},
			};
		},
		REFRESH(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
				refreshToken: {
					...state.refreshToken,
					refreshing: true,
				},
			};
		},
		REFRESH_SUCCESS(state, action) {
			// Decode access + refresh tokens
			const accessToken: IJwt = jwt_decode(action.payload?.data?.accessToken);
			const refreshToken: IJwt = jwt_decode(action.payload?.data?.refreshToken);

			return {
				...state,
				hasAuth: true,
				accessToken: {
					...state.accessToken,
					expiry: accessToken.exp,
					token: action.payload?.data?.accessToken,
				},
				refreshToken: {
					...state.refreshToken,
					refreshing: false,
					expiry: refreshToken.exp,
					token: action.payload?.data?.refreshToken,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		REFRESH_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				refreshToken: {
					...state.refreshToken,
					refreshing: false,
				},
			};
		},
		SET_ACCESS_TOKEN(state, action) {
			// Decode access token
			const accessToken: IJwt = jwt_decode(action.payload);

			return {
				...state,
				hasAuth: true,
				accessToken: {
					...state.accessToken,
					expiry: accessToken.exp,
					token: action.payload,
				},
			};
		},
		SET_USER_DETAILS(state, action) {
			return {
				...state,
				user: {
					...state.user,
					...action.payload,
				},
			};
		},
		SET_SOCIAL_SIGNIN_FLOW(state, action) {
			return {
				...state,
				socialSignInFlow: action.payload,
			};
		},
		LOGOUT(state) {
			return initialAuthState;
		},
		GET_PROFILE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_PROFILE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_PROFILE_SUCCESS(state, action) {
			return {
				...state,
				user: {
					...state.user,
					...action.payload.data,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PROFILE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		UPDATE_PROFILE_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		UPDATE_PROFILE_SUCCESS(state, action) {
			return {
				...state,
				user: {
					...state.user,
					...action.payload.data,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_PREVIOUS_URL(state, action) {
			return {
				...state,
				previousUrl: action.payload,
			};
		},
		SET_NEXT_URL(state, action) {
			return {
				...state,
				nextUrl: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_AUTH_STATE,
	SOCIAL_SIGN_IN,
	SOCIAL_SIGN_IN_FAIL,
	SOCIAL_SIGN_IN_SUCCESS,
	SET_SOCIAL_SIGN_IN,
	ATTACH_SOCIAL_SIGN_IN,
	ATTACH_SOCIAL_SIGN_IN_FAIL,
	ATTACH_SOCIAL_SIGN_IN_SUCCESS,
	REGISTER_USER,
	REGISTER_USER_FAIL,
	REGISTER_USER_SUCCESS,
	REGISTER_GUEST,
	REGISTER_GUEST_FAIL,
	REGISTER_GUEST_SUCCESS,
	SET_USER_COORDS,
	REFRESH,
	REFRESH_FAIL,
	REFRESH_SUCCESS,
	SET_ACCESS_TOKEN,
	SET_USER_DETAILS,
	SET_SOCIAL_SIGNIN_FLOW,
	LOGOUT,
	GET_PROFILE,
	GET_PROFILE_FAIL,
	GET_PROFILE_SUCCESS,
	SET_PREVIOUS_URL,
	SET_NEXT_URL,
	UPDATE_PROFILE,
	UPDATE_PROFILE_FAIL,
	UPDATE_PROFILE_SUCCESS,
} = authSlice.actions;

/** Reset state */
export const resetAuthState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_AUTH_STATE());
};

/** Thunk to process logout */
export const processLogout = () => async (dispatch: Dispatch) => {
	// Reset auth state
	await dispatch(LOGOUT());
};

/** social sign in e.g. google/apple/facebook */
export const postSocialSignIn = (
	socialType: TSocials,
	token: string,
	user?: { firstName?: string; lastName?: string; email?: string },
) => async (dispatch: Dispatch): Promise<ISocialLoginSuccess | undefined> => {
	const response = await dispatch(
		SOCIAL_SIGN_IN({
			request: {
				method: 'post',
				url: `2/auth/social/${socialType}`,
				data: {
					token,
					email: user?.email,
					firstName: user?.firstName,
					lastName: user?.lastName,
				},
			},
		}),
	);

	return response.payload?.status === 200 ? response.payload?.data : undefined;
};

/** Set tokens from social sign in */
export const setSocialSignInTokens = (
	socialSignIn: ISocialLoginSuccess,
) => async (dispatch: Dispatch) => {
	await dispatch(SET_SOCIAL_SIGN_IN(socialSignIn));
};

/** Attach token ID to user */
export const attachSocialSignIn = (
	socialType: TSocials,
	token: string,
) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		ATTACH_SOCIAL_SIGN_IN({
			request: {
				method: 'put',
				url: `1/auth/social/${socialType}`,
				data: { token },
			},
		}),
	);

	return response.payload?.status === 200 ? response.payload?.data : false;
};

/** Register user details */
export const registerUser = (id: string, user: IRegisterUser) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		REGISTER_USER({
			request: {
				method: 'post',
				url: '1/me/register',
				data: {
					id,
					...user,
				},
			},
		}),
	);

	return response.payload?.status === 200;
};

/** Register as guest (ghost user) */
export const registerGuest = (user?: IRegisterGuest) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		REGISTER_GUEST({
			request: {
				method: 'post',
				url: '1/auth/login/ghost',
				data: user,
			},
		}),
	);

	// If request was successful
	if (response.payload?.status === 200 && user) {
		identifyUser({
			id: response.payload?.data?.id,
			name: user.firstName,
			email: user.email,
		});
	}

	return response.payload?.status === 200 ? response.payload?.data : false;
};

/** Refresh user's access token */
export const refreshAccessToken = (refreshToken: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		REFRESH({
			request: {
				method: 'post',
				url: '1/auth/refresh',
				data: { refreshToken },
			},
		}),
	);

	return response.payload?.status === 200;
};

/** set user access token */
export const setAccessToken = (accessToken: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACCESS_TOKEN(accessToken));
};

/** set user details */
export const setUserDetails = (details: Partial<IUser>) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_USER_DETAILS(details));
};

/** Set social sign in flow */
export const setSocialSignInFlow = (
	signOnFlow: ESocialSignInFlow | null,
) => async (dispatch: Dispatch) => {
	await dispatch(SET_SOCIAL_SIGNIN_FLOW(signOnFlow));
};

/** Set user coordinates */
export const setUserCoords = (coords: ICoordinates) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_USER_COORDS(coords));
};

/** Get current user's profile */
export const getUserProfile = () => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_PROFILE({
			request: {
				method: 'get',
				url: '1/me',
			},
		}),
	);

	// If request was successful
	if (response.payload?.status === 200 && response.payload.data) {
		const user: IUser = response.payload.data;
		identifyUser({
			id: user.id,
			name: user.firstName || '',
			email: user.email || '',
		});
	}

	return response.payload?.status === 200 ? response.payload?.data : false;
};

/** Updates the users profile */
export const updateUserProfile = (data: Partial<IUser>) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		UPDATE_PROFILE({
			request: {
				method: 'post',
				url: '1/me',
				data,
			},
		}),
	);
	return response.payload?.status === 200;
};

/** set previous url */
export const setPreviousUrl = (previousUrl: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PREVIOUS_URL(previousUrl));
};

/** set next url */
export const setNextUrl = (nextUrl: string) => async (dispatch: Dispatch) => {
	await dispatch(SET_NEXT_URL(nextUrl));
};

export default authSlice.reducer;
