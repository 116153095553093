import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import brand from 'assets/styles/variables/brand';

// The default theme to use for the app:
const theme = {
	colors: {
		link: brand.black,
		linkActive: brand.linkActive,
		bgPrimary: brand.primary,
		lblPrimary: brand.white,
		lblSecondary: brand.black,
		radioChecked: brand.text,
		checkboxChecked: brand.black,
	},
} as const;

const DefaultTheme: FunctionComponent = ({ children }) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DefaultTheme;
