import { AnimatePresence } from 'framer-motion';
import React, { ReactNode, useState } from 'react';

interface IBottomSheetProviderProps {
	children: ReactNode;
}

const sleep = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

export interface IContextProps {
	showSheet: <T extends ISheetReturnValue>(
		component: ReactNode,
		delay?: number,
	) => Promise<T>;
	closeSheet: <T extends ISheetReturnValue>(result: T) => void;
}

const ERROR_WRAP_COMPONENT =
	'Make sure to wrap your component in a BottomSheetProvider!';
export const throwError = (): never => {
	throw new Error(ERROR_WRAP_COMPONENT);
};

interface ISheetReturnValue {
	isConfirmed: boolean;
}

export const BottomSheetContext = React.createContext<IContextProps | undefined>(
	undefined,
);

const BottomSheetProvider = ({ children }: IBottomSheetProviderProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [component, setComponent] = useState<ReactNode | null>(null);
	const [closeSheet, setCloseSheet] = useState<IContextProps['closeSheet']>(
		() => throwError,
	);

	const showSheet = async <T extends ISheetReturnValue>(
		newComponent: ReactNode,
		delay?: number,
	) => {
		await setComponent(newComponent);
		await setIsOpen(true);

		return new Promise<T>((resolve) => {
			const wrappedResolve = async (result: T) => {
				setIsOpen(false);
				setComponent(null);
				// Delay to prevent sheet overlays overlapping
				delay && (await sleep(delay));
				return resolve(result);
			};
			setCloseSheet(() => wrappedResolve);
		});
	};

	const value = { showSheet, closeSheet };
	return (
		<BottomSheetContext.Provider value={value}>
			{children}
			<AnimatePresence>{isOpen && component}</AnimatePresence>
		</BottomSheetContext.Provider>
	);
};

const useBottomSheet = () => {
	const context = React.useContext(BottomSheetContext);
	if (context === undefined) {
		throw new Error('useCount must be used within a BottomSheetProvider');
	}
	return context;
};

export { BottomSheetProvider, useBottomSheet };
