import { captureException, Severity } from '@sentry/react';

import { tracker } from 'modules/core/logging/initialise-logs.helper';

/** Log errors to sentry and track in openreplay */
const errorLogger = (error: Error, level: Severity) => {
	// eslint-disable-next-line no-console
	console.error(new Error(error.name), error);
	// If we have sentry config
	if (process.env.REACT_APP_SENTRY_DSN) {
		// Log error to sentry
		captureException(new Error(error.name), { level, extra: { ...error } });
	}
	// Log error to OpenReplay
	tracker.handleError(error, { name: error.name, message: error.message });
};

export default errorLogger;
