import { motion } from 'framer-motion';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Button from 'components/button/button.component';
import Container from 'components/container/container.component';

export const Wrapper = styled.div`
	padding: 20px 15px 0;
	background: ${brand.neutralMid};
`;

export const StyledContainer = styled(Container)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const StyledSearchBar = styled(motion.header)`
	width: 100%;
	height: 54px;
	display: flex;
	align-items: center;
	border-radius: 6px;
	background: ${brand.white};
`;

export const GPSButton = styled(Button)`
	height: 28px;
	padding: 0 5px 0 20px;
	border-left: 1px solid ${brand.keylineLight};
	border-radius: 0;

	div {
		margin-right: 0;
	}
`;
