import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import Icon from 'components/icons/icon.component';

const StyledPoweredBy = styled.div`
	padding-bottom: 30px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;

	.icon-logo {
		margin: 0 0 0 13px;
	}
`;

const PoweredBy: FunctionComponent<{ className?: string }> = ({
	className,
}) => (
	<StyledPoweredBy className={className}>
		<FormattedMessage id="footer.poweredBy" />
		<a href="https://orderpay.com" rel="noreferrer" target="_blank" aria-label='Powered By OrderPay'>
			<Icon
				name="fullLogo"
				className="icon-logo"
				width={100}
				height={22}
				colour={brand.black}
			/>
		</a>
	</StyledPoweredBy>
);

export default PoweredBy;
