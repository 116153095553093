import { rgba } from 'polished';

import brand from '../variables/brand';
import fonts from '../variables/fonts';

/** Global sweetalert styles */
const sweetAlert = `
.swal-container {
	font-family: "Montserrat",arial,helvetica,sans-serif !important;
	background: black;

	button,
	input {
		font-family: "Montserrat",arial,helvetica,sans-serif;
    margin: 0;
	}

	.swal-popup {
		max-width: 425px;
		min-width: 325px;
		padding: 35px 15px 15px;
		display: flex;
		border-radius: 10px;
		width: auto;

		.swal-title {
			font-size: ${fonts.sizes.h1};
			font-weight: ${fonts.weights.light};
			color: ${brand.text};
			margin: 0 0 13px;
		}

		.swal-content {
			font-size: ${fonts.sizes.med};
			font-weight: ${fonts.weights.light};
			color: ${brand.text};
			padding: 0 10px;
			line-height: ${fonts.lineHeight.base};
		}

		.swal2-actions {
			display: flex;
			padding: 20px 0 0;
			max-width: 300px;
			flex-direction: row-reverse;
      gap: 5px;

			.swal2-deny,
			.swal-button-cancel,
			.swal-button-confirm {
				flex-grow: 1;
				outline: none;
				padding: 10px 22px;
				min-height: 40px;
				font-size: ${fonts.sizes.standard};
				box-shadow: 0px 0px 0px 1px ${brand.primary};
				border-radius: 5px;
				background: ${brand.primary};

				&:focus {
					box-shadow: none;
				}

				&:hover {
					background: ${brand.linkHover};
				}
			}s

			.swal2-deny,
			.swal-button-confirm {
				color: ${brand.white};
				background: ${brand.primary};
			}

			.swal-button-cancel {
				color: ${brand.primary};
				background: ${rgba(brand.primary, 0.3)};
				box-shadow: 0px 0px 0px 1px ${rgba(brand.primary, 0.3)};

				&:hover {
					background: ${rgba(brand.primary, 0.2)};
					box-shadow: 0px 0px 0px 1px ${rgba(brand.primary, 0.2)};
				}
			}
		}
	}
}
`;

export default sweetAlert;
