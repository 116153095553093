import { motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import { metresToMiles } from 'helpers/generic-helpers.helper';
import AskForAngelaButton from 'modules/ask-for-angela/ask-for-angela-button/ask-for-angela-button.component';
import formatVenueAddress from 'modules/venue/helpers/format-venue-address.helper';
import { IVenue } from 'modules/venue/venue.types';

const StyledListItem = styled(motion.li)`
	width: 100%;
	margin: 0 0 15px 0;
	padding: 0;
	position: relative;
	overflow: hidden;
	background: ${brand.white};
	border-radius: 5px;

	a {
		display: block;
		text-decoration: none;
		color: ${brand.text};
	}
`;

const StyledDetail = styled.div`
	min-height: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 12px 12px 12px 70px;
	position: relative;

	.sub-brand {
		align-items: flex-start;
		justify-content: flex-start;
		top: 12px;
		left: 12px;
		width: auto;
		right: unset;

		.sub-logo {
			width: 45px;
			height: 45px;
			background-size: contain;
			border-radius: 5px;
			border: 1px solid ${brand.neutral};
		}
	}

	.sub-title {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h3 {
			margin: 0;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: ${fonts.weights.regular};
		}

		.sub-distance {
			margin-top: 1px;
			margin-left: 5px;
			flex-shrink: 0;
			font-size: ${fonts.sizes.small};
			font-weight: ${fonts.weights.light};
		}
	}

	.sub-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: ${fonts.sizes.standard};
		font-weight: ${fonts.weights.light};
		opacity: 0.65;
		.sub-address {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
`;

interface IBackgroundImageProps {
	backgroundImage: string;
	backgroundColor: string;
}

const StyledCover = styled.div<IBackgroundImageProps>`
	width: 100%;
	height: 120px;
	position: relative;

	${mq.tabletUp`height: 150px;`}

	.sub-cover {
		width: 100%;
		height: 100%;
		background: ${({ backgroundImage, backgroundColor }) =>
		backgroundImage
			? `url(${backgroundImage}) ${backgroundColor}`
			: backgroundColor};
		background-size: cover;
		background-position: center left;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
	}

	.sub-brand {
		${({ backgroundImage }) => backgroundImage && 'display: none;'}
	}
`;

const StyledBrand = styled.div<IBackgroundImageProps>`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;

	.sub-logo {
		width: 100%;
		height: 100%;
		background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-color: ${({ backgroundColor }) => backgroundColor};
	}
`;

// Interface for component props
interface IComponentProps {
	venue: IVenue;
}

/** Renders venue list item component */
const VenueListItem: FunctionComponent<IComponentProps> = ({ venue }) => {
	return (
		<StyledListItem whileTap={{ scale: 0.95 }} data-testid="venue-list-item">
			<Link to={`/venue/${venue.id}`}>
				<StyledCover
					backgroundImage={venue.coverUrls[0]}
					backgroundColor={venue.brand.colours.bgPrimary}
				>
					<LazyLoad height={75} offset={200} once>
						<div className="sub-cover" />
					</LazyLoad>
					{venue.brand.logoUrl && !venue.coverUrls[0] && (
						<StyledBrand
							className="sub-brand"
							backgroundImage={venue.brand.logoUrl}
							backgroundColor={venue.brand.colours.bgPrimary}
						>
							<div className="sub-logo" />
						</StyledBrand>
					)}
				</StyledCover>
				<StyledDetail>
					{venue.brand.logoUrl && (
						<StyledBrand
							className="sub-brand"
							backgroundImage={venue.brand.logoUrl}
							backgroundColor={venue.brand.colours.bgPrimary}
						>
							<LazyLoad height={55} once>
								<div className="sub-logo" />
							</LazyLoad>
						</StyledBrand>
					)}
					<div className="sub-title">
						<h3>{venue.name}</h3>
						{venue.distance && (
							<span className="sub-distance">
								{metresToMiles(venue.distance)}{' '}
								{metresToMiles(venue.distance) > 1 ? 'miles' : 'mile'}
							</span>
						)}
					</div>
					<div className="sub-info">
						{venue.location?.address &&
							formatVenueAddress(venue.location.address).length > 0 && (
							<div className="sub-address">
								{formatVenueAddress(venue.location.address)}
							</div>
						)}
					</div>
				</StyledDetail>
			</Link>
			{venue.config?.askForAngelaEnabled && <AskForAngelaButton />}
		</StyledListItem>
	);
};

export default VenueListItem;
