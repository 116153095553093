import { pushEvent } from '../data-layer';

// All custom events that are configured in Google Tag Manager
export enum ECustomEvent {
	askForAngelaDetails = 'ask_for_angela_details',
	askForAngelaBannerImpression = 'ask_for_angela_banner_impression',
	removeTip = 'remove_tip',
	setTip = 'set_tip',
	removeServiceCharge = 'remove_service_charge',
	setServiceCharge = 'set_service_charge',
	splitBillMethod = 'split_bill_method',
}

// Set of all tracked events. Used for de-duping events client side
const trackedEvents = new Set<ECustomEvent>();

/**
 * Logs custom events using google tag manager
 * @link https://www.analyticsmania.com/post/how-to-track-events-with-google-analytics-4-and-google-tag-manager/#custom-events
 */
export default function customEvent(
	event: ECustomEvent,
	trackMultiple = false,
	// eslint-disable-next-line
	eventDetails?: any,
) {
	if (!trackMultiple) {
		if (trackedEvents.has(event)) return;
		trackedEvents.add(event);
	}
	pushEvent({ event, ...eventDetails });
}
