import { motion, MotionProps } from 'framer-motion';
import * as React from 'react';
import styled from 'styled-components';

import icons, { TIcons } from './icons.config';

import brand from 'assets/styles/variables/brand';
import { mq } from 'assets/styles/variables/responsive';

// Interface for component props
interface IComponentProps extends MotionProps {
	name?: TIcons;
	colour?: string;
	height?: number;
	width?: number;
	className?: string;
	widthMobile?: number;
	heightMobile?: number;
}

const StyledDiv = styled(motion.div)<IComponentProps>`
	height: ${({ height }) => height}px;
	width: ${({ width }) => width}px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	${({ heightMobile, widthMobile, height, width }) => mq.mobile`
		height: ${heightMobile || height}px;
		width: ${widthMobile || width}px;
	`}

	svg {
		color: ${({ colour }) => colour};
		width: 100%;
		height: 100%;
	}
`;

/** Renders Icon component */
const Icon: React.FC<IComponentProps> = (props) => {
	// Destructure props
	const {
		name = 'logo',
		colour = brand.text,
		height = 30,
		width = 30,
		widthMobile,
		heightMobile,
		className,
	} = props;

	const IconContent = icons[name];
	return (
		<StyledDiv
			{...props}
			className={className}
			colour={colour}
			height={height}
			width={width}
			widthMobile={widthMobile}
			heightMobile={heightMobile}
			data-testid="svg-icon-div"
		>
			<IconContent />
		</StyledDiv>
	);
};

export default Icon;
