import React, { FunctionComponent, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IVenue } from '../../venue.types';
import VenueListItem from '../venue-list-item/venue-list-item.component';
import { StyledBlankLi, StyledList } from './venue-list.styles';

import AskForAngelaBanner from 'modules/ask-for-angela/ask-for-angela-banner/ask-for-angela-banner.component';

interface IComponentProps {
	venueItems: IVenue[];
	handlePagination: () => void;
}

/** Renders venue list component */
const VenueList: FunctionComponent<IComponentProps> = ({
	venueItems,
	handlePagination,
}) => {
	/** Memoised function that returns true for the first Ask For Angela venue */
	const isFirstAskForAngelaL: (i: number) => boolean = useMemo(() => {
		// useMemo instead of useCallback because indexOfA4A should only be calculated once
		const indexOfA4A = venueItems.findIndex((venue: IVenue) =>
			Boolean(venue?.config?.askForAngelaEnabled),
		);
		return (index: number) => index === indexOfA4A;
	}, [venueItems]);

	return (
		<InfiniteScroll
			dataLength={venueItems.length + 1}
			next={handlePagination}
			hasMore={true}
			hasChildren={true}
			loader={false}
		>
			<StyledList>
				{venueItems.map((venue, index) => (
					<React.Fragment key={venue.id}>
						{isFirstAskForAngelaL(index) && (
							<StyledBlankLi key={`${venue.id}-a4a`}>
								<AskForAngelaBanner onVenue={false} />
							</StyledBlankLi>
						)}
						<VenueListItem venue={venue} key={venue.id} />
					</React.Fragment>
				))}
			</StyledList>
		</InfiniteScroll>
	);
};

export default VenueList;
