import { TEcommerceItem } from '../analytics.types';
import priceToEcommercePrice from './price-to-ecommerce-price.helper';

import { IOrderItem } from 'modules/basket/basket.types';

/**
 * Converts an IOrderItem object to an Ecommerce item
 */
export default function orderItemToEcommerceItem(
	product: IOrderItem,
): TEcommerceItem {
	const price = priceToEcommercePrice(product.price);
	return {
		...price,
		item_id: product.productId,
		item_name: product.name,
		quantity: product.quantity,
		item_list_id: product.menuId,
	};
}
