import { History } from 'history';

import { isInAndroidWebview } from 'helpers/device.helper';
import GenericError from 'modules/core/error/generic-error.class';

/** Handles errors that occur when on venue link page */
export default async function handleLVenueLinkError(
	history: History,
	venueId: string,
	error: unknown,
): Promise<void> {
	if (!(error instanceof GenericError)) return;

	// Log the error and show a dialog
	error.logAndShowDialog();

	// If Venue wasn't found, redirect to 404
	if (
		error.name.includes('venueLinkError') &&
		error.message === 'Venue not found'
	) {
		history.push('/404');
		return;
	}

	// Redirect user to correct venue
	history.push(`/venue/${venueId}`);

	// If on android webview, send message to webview container
	if (isInAndroidWebview()) {
		window.androidWebview.redirectedToVenuePage &&
			window.androidWebview.redirectedToVenuePage(venueId, error.message);
	}
}
