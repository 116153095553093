import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ITabPickupState } from './tab-pickup.types';

// Create initial state
export const initialTabPickupState: ITabPickupState = {
	eventsInProgress: 0,
	activeSession: {
		orders: [],
	},
	isPickingUpTab: false,
	isPayingTab: false,
};

const tabPickupSlice = createSlice({
	name: 'tabPickup',
	initialState: initialTabPickupState,
	reducers: {
		RESET_TAB_PICKUP_STATE(state) {
			return {
				...initialTabPickupState,
				isPickingUpTab: state.isPickingUpTab,
			};
		},
		SET_TAB_PICKUP_TABLE_NUMBER(state, action) {
			return {
				...state,
				activeSession: {
					...state.activeSession,
					tableNumber: action.payload,
				},
			};
		},
		GET_ORDERS_BY_TABLE(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDERS_BY_TABLE_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeSession: {
					...state.activeSession,
					orders: action.payload.data,
				},
			};
		},
		GET_ORDERS_BY_TABLE_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_ORDER_BY_POS_ID(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_ORDER_BY_POS_ID_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeSession: {
					...state.activeSession,
					activeOrder: action.payload.data,
					tableNumber:
						action.payload.data.tableNumber || state.activeSession.tableNumber,
				},
			};
		},
		GET_ORDER_BY_POS_ID_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_IS_PICKING_UP_TAB(state, action) {
			return {
				...state,
				isPickingUpTab: action.payload,
			};
		},
		SET_IS_PAYING_TAB(state, action) {
			return {
				...state,
				isPayingTab: action.payload,
			};
		},
		CLEAR_ACTIVE_ORDER(state) {
			return {
				...state,
				activeSession: {
					...state.activeSession,
					activeOrder: undefined,
				},
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_TAB_PICKUP_STATE,
	SET_TAB_PICKUP_TABLE_NUMBER,
	GET_ORDERS_BY_TABLE,
	GET_ORDERS_BY_TABLE_FAIL,
	GET_ORDERS_BY_TABLE_SUCCESS,
	GET_ORDER_BY_POS_ID,
	GET_ORDER_BY_POS_ID_FAIL,
	GET_ORDER_BY_POS_ID_SUCCESS,
	SET_IS_PICKING_UP_TAB,
	SET_IS_PAYING_TAB,
	CLEAR_ACTIVE_ORDER,
} = tabPickupSlice.actions;

/** Reset state */
export const resetTabPickupState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_TAB_PICKUP_STATE());
};

/** Set active table number */
export const setTabPickupTableNumber = (tableNumber: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_TAB_PICKUP_TABLE_NUMBER(tableNumber));
};

/** Get orders by table number */
export const getOrdersByTable = (
	venueId: string,
	tableNumber: string,
) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_ORDERS_BY_TABLE({
			request: {
				method: 'get',
				url: `2/tabs/from-pos/${venueId}/${tableNumber}`,
			},
		}),
	);

	return response.payload?.data;
};

/** Get order by POS ID */
export const getOrderByPosId = (venueId: string, posId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_ORDER_BY_POS_ID({
			request: {
				method: 'get',
				url: `1/tabs/from-pos/${venueId}/by-pos-id/${posId}`,
			},
		}),
	);

	return response.payload?.data;
};

/** Set has picked up active tab */
export const setIsPickingUpTab = (hasPickedupTab: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_IS_PICKING_UP_TAB(hasPickedupTab));
};

/** Set paying tab */
export const setIsPayingTab = (isPayingTab: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_IS_PAYING_TAB(isPayingTab));
};

/** Clear active order */
export const clearActiveOrder = () => async (dispatch: Dispatch) => {
	await dispatch(CLEAR_ACTIVE_ORDER());
};

export default tabPickupSlice.reducer;
