import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	StyledWrapper,
	StyledHero,
	StyledCover,
	StyledNavButton,
	StyledHeroDetail,
	StyledBrand,
	StyledLogo,
	StyledIntro,
	StyledSearch,
	StyledSearchButton,
	StyledSearchInput,
	StyledMapAutoComplete,
	StyledNoResults,
} from './brand-landing.styles';
import CoverGradient from './cover-gradient.component';

import ActiveTabBanner from 'components/active-tab-banner/active-tab-banner.component';
import GenericFooter from 'components/generic-footer/generic-footer.component';
import {
	addLoadingEvent,
	removeLoadingEvent,
} from 'components/loading/loading.slice';
import animations from 'helpers/animations.helper';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { setUserCoords } from 'modules/auth/auth.slice';
import { ICoordinates } from 'modules/auth/auth.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { RootState } from 'modules/core/state/root.reducer';
import { setThemeMode } from 'modules/core/theme/theme.slice';
import { matchesActiveBasketPaymentOption } from 'modules/order/helpers/onpl.helper';

/** Renders brand landing page component */
const BrandLandingPage: FunctionComponent = () => {
	// Get hooks
	const history = useHistory();
	const dispatch = useReduxDispatch();
	const intl = useIntl();
	const screenHeight = use100vh();

	// Local component state/refs
	const locationTimeout = useRef(0);
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [isLocating, setIsLocating] = useState<boolean>(false);

	// Get coords from state
	const authState = useSelector((state: RootState) => state.auth);
	const userCoords = authState.user.coords;
	const { hasAuth } = authState;

	// Get active brand from brand state
	const { activeBrand } = useSelector((state: RootState) => state.brand);

	const { activeBasket, fullBasket, venue } = useSelector(
		(state: RootState) => state.basket,
	);

	/** When user's coords have changed + initial load */
	useEffect(() => {
		// Initial load complete, coords changed
		if (hasLoaded && userCoords?.longitude && userCoords.latitude) {
			// Navigate to venue list page
			history.push('/venues');
		} else {
			setHasLoaded(true);
			dispatch(setThemeMode('brand'));
		}
	}, [userCoords, hasAuth]);

	/** When user is being located */
	useEffect(() => {
		const checkState = async () => {
			// 5s timeout for geolocation
			await new Promise((resolve) => {
				locationTimeout.current = setTimeout(() => {
					resolve(null);
				}, 5000);
			});
			// If user hasn't been located
			isLocating && handleGeolocationError();
		};
		checkState();

		return () => {
			setIsLocating(false);
			clearTimeout(locationTimeout.current);
		};
	}, [isLocating]);

	/** Handle when user's geolocation has changed */
	const handleGeolocationChange = (position: { coords: ICoordinates }) => {
		clearTimeout(locationTimeout.current);
		setIsLocating(false);
		dispatch(removeLoadingEvent());

		dispatch(
			setUserCoords({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			}),
		);
	};

	/** Handle geolocation errors */
	const handleGeolocationError = () => {
		clearTimeout(locationTimeout.current);
		setIsLocating(false);
		dispatch(removeLoadingEvent());

		fireDialog({
			title: intl.formatMessage({ id: 'brand.landing.location.failed.title' }),
			text: intl.formatMessage({ id: 'brand.landing.location.failed.text' }),
		});
	};

	/** Get user's geolocation */
	const getGeolocation = async () => {
		setIsLocating(true);
		dispatch(addLoadingEvent());

		if (!navigator.geolocation) {
			handleGeolocationError();
		} else {
			navigator.geolocation.getCurrentPosition(
				handleGeolocationChange,
				handleGeolocationError,
				{ maximumAge: 15000, timeout: 5000, enableHighAccuracy: true },
			);
		}
	};

	return (
		<>
			<StyledWrapper style={{ minHeight: `${screenHeight}px` }}>
				<StyledHero
					{...animations.slideInDown}
					transition={{ delay: 0.2, duration: 0.3 }}
				>
					<StyledCover
						backgroundImage={activeBrand.coverUrls && activeBrand.coverUrls[0]}
					>
						{activeBrand.coverUrls && activeBrand.coverUrls.length > 0 && (
							<>
								<img src={activeBrand.coverUrls[0]} alt={activeBrand.name} />
								<CoverGradient color={activeBrand?.colour?.bgPrimary} />
							</>
						)}
					</StyledCover>
					<StyledNavButton />
					<StyledHeroDetail>
						{activeBrand.logoUrl && (
							<StyledBrand>
								<StyledLogo backgroundImage={activeBrand.logoUrl} />
							</StyledBrand>
						)}
					</StyledHeroDetail>
				</StyledHero>

				<StyledIntro>
					<h1>{activeBrand.name}</h1>
					{activeBrand.description}
				</StyledIntro>
				<StyledSearch>
					<h2>
						<FormattedMessage id="brand.landing.search.title" />
					</h2>
					<StyledSearchButton
						variant="primary"
						icon="map"
						onClick={() => getGeolocation()}
						iconWidth={15}
						iconHeight={18}
					>
						<FormattedMessage id="brand.landing.search.button.nearMe" />
					</StyledSearchButton>
					<StyledSearchInput>
						<StyledMapAutoComplete
							mode="search"
							inputPlaceholder={intl.formatMessage({
								id: 'venue.search.title.orSearch',
							})}
						/>
					</StyledSearchInput>
				</StyledSearch>
				{isLocating && (
					<StyledNoResults
						titleLocale="venuesList.locating.title"
						textLocale="venuesList.locating.text"
						iconName="location"
						fullHeight={true}
					/>
				)}
			</StyledWrapper>
			<GenericFooter variant="white" />
			{matchesActiveBasketPaymentOption(activeBasket, [
				'TabPickup',
				'OrderNowPayLater',
			]) &&
				!!fullBasket?.order && <ActiveTabBanner venueName={venue?.name!} />}
		</>
	);
};

export default BrandLandingPage;
