import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { checkoutFailureStates } from '../checkout.slice';

import { RootState } from 'modules/core/state/root.reducer';

/** Listens to checkout order status and redirects user to checkout success on failure */
const CheckoutStatusProvider: FunctionComponent = () => {
	// Get hooks
	const history = useHistory();
	const location = useLocation();

	// Get data from app state
	const { activeOrder } = useSelector((state: RootState) => state.checkout);

	useEffect(() => {
		if (
			activeOrder?.status &&
			checkoutFailureStates.includes(activeOrder.status) &&
			location.pathname !== '/checkout/success'
		) {
			history.push('/checkout/success');
		}
	}, [activeOrder?.status]);

	return null;
};

export default CheckoutStatusProvider;
