import React from 'react';
import ReactDOM from 'react-dom';

import App from './app.component';
import { GlobalStyle } from './assets/styles/app';
import * as serviceWorker from './serviceWorker';

import initialiseLogs from 'modules/core/logging/initialise-logs.helper';

/** Render react app */
if (process.env.NODE_ENV !== 'production') {
	import('@axe-core/react').then(({ default: axe }) => {
		axe(React, ReactDOM, 1000);
		ReactDOM.render(
			<React.StrictMode>
				<GlobalStyle />
				<App />
			</React.StrictMode>,
			document.getElementById('root'),
		);
	});
} else {
	ReactDOM.render(
		<React.StrictMode>
			<GlobalStyle />
			<App />
		</React.StrictMode>,
		document.getElementById('root'),
	);
}

// Initialise logging tools
initialiseLogs();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
