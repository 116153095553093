/** structure variables */
const structure = {
	container: {
		maxWidth: '800px',
		mobileWidth: '100%',
		mobilePadding: '15px',
	},
};

export default structure;
