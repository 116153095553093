import { Severity } from '@sentry/react';
import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom-v5-compat';

import errorLogger from '../error/helpers/error-logger.helper';

interface IRedirectRecord {
	label?: string;
	from: string;
	to: string;
}

// This might be something we want to save on the backend.
// IF that is the case, then we would need to rethink this from being a route, to maybe a middleware or hook so it can load it asyncronously
export const REDIRECTS: readonly IRedirectRecord[] = [
	{
		label: 'Zedel',
		from: '06757306-3600-423d-ad9a-738989fff182',
		to: '0833c442-f44d-4242-8bb6-1968e033bfa8',
	},
];

export const REDIRECTED_URLS = REDIRECTS.map(({ from }) => `/venue/${from}`);

export const getNewPath = (current: string) => {
	const matchingRedirect = REDIRECTS.find(({ from }) => current.includes(`/venue/${from}`));
	if (matchingRedirect) {
		const newPath = current.replace(matchingRedirect.from, matchingRedirect.to);
		errorLogger(Error(`Venue redirect - ${matchingRedirect.label ? `${matchingRedirect.label} - ` : ''} from ${current} to ${newPath}`), Severity.Error);
		return newPath;
	}
	// This should never happen, as we only redirect venues which are in REDIRECTS
	// This is here just in case
	errorLogger(Error(`Venue redirect - No matching redirect - current url ${current}`), Severity.Error);
	return current;
};

export const RedirectVenueHandler: FunctionComponent = () => {
	const location = useLocation();
	const newPath = getNewPath(`${location.pathname}?${location.search}`);
	return (
		<Navigate
			to={newPath}
			replace
		/>
	);
};

