import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getSubdomain } from 'tldts';

import { tracker } from '../../core/logging/initialise-logs.helper';
import Hero from './hero/hero.component';
import List from './list/list.component';
import { StyledWrapper } from './o2-landing-page.styles';

import ActiveTabBanner from 'components/active-tab-banner/active-tab-banner.component';
import GenericFooter from 'components/generic-footer/generic-footer.component';
import {
	addLoadingEvent,
	removeLoadingEvent,
} from 'components/loading/loading.slice';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { useRetrieveEventParameters } from 'hooks/url-parameters.hook';
import { setUserCoords } from 'modules/auth/auth.slice';
import { ICoordinates } from 'modules/auth/auth.types';
import { fireDialog } from 'modules/core/dialog/dialog.service';
import { RootState } from 'modules/core/state/root.reducer';
import { setThemeMode } from 'modules/core/theme/theme.slice';
import { matchesActiveBasketPaymentOption } from 'modules/order/helpers/onpl.helper';

/** Renders brand landing page component */
const O2LandingPage: FunctionComponent = () => {
	// Get hooks
	const dispatch = useReduxDispatch();
	const intl = useIntl();
	const screenHeight = use100vh();

	// Local component state/refs
	const locationTimeout = useRef(0);
	const [isLocating, setIsLocating] = useState<boolean>(false);

	const { activeBasket, fullBasket, venue } = useSelector(
		(state: RootState) => state.basket,
	);

	let subDomain = getSubdomain(window.location.href);
	if (subDomain && subDomain.indexOf('.')) {
		subDomain = subDomain.substring(0, subDomain.indexOf('.'));
	}

	tracker.event('visited_o2_landing', subDomain);

	/** initial load */
	useEffect(() => {
		dispatch(setThemeMode('brand'));
	}, []);

	/** When user is being located */
	useEffect(() => {
		const checkState = async () => {
			// 5s timeout for geolocation
			await new Promise((resolve) => {
				locationTimeout.current = setTimeout(() => {
					resolve(null);
				}, 5000);
			});
			// If user hasn't been located
			isLocating && handleGeolocationError();
		};
		checkState();

		return () => {
			setIsLocating(false);
			clearTimeout(locationTimeout.current);
		};
	}, [isLocating]);

	/** Handle when user's geolocation has changed */
	const handleGeolocationChange = (position: { coords: ICoordinates }) => {
		clearTimeout(locationTimeout.current);
		setIsLocating(false);
		dispatch(removeLoadingEvent());

		dispatch(
			setUserCoords({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			}),
		);
	};

	/** Handle geolocation errors */
	const handleGeolocationError = () => {
		clearTimeout(locationTimeout.current);
		setIsLocating(false);
		dispatch(removeLoadingEvent());

		fireDialog({
			title: intl.formatMessage({ id: 'brand.landing.location.failed.title' }),
			text: intl.formatMessage({ id: 'brand.landing.location.failed.text' }),
		});
	};

	/** Get user's geolocation */
	const getGeolocation = async () => {
		setIsLocating(true);
		dispatch(addLoadingEvent());

		if (!navigator.geolocation) {
			handleGeolocationError();
		} else {
			navigator.geolocation.getCurrentPosition(
				handleGeolocationChange,
				handleGeolocationError,
				{ maximumAge: 15000, timeout: 5000, enableHighAccuracy: true },
			);
		}
	};

	useRetrieveEventParameters();

	return (
		<>
			<StyledWrapper style={{ minHeight: `${screenHeight}px` }}>
				<Hero />
				<List getGeolocation={getGeolocation} />
			</StyledWrapper>
			<GenericFooter variant="white" />
			{matchesActiveBasketPaymentOption(activeBasket, [
				'TabPickup',
				'OrderNowPayLater',
			]) &&
				!!fullBasket?.order && <ActiveTabBanner venueName={venue?.name!} />}
		</>
	);
};

export default O2LandingPage;
