import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import structure from 'assets/styles/variables/structure';
import Button from 'components/button/button.component';
import Container from 'components/container/container.component';
import MapAutoComplete from 'components/maps/autocomplete/map-autocomplete.component';
import NoResults from 'components/no-results/no-results.component';
import NavButton from 'modules/core/navigation/nav-button.component';

export const StyledNavButton = styled(NavButton)`
	position: absolute;
	right: 0;
	top: 12px;
`;

export const StyledWrapper = styled.section`
	height: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.bgPrimary};
	color: ${({ theme }) => theme.colors.lblPrimary};
`;

export const StyledHero = styled(motion.article)`
	position: relative;
`;

export const StyledCover = styled.div<{
	backgroundImage?: string;
}>`
	min-height: 100px;
	max-height: 220px;
	${({ backgroundImage }) =>
		backgroundImage && `background-image: url(${backgroundImage})`};
	background-size: cover;
	background-position: center;
	overflow: hidden;
	display: relative;

	img {
		width: 100%;
		height: auto;
		margin-bottom: -5px;
		opacity: 0;
	}
`;

export const StyledHeroDetail = styled.article`
	padding: 20px 0 0 0;
	display: flex;
	flex-direction: column;
	text-align: center;
	line-height: ${fonts.lineHeight.med};
	font-weight: ${fonts.weights.light};
	position: relative;

	${mq.tabletUp`padding: 75px 0 45px 0;`}

	&:before {
		width: 100%;
		height: 50px;
		background: ${({ theme }) => theme.colors.bgPrimary};
		content: '';
		position: absolute;
		top: -44px;
		left: 0;
		z-index: 0;
		clip-path: polygon(0 45px, 100% 0, 100% 100%, 0% 100%);
	}
`;

export const StyledBrand = styled.header`
	width: 100%;
	display: flex;
	justify-content: center;
	position: absolute;
	top: -75px;
`;

export const StyledLogo = styled.div<{
	backgroundImage?: string;
}>`
	width: 96px;
	height: 96px;
	padding: 12px;
	border: 1px solid ${rgba(brand.white, 0.25)};
	${({ backgroundImage }) =>
		backgroundImage && `background-image: url(${backgroundImage})`};
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-origin: content-box;
	background-color: ${({ theme }) => theme.colors.bgPrimary};
	border-radius: 10px;
`;

export const StyledIntro = styled.div`
	width: ${structure.container.mobileWidth};
	max-width: ${structure.container.maxWidth};
	padding: 25px 30px 0;
	text-align: center;
	font-size: ${fonts.sizes.med};

	h1 {
		font-weight: ${fonts.weights.medium};
		font-size: ${fonts.sizes.h2};
		margin: 0 0 8px;
		color: ${({ theme }) => theme.colors.lblPrimary};
	}

	p:last-child {
		margin-bottom: 0;
	}

	.read-more-button {
		display: inline;
		margin-left: 3px;
		color: ${({ theme }) => theme.colors.lblPrimary};
	}
`;

export const StyledSearch = styled(Container)`
	width: ${structure.container.mobileWidth};
	max-width: ${structure.container.maxWidth};
	padding: 60px ${structure.container.mobilePadding} 100px;
	text-align: center;
	color: ${({ theme }) => theme.colors.lblPrimary};

	h2 {
		font-size: ${fonts.sizes.large};
		font-weight: ${fonts.weights.medium};
		margin-bottom: 22px;
		color: ${({ theme }) => theme.colors.lblPrimary};
	}
`;

export const StyledSearchInput = styled.div`
	position: relative;
`;

export const StyledSearchButton = styled(Button)`
	width: 100%;
	background: ${({ theme }) => theme.colors.lblPrimary};
	color: ${({ theme }) => theme.colors.lblSecondary};
	display: flex;
	justify-content: center;
	font-weight: ${fonts.weights.medium};
	margin: 0 0 25px;
`;

export const StyledNoResults = styled(NoResults)`
	background: ${brand.white};
	color: ${brand.text};
	position: fixed;
	top: 0;
	left: 0;
`;

export const StyledMapAutoComplete = styled(MapAutoComplete)`
	input {
		padding: 5px 38px 5px 0;
		border: none;
		border-bottom: 1px solid ${({ theme }) => theme.colors.lblPrimary};
		background: transparent;
		font-size: ${fonts.sizes.large};
		appearance: none;
		outline: none;
		border-radius: 0;
		color: ${({ theme }) => theme.colors.lblPrimary};

		&:focus {
			border-color: ${({ theme }) => theme.colors.lblPrimary};
			outline: none;
		}

		::-webkit-input-placeholder {
			/* Chrome/Opera/Safari */
			color: ${rgba(brand.white, 0.6)};
		}
		::-moz-placeholder {
			/* Firefox 19+ */
			color: ${rgba(brand.white, 0.6)};
		}
		:-ms-input-placeholder {
			/* IE 10+ */
			color: ${rgba(brand.white, 0.6)};
		}
		:-moz-placeholder {
			/* Firefox 18- */
			color: ${rgba(brand.white, 0.6)};
		}
	}

	.icon-search {
		left: inherit;
		right: 10px;

		svg {
			color: ${({ theme }) => theme.colors.lblPrimary};
		}
	}
`;
