import React, { FunctionComponent, ReactNode } from 'react';
import { use100vh } from 'react-div-100vh';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import LoadingAnimation from 'components/animations/loading-animation.component';
import Button from 'components/button/button.component';
import { TButtonVariants } from 'components/button/button.types';
import Container from 'components/container/container.component';
import Icon from 'components/icons/icon.component';
import iconsConfig from 'components/icons/icons.config';

const StyledNoResults = styled(Container)`
	padding-top: 30px;
	padding-bottom: 30px;

	&.mod-full-height {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}

	&.mod-center {
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}

	h1 {
		margin-bottom: 10px;
	}

	p {
		font-size: ${fonts.sizes.h4};
		margin: 0 3rem 1rem;
	}

	button {
		width: 100%;
		min-height: 50px;
		margin-top: 20px;

		${mq.tabletUp`min-height: 56px;`}
	}
`;

const StyledIcon = styled(Icon)`
	margin: 0 auto 3rem;
`;

const StyledLoadingContainer = styled.div<{ width: number; height: number }>`
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	margin: 0 auto 3rem;
	color: ${({ theme }) => theme?.colors?.link};
`;

const StyledBody = styled.div`
	margin: auto 0;
	@media screen and (min-height: 680px) {
		margin: 19vh 0 auto;
	}
`;

export interface INoResultsButtonConfig {
	locale: string;
	onClick: () => void;
	variant?: TButtonVariants;
}
interface IComponentProps {
	titleLocale: string;
	textLocale?: string;
	buttons?: INoResultsButtonConfig[];
	iconName?: keyof typeof iconsConfig;
	fullHeight?: boolean;
	center?: boolean;
	className?: string;
	showLoading?: boolean;
	content?: ReactNode;
	iconSize?: {
		width: number;
		height: number;
	};
	iconColor?: string;
}

/** Renders no results component */
const NoResults: FunctionComponent<IComponentProps> = ({
	titleLocale,
	textLocale,
	buttons,
	iconName,
	fullHeight,
	center,
	className,
	showLoading,
	content,
	iconSize = {
		width: 97,
		height: 97,
	},
	iconColor,
}) => {
	const screenHeight = use100vh();
	const height = fullHeight ? `${screenHeight}` : '';

	return (
		<StyledNoResults
			className={`${className} ${fullHeight ? 'mod-full-height' : ''} ${
				center ? 'mod-center' : ''
			}`}
			style={{
				height,
			}}
		>
			<StyledBody>
				{iconName && (
					<StyledIcon name={iconName} {...iconSize} colour={iconColor} />
				)}
				{showLoading && (
					<StyledLoadingContainer {...iconSize}>
						<LoadingAnimation />
					</StyledLoadingContainer>
				)}
				<h1>
					<FormattedMessage id={titleLocale} />
				</h1>
				{textLocale && (
					<p>
						<FormattedMessage id={textLocale} />
					</p>
				)}
				{content && <div>{content}</div>}
			</StyledBody>
			{buttons && buttons.length > 0
				? buttons.map((buttonConfig) => (
					<Button
						key={buttonConfig.locale}
						variant={buttonConfig.variant ?? 'black'}
						type="button"
						onClick={() => buttonConfig.onClick()}
					>
						<FormattedMessage id={buttonConfig.locale} />
					</Button>
				  ))
				: null}
		</StyledNoResults>
	);
};

export default NoResults;
