import { rgba } from 'polished';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

interface IStyledLoadingComponentProps {
	isLoading: boolean;
	isOnTop: boolean;
}

export const StyledLoadingComponent = styled.div<IStyledLoadingComponentProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 20;
    align-items: center;
    justify-content: center;
    transition: opacity 0.15s ease;
    background: ${rgba(brand.black, 0.6)};
    opacity: 0;
    pointer-events: none;
    left: 0;
    top: 0;
    ${({ isLoading }) => isLoading && 'opacity: 1; pointer-events: all;'}
    ${({ isOnTop }) => isOnTop && 'z-index: 9999;'}
`;

export const StyledLoadingMessage = styled.div`
    margin: auto;
    padding: 32px 16px 26px;
    border-radius: 15px;
    background-color: ${brand.white};
    text-align: center;
    max-width: 232px;
`;

export const StyledLoadingContainer = styled.div`
    width: 80px;
    height: 80px;
    position: relative;
    color: ${brand.white};
    margin: auto;
`;

export const StyledLoadingAnimation = styled.div`
    position: relative;
    color: ${brand.text};
    margin: auto;
    width: 40px;
    height: 40px;
`;

export const StyledLoadingTitle = styled.h2`
    color: ${brand.text};
    font-weight: ${fonts.weights.regular};
    font-size: ${fonts.sizes.large};
    line-height: ${fonts.lineHeight.small};
    margin: 0 auto 22px;
`;

export const StyledLoadingText = styled.p`
    font-weight: ${fonts.weights.light};
    font-size: ${fonts.sizes.standard};
    line-height: ${fonts.lineHeight.base};
    margin: 22px auto 0;
`;