import { AxiosError } from 'axios';

import { fireDialog } from '../dialog/dialog.service';
import { intl } from '../i18n/i18n.config';
import { authErrors } from './helpers/endpoint-error.helper';

interface IExcludedEndpoint {
	route: string | RegExp;
	errors: number[];
}

/** Handle an error response from the API */
export const handleApiError = async (error?: AxiosError) => {
	// This will catch any cancel events
	if (error && typeof error.isAxiosError !== 'boolean') {
		return Promise.resolve();
	}

	// Get error code from error
	const errorCode: string | undefined = error?.code;
	// Get statuscode from error
	const statusCode: number | undefined = error?.response?.status;
	// Get requested endpoint from error
	const endpoint: string | undefined = error?.response?.config?.url;
	// Is the endpoint polling (should fail silently)
	const polling: boolean = error?.config.params?.polling;
	// Endpoint is excluded from generic response
	let isExcluded: boolean = false;

	// If endpoint is polling - don't show an error
	if (polling) {
		return Promise.resolve();
	}

	// Endpoints with specific error handling
	const excludedEndpoints: IExcludedEndpoint[] = [
		{
			route: '1/auth/login',
			errors: [401],
		},
		{
			route: '1/auth/social/google',
			errors: [404],
		},
		{
			route: '1/auth/social/apple',
			errors: [404],
		},
		{
			route: '1/auth/social/facebook',
			errors: [404],
		},
		{
			route: '2/basket/active',
			errors: [404],
		},
		{
			route: new RegExp(/1\/order\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/upsell/),
			errors: [404],
		},
		{
			route: new RegExp(/1\/upsell\/venue\/[\w]{8}(-[\w]{4}){3}-[\w]{12}/),
			errors: [404],
		},
		{
			route: new RegExp(
				/1\/venues\/[\w]{8}(-[\w]{4}){3}-[\w]{12}\/click-and-collect\/timeslots/,
			),
			errors: [404],
		},
		{
			route: new RegExp(/1\/marketing\/survey-link\/feed-it-back\/[\w]+/),
			errors: [404],
		},
	];

	// Check if requested endpoint is in excluded list
	const endpointExcluded = endpoint
		? excludedEndpoints.find((excludedEndpoint) => {
			// If string then just use ===
			if (typeof excludedEndpoint.route === 'string') {
				return excludedEndpoint.route === endpoint;
			}

			// else assume its regex
			return excludedEndpoint.route.test(endpoint);
		  })
		: undefined;

	// If endpoint is excluded + status code is set
	if (endpointExcluded && statusCode) {
		// If endpoint has this status code excluded
		isExcluded = endpointExcluded?.errors.includes(statusCode);
	}

	// Define responses we'll handle in a generic way
	const genericResponses = [400, 401, 403, 404, 409, 422, 424, 500];

	// If endpoint isn't excluded
	if (!isExcluded) {
		// If we have a status code and it's one of the generic responses we handle
		if (statusCode && genericResponses.includes(statusCode)) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: `errors.responses.${statusCode}.title`,
				}),
				text: error?.response?.data?.errorMessage
					? error?.response?.data?.errorMessage
					: intl.formatMessage({
						id: `errors.responses.${statusCode}.text`,
					  }),
				icon: 'error',
			});
		} else if (
			// Timeout
			errorCode === 'ECONNABORTED'
		) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.timeout.title',
				}),
				text: intl.formatMessage({
					id: 'errors.responses.timeout.text',
				}),
				icon: 'error',
			});
		} else if (
			// Network error
			!!error?.isAxiosError &&
			!error?.response
		) {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.network.title',
				}),
				text: intl.formatMessage({
					id: 'errors.responses.network.text',
				}),
				icon: 'error',
			});
		} else {
			// Fire an error message
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.responses.general.title',
				}),
				text: error?.response?.data?.errorMessage
					? error?.response?.data?.errorMessage
					: intl.formatMessage({
						id: 'errors.responses.general.text',
					  }),
				icon: 'error',
			});
		}
	} else {
		// run excluded endpoint
		authErrors(error!);
	}

	// Pass error back to caller
	return Promise.reject(error);
};

// Export error service as object
export const errorService = { handleApiError };
