import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeroUnit, Caption, LogoWrapper, Logo } from './hero.styles';

import animations from 'helpers/animations.helper';

const Hero: FunctionComponent = () => {
	return (
		<HeroUnit>
			<Caption {...animations.fadeInDown}>
				<FormattedMessage id="brand.landing.gbk-o2.hero" />
			</Caption>
			<LogoWrapper>
				<Logo {...animations.fadeInUp} />
				<Logo {...animations.fadeInUp} />
			</LogoWrapper>
		</HeroUnit>
	);
};

export default Hero;
