import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import Icon from 'components/icons/icon.component';
import { IUser } from 'modules/auth/auth.types';

const StyledNavProfile = styled.div`
	display: flex;
	align-items: center;
	margin: 3px 0 25px;
`;

const StyledName = styled.div`
	font-style: normal;
	font-weight: ${fonts.weights.medium};
	font-size: ${fonts.sizes.med};
	line-height: ${fonts.lineHeight.large};
	color: ${brand.black};
	margin-left: 20px;
`;

interface IComponentProps {
	user?: IUser;
}

const NavProfile: FunctionComponent<IComponentProps> = ({ user }) => {
	const name =
		user && (user?.firstName || user?.lastName)
			? `${user.firstName || ''} ${user.lastName || ''}`
			: '';
	return (
		<StyledNavProfile>
			<Icon name={name ? 'profile' : 'logoBg'} width={40} height={40} />
			<StyledName>
				{name || <FormattedMessage id="navigation.notLoggedIn.title" />}
			</StyledName>
		</StyledNavProfile>
	);
};

export default NavProfile;
