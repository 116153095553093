import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IBrandState } from './brand.types';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';

// Create initial state
export const initialBrandState: IBrandState = {
	eventsInProgress: 0,
	subDomain: '',
	activeBrand: {
		id: '',
		name: 'OrderPay',
		description: "The UK's #1 order at table app",
		website: 'https://www.orderpay.com',
		logoUrl:
			'https://orderpay.s3.eu-west-2.amazonaws.com/brands/logos/orderpay-logo.png',
		coverUrls: [
			'https://orderpay.s3.eu-west-2.amazonaws.com/brands/covers/cover_orderpay_01.jpg',
		],
		font: {
			family: fonts.family,
			url:
				'https://orderpay.s3.eu-west-2.amazonaws.com/brands/fonts/Montserrat-Regular.ttf',
			h1: 26,
			h2: 20,
			h3: 18,
			text: 14,
			highlights: 15,
			smallText: 12,
		},
		colour: {
			bgPrimary: brand.text,
			bgSecondary: brand.white,
			lblPrimary: brand.white,
			lblSecondary: brand.text,
			actPrimary: brand.white,
			actSecondary: brand.text,
			actLblPrimary: brand.text,
			actLblSecondary: brand.white,
		},
	},
};

const brandSlice = createSlice({
	name: 'brand',
	initialState: initialBrandState,
	reducers: {
		RESET_BRAND_STATE() {
			return {
				...initialBrandState,
			};
		},
		SET_SUBDOMAIN(state, action) {
			return {
				...state,
				subDomain: action.payload,
			};
		},
		GET_BRAND_BY_SLUG(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BRAND_BY_SLUG_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeBrand: action.payload.data,
			};
		},
		GET_BRAND_BY_SLUG_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_BRAND_BY_ID(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_BRAND_BY_ID_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				activeBrand: {
					...action.payload.data,
					// Was added because the backend returned 'color' instead of 'colour'
					colour: {
						...action.payload.data.color,
					},
				},
			};
		},
		GET_BRAND_BY_ID_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_BRAND_STATE,
	SET_SUBDOMAIN,
	GET_BRAND_BY_SLUG,
	GET_BRAND_BY_SLUG_FAIL,
	GET_BRAND_BY_SLUG_SUCCESS,
	GET_BRAND_BY_ID,
	GET_BRAND_BY_ID_FAIL,
	GET_BRAND_BY_ID_SUCCESS,
} = brandSlice.actions;

/** Reset state */
export const resetBrandState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_BRAND_STATE());
};

export const setSubdomain = (subDomain: string) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_SUBDOMAIN(subDomain));
};

/** Get brand request */
export const getBrandBySlug = (brandSlug: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		GET_BRAND_BY_SLUG({
			request: {
				method: 'get',
				url: `1/brands/slug/${brandSlug}`,
			},
		}),
	);

	return response.payload?.data;
};

/** Get brand by brandId request */
export const getBrandById = (brandId: string) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_BRAND_BY_ID({
			request: {
				method: 'get',
				url: `1/brands/${brandId}`,
			},
		}),
	);

	return response.payload?.data;
};

export default brandSlice.reducer;
