import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { setAskForAngelaOpen } from '../ask-for-angela.slice';

interface IComponentProps {
	className?: string;
	testId?: string;
	onVenue?: boolean;
}

const AskForAngelaBase: FunctionComponent<IComponentProps> = ({
	children,
	className,
	testId,
	onVenue = false,
}) => {
	const dispatch = useDispatch();
	return (
		<button
			data-testid={testId}
			className={className}
			type="button"
			onClick={(event) => {
				event.stopPropagation();
				dispatch(
					setAskForAngelaOpen({
						open: true,
						venueWaveTraining: onVenue,
					}),
				);
			}}
		>
			{children}
		</button>
	);
};

export default AskForAngelaBase;
