import { Severity } from '@sentry/react';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fireDialog } from '../dialog/dialog.service';
import errorLogger from '../error/helpers/error-logger.helper';
import { intl } from '../i18n/i18n.config';
import { RootState } from '../state/root.reducer';

window.androidWebview = window.androidWebview || {};

// TODO - This can probably be removed when Android/Kotlin no longer relies on web ordering
/** Window provider component */
const WindowProvider: FunctionComponent = () => {
	const { fullBasket } = useSelector((state: RootState) => state.basket);
	const { activeBasket } = useSelector((state: RootState) => state.basket);
	const { paymentOption } = useSelector(
		(state: RootState) => state.basket.activeBasket,
	);

	useEffect(() => {
		// Android window event
		window.androidWebview.handleGooglePayOnCancelled = () => {
			errorLogger(
				{
					name: 'GooglePay on Android cancelled',
					message: 'User cancelled GooglePay in Android webview',
				},
				Severity.Warning,
			);
		};
		// Android window event
		window.androidWebview.handleGooglePayOnFailure = () => {
			errorLogger(
				{
					name: 'integrationError - handleGooglePayOnFailure',
					message: 'GooglePay on Android failed',
				},
				Severity.Error,
			);
			fireDialog({
				title: intl.formatMessage({
					id: 'errors.general.title',
				}),
				text: intl.formatMessage({
					id: 'errors.payment.googlePay.text',
				}),
			});
		};
	}, []);

	useEffect(() => {
		// Android window event
		// Deprecated by getONPLBasketId but needed to support older Android versions
		window.androidWebview.getActiveBasketId = (): string => {
			return activeBasket.id || '';
		};
		// Android window event
		window.androidWebview.getONPLBasketId = (): string => {
			if (paymentOption === 'OrderNowPayLater') {
				return activeBasket.id || '';
			}
			return '';
		};
	}, [activeBasket.id, paymentOption, fullBasket?.status, fullBasket?.id]);

	return <div data-testid="window-provider" />;
};

export default WindowProvider;
