// Straight outta backend

import { IPagination } from 'app.types';

export default interface IVenueContactDetails {
	phone?: string | null;
	website?: string | null;
}

export interface IVenueTippingConfig {
	increments: [number, number, number];
	default: number;
	notificationTimes: {
		first: number;
		delay: number;
	};
	messaging: {
		notification: {
			title: string;
			message: string;
		};
		thankYou: string;
	};
}

export enum EServiceChargeType {
	amount = 'amount',
	percentage = 'percentage',
}

export enum EServiceType {
	orderAhead,
	tableService,
	delivery,
	selfService,
	customService,
}

export enum EPaymentOption {
	payNow,
	payLater,
}

export enum ETableServiceFeature {
	OrderNowPayLater = 'OrderNowPayLater', // Standard ONPL Flow
	PayAndGo = 'PayAndGo', // Pay & Go
	TabPickup = 'TabPickup', // Join a tab (Split billing, etc)
	OrderAndPay = 'OrderAndPay', // Standard Order Now Pay Now (Order Injection)
	JoinExistingTab = 'JoinExistingTab',
}

export interface IVenueServiceTableServiceConfig {
	paymentOption: EPaymentOption;
	tableServiceFeatures: (keyof typeof ETableServiceFeature)[];
}

export interface IVenueServiceChargeConfig {
	id?: string;
	venueServiceTypeId?: string;
	enabled: boolean;
	name: string;
	type: EServiceChargeType;
	value: number;
	editable: boolean;
	appMessage?: string;
	emailMessage?: string;
}

export interface IVenueService {
	isActive: boolean;
	deactivatedReason: string | null;
	operationalHours: IVenueOpeningTime[];
	orderInstructions: string | null;
	tippingConfig?: IVenueTippingConfig;
	ordersPausedUntil?: Date;
	acceptsVouchers: boolean;
	type: EServiceType;
	tableServiceConfig: IVenueServiceTableServiceConfig | null;
	featuresEnabled?: string[];
	serviceChargeConfig?: IVenueServiceChargeConfig;
	venueId?: string;
	preAuth?: {
		amountPence: number | null;
		enabled: boolean;
		isAmountPerGuest: boolean;
	};
	isAvailable?: boolean;
	orderNotesEnabled?: boolean;
	itemNotesEnabled?: boolean;
	minimumSpendPence?: number | null;
	onplEnabled?: boolean;
}

export interface IVenueCustomServiceConfig {
	fields?: string[];
	iconUrl?: string;
	inverseButtonColor?: false;
	modalTitle?: string;
	webViewIncludeAuth?: boolean;
	webViewUrl?: string;
}

export interface IVenueCustomService extends IVenueService {
	id: string;
	name?: string;
	customConfig: IVenueCustomServiceConfig;
}

export interface IVenueServices {
	clickAndCollect?: IVenueService;
	tableService?: IVenueService;
	delivery?: IVenueService;
	selfService?: IVenueService;
	customServices?: IVenueCustomService[];
}

export interface IVenueOpeningTime {
	day: number;
	name: string;
	openingTime: string;
	closingTime: string;
}

export interface IBrandColour {
	id?: string;
	bgPrimary: string;
	bgSecondary: string;
	lblPrimary: string;
	lblSecondary: string;
	actPrimary: string;
	actSecondary: string;
	actLblPrimary: string;
	actLblSecondary: string;
	brandId?: string;
	logoTint?: string | null;
}

export interface IBrandFont {
	family: string;
	url: string;
	sizes: {
		h1: string | number;
		h2: string | number;
		h3: string | number;
		text: string | number;
		highlights: string | number;
		smallText: string | number;
	};
}

export interface IVenueBrandAssets {
	logoUrl: string;
	colours: IBrandColour;
	font: IBrandFont;
	useLogoBackground: boolean;
}

export interface IVenueGeoFence {
	radius: number;
	point: {
		long: number;
		lat: number;
	};
}

export interface IIBeacon {
	uuid: string;
	major: number;
	minor: number;
}

export interface IVenueBeacons {
	threshold?: IIBeacon[];
	payLock?: IIBeacon | null;
}

export interface IVenueAddress {
	line1: string;
	line2: string;
	city: string;
	postcode: string;
	country: string;
}

export interface IVenueLocation {
	address: IVenueAddress;
	geoFence: IVenueGeoFence;
	beacons: IVenueBeacons;
}

export interface IVenueConfig {
	ageRestrictions: {
		showAlert: boolean;
		showIcons: boolean;
	};
	askForAngelaEnabled: boolean;
	socialDistancing: boolean;
	numericTables: boolean;
	paymentTypes: string[];
	campaignEventName?: string | null;
	promoNotice: boolean;
	notices: {
		allergen?: {
			url?: string | null;
			active: boolean;
		};
		alcohol?: {
			url?: string | null;
			active: boolean;
		};
	};
	showDiscountsAndPromoBanner: boolean;
	featuresEnabled?: string[];
	showCalorificInformation?: boolean;
}

export interface IVenue {
	id: string;
	name: string;
	description: string;
	isOpen: boolean;
	location: IVenueLocation;
	brand: IVenueBrandAssets;
	coverUrls: string[];
	distance?: number;
	openingTimes?: IVenueOpeningTime[];
	services?: IVenueServices;
	brandId?: string;
	contactDetails?: IVenueContactDetails;
	vatNo?: string | null;
	paymentIdentifier?: {
		ccc?: string | null;
	};
	paymentConfig?: {
		ccc?: string | null;
		paymentTypes?: string[];
		provider?: 'stripe' | null;
		stripe?: {} | null;
	};
	config?: IVenueConfig;
	discountCardsEnabled?: boolean;
	integrations?: IIntegrations;
}

export interface ITimeslot {
	collectionTime: Date;
	cutOffTime: Date;
	isAvailable: boolean;
}

export interface IVenueTable {
	id: number;
	number: string;
	display: string;
}

export interface IVenueListFilters {
	lat?: number;
	long?: number;
	pageNumber?: number;
	pageSize?: number;
	brandId?: string;
	sortBy?: 'name' | 'distance';
	sortOrder?: 'ASC' | 'DESC';
}

export interface IVenueMarketing {
	showOptIn: boolean;
	privacyUrl: string;
	message?: string;
}

export interface IIntegrations {
	showFeedItBackBanner: boolean;
}

export interface IFeedItBackResponse {
	id: string;
	orderId: string;
	venueId: string;
	marketingIntegrationType: string;
	url: string;
}

/** STATE TYPES */

export interface IVenueState {
	eventsInProgress: number;
	pagination: IPagination;
	activeVenue?: IVenue;
	activeVenueMarketing?: IVenueMarketing;
	venues: IVenue[];
	timeslots: ITimeslot[];
	tableNumbers: IVenueTable[];
	isPaymentOptionPickerVisible: boolean;
	feeditbackUrl?: IFeedItBackResponse;
}
