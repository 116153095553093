import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IPaymentCard, IPaymentState } from './payment.types';

// Create initial state
export const initialPaymentState: IPaymentState = {
	eventsInProgress: 0,
	pagination: {
		pageSize: 20,
		pageNumber: 1,
		pageCount: 1,
	},
	isPaysheetVisible: false,
	isServiceChargeVisible: false,
	isCardPaymentVisible: false,
	isCardManagementVisible: false,
	isTipVisible: false,
	isPaymentVisible: false,
	isGiftRedemptionVisible: false,
	isRewardRedemptionVisible: false,
	cards: [],
};

const paymentSlice = createSlice({
	name: 'payment',
	initialState: initialPaymentState,
	reducers: {
		RESET_PAYMENT_STATE() {
			return { ...initialPaymentState };
		},
		SET_PAYSHEET_VISIBILITY(state, action) {
			return {
				...state,
				isPaysheetVisible: action.payload,
			};
		},
		SET_SERVICE_CHARGE_VISIBILITY(state, action) {
			return {
				...state,
				isServiceChargeVisible: action.payload,
			};
		},
		SET_CARD_PAYMENT_VISIBILITY(state, action) {
			return {
				...state,
				isCardPaymentVisible: action.payload,
			};
		},
		SET_CARD_MANAGEMENT_VISIBILITY(state, action) {
			return {
				...state,
				isCardManagementVisible: action.payload,
			};
		},
		SET_TIP_VISIBILITY(state, action) {
			return {
				...state,
				isTipVisible: action.payload,
			};
		},
		SET_PAYMENT_VISIBILITY(state, action) {
			return {
				...state,
				isPaymentVisible: action.payload,
			};
		},
		SET_GIFT_REDEMPTION_VISIBILITY(state, action) {
			return {
				...state,
				isGiftRedemptionVisible: action.payload,
			};
		},
		SET_REWARD_REDEMPTION_VISIBILITY(state, action) {
			return {
				...state,
				isRewardRedemptionVisible: action.payload,
			};
		},
		CREATE_PAYMENT_REQUEST(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_PAYMENT_REQUEST_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_PAYMENT_REQUEST_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CARDS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_CARDS_SUCCESS(state, action) {
			// Get cards from payload
			const cards =
				action.payload?.data?.length > 0
					? action.payload.data.filter(
						(card: IPaymentCard) => card.provider === 'stripe',
					  )
					: [];

			// Find default card
			const defaultCard =
				cards.find((card: IPaymentCard) => card.isDefault) ||
				cards[0] ||
				undefined;

			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				cards,
				defaultCard,
				activeCard: defaultCard,
			};
		},
		GET_CARDS_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_CARDS_SILENT(state) {
			return {
				...state,
			};
		},
		GET_CARDS_SILENT_SUCCESS(state, action) {
			// Get cards from payload
			const cards =
				action.payload?.data?.length > 0
					? action.payload.data.filter(
						(card: IPaymentCard) => card.provider === 'stripe',
					  )
					: [];

			// Find default card
			const defaultCard =
				cards.find((card: IPaymentCard) => card.isDefault) ||
				cards[0] ||
				undefined;

			return {
				...state,
				cards,
				defaultCard,
				activeCard: defaultCard,
			};
		},
		GET_CARDS_SILENT_FAIL(state) {
			return {
				...state,
			};
		},
		CREATE_SETUP_INTENT(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_SETUP_INTENT_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SETUP_INTENT_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_ACTIVE_CARD(state, action) {
			return {
				...state,
				activeCard: action.payload,
			};
		},
		SET_DEFAULT_CARD(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		SET_DEFAULT_CARD_SUCCESS(state, action) {
			// Get cards from state and set default
			const cards = state.cards.map((card: IPaymentCard) => {
				return { ...card, isDefault: card.id === action.payload.data.id };
			});

			// Find default card
			const defaultCard = cards.find(
				(card: IPaymentCard) => card.id === action.payload.data.id,
			);

			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				cards,
				defaultCard,
				activeCard: defaultCard,
			};
		},
		SET_DEFAULT_CARD_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		DELETE_CARD(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		DELETE_CARD_SUCCESS(state, action) {
			// Get cards from payload
			const cards = state.cards.filter(
				(card: IPaymentCard) => card.id !== action.payload.data.id,
			);

			// Find default card
			const defaultCard =
				cards.find((card: IPaymentCard) => card.isDefault) ||
				cards[0] ||
				undefined;

			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				cards,
				defaultCard,
				activeCard: defaultCard,
			};
		},
		DELETE_CARD_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		ADD_CARD(state, action) {
			return {
				...state,
				activeCard: action.payload,
				defaultCard: action.payload,
				cards: [...state.cards, action.payload],
			};
		},
		SET_PAYMENT_PROCESSING(state, action) {
			return {
				...state,
				processing: Boolean(action.payload),
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_PAYMENT_STATE,
	SET_PAYSHEET_VISIBILITY,
	SET_SERVICE_CHARGE_VISIBILITY,
	SET_CARD_PAYMENT_VISIBILITY,
	SET_CARD_MANAGEMENT_VISIBILITY,
	SET_TIP_VISIBILITY,
	SET_PAYMENT_VISIBILITY,
	SET_GIFT_REDEMPTION_VISIBILITY,
	SET_REWARD_REDEMPTION_VISIBILITY,
	CREATE_PAYMENT_REQUEST,
	CREATE_PAYMENT_REQUEST_FAIL,
	CREATE_PAYMENT_REQUEST_SUCCESS,
	GET_CARDS,
	GET_CARDS_FAIL,
	GET_CARDS_SUCCESS,
	GET_CARDS_SILENT,
	GET_CARDS_SILENT_SUCCESS,
	GET_CARDS_SILENT_FAIL,
	CREATE_SETUP_INTENT,
	CREATE_SETUP_INTENT_FAIL,
	CREATE_SETUP_INTENT_SUCCESS,
	SET_ACTIVE_CARD,
	SET_DEFAULT_CARD,
	SET_DEFAULT_CARD_FAIL,
	SET_DEFAULT_CARD_SUCCESS,
	DELETE_CARD,
	DELETE_CARD_FAIL,
	DELETE_CARD_SUCCESS,
	ADD_CARD,
	SET_PAYMENT_PROCESSING,
} = paymentSlice.actions;

/** Reset state */
export const resetPaymentState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_PAYMENT_STATE());
};

/** Show/hide service charge */
export const setServiceChargeVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_SERVICE_CHARGE_VISIBILITY(visible));
};

/** Show/hide paysheet */
export const setPaysheetVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PAYSHEET_VISIBILITY(visible));
};

/** Show/hide card payment */
export const setCardPaymentVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_CARD_PAYMENT_VISIBILITY(visible));
};

/** Show/hide card management */
export const setCardManagementVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_CARD_MANAGEMENT_VISIBILITY(visible));
};

/** Show/hide tip */
export const setTipVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_TIP_VISIBILITY(visible));
};

/** Show/hide payment bottom sheet */
export const setPaymentVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PAYMENT_VISIBILITY(visible));
};

/** Show/hide gift redemption screen */
export const setGiftRedemptionVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_GIFT_REDEMPTION_VISIBILITY(visible));
};

/** Show/hide reward redemption screen */
export const setRewardRedemptionVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_REWARD_REDEMPTION_VISIBILITY(visible));
};

/** Create a stripe payment request */
export const createPaymentRequest = (
	basketId: string,
	paymentMethodId: string,
	tipAmount?: number,
	userEmail?: string,
	amount?: number,
) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		CREATE_PAYMENT_REQUEST({
			request: {
				method: 'post',
				url: `1/payment/payment-request/basket/${basketId}`,
				data: {
					paymentMethodId,
					tipAmount,
					userEmail,
					amount,
				},
				timeout: process.env.REACT_APP_POS_TIMEOUT,
			},
		}),
	);

	return response.payload?.data;
};

/** Create a stripe setup intent */
export const createSetupIntent = () => async (dispatch: Dispatch) => {
	const response = await dispatch(
		CREATE_SETUP_INTENT({
			request: {
				method: 'get',
				url: '1/payment/stripe/setup-intent',
			},
		}),
	);

	return response.payload?.data;
};

/** Get cards for the active user */
export const getSavedCards = (silent = false) => async (dispatch: Dispatch) => {
	const GET_CARDS_ACTION = silent ? GET_CARDS_SILENT : GET_CARDS;
	const response = await dispatch(
		GET_CARDS_ACTION({
			request: {
				method: 'get',
				url: '1/me/cards',
			},
		}),
	);
	return response.payload?.data;
};

/** Set user's active card for payment */
export const setActiveCard = (card: IPaymentCard) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_ACTIVE_CARD(card));
};

/** Set default card for user */
export const setDefaultCard = (cardId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		SET_DEFAULT_CARD({
			request: {
				method: 'post',
				url: `1/me/cards/${cardId}/default`,
			},
		}),
	);
	return response.payload?.data;
};

/** Delete a user's card */
export const deleteCard = (cardId: string) => async (dispatch: Dispatch) => {
	const response = await dispatch(
		DELETE_CARD({
			request: {
				method: 'delete',
				url: `1/me/cards/${cardId}`,
			},
		}),
	);

	return response.payload?.data;
};

/** Add a new card to the store */
export const addCard = (card: IPaymentCard) => async (dispatch: Dispatch) => {
	await dispatch(ADD_CARD(card));
};

/** Sets the payment processing status */
export const setPaymentProcessing = (processing: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PAYMENT_PROCESSING(processing));
};

export default paymentSlice.reducer;
