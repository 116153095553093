import { Severity } from '@sentry/react';
import React, { FunctionComponent, useEffect } from 'react';

import I18nProvider from '../i18n/i18n-provider.component';
import { getWithExpiry, setWithExpiry } from '../state/helpers/storage.helper';
import errorLogger from './helpers/error-logger.helper';

import NoResults, {
	INoResultsButtonConfig,
} from 'components/no-results/no-results.component';

interface IComponentProps {
	error: Error;
}
const ErrorFallback: FunctionComponent<IComponentProps> = ({ error }) => {
	useEffect(() => {
		if (error) {
			if (error.message && isChunkFailedError(error.message)) {
				handleChunkFailed();
				return;
			}
			errorLogger(error, Severity.Error);
		}
	}, [error]);

	/** Check if this is a chunk load error */
	const isChunkFailedError = (errorMessage: string) => {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		return chunkFailedMessage.test(errorMessage);
	};

	/** Reload the page (and make sure we don't get stuck in a reload loop) */
	const handleChunkFailed = () => {
		if (!getWithExpiry('chunk_failed')) {
			setWithExpiry('chunk_failed', 'true', 60000);
			window.location.reload();
		}
	};

	const noResultsButtons: INoResultsButtonConfig[] = [
		{
			locale: 'button.reloadPage',
			onClick: () => window.location.reload(),
		},
	];
	return (
		<I18nProvider locale="en">
			<NoResults
				titleLocale="errors.exception.title"
				fullHeight={true}
				buttons={noResultsButtons}
				content={<pre>{error?.message}</pre>}
			/>
		</I18nProvider>
	);
};

export default ErrorFallback;
