import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IDiscountState, ITastecardEvent } from './discount.types';

export const initialDiscountState: IDiscountState = {
	discountSheetVisible: false,
	eventId: '',
	promoId: '',
};

const discountSlice = createSlice({
	name: 'discount',
	initialState: initialDiscountState,
	reducers: {
		SET_DISCOUNT_SHEET_VISIBLE(
			state: IDiscountState,
			action: { payload: boolean },
		) {
			return {
				...state,
				discountSheetVisible: Boolean(action.payload),
			};
		},
		SET_EVENT_DATA(
			state: IDiscountState,
			action: { payload: ITastecardEvent },
		) {
			return {
				...state,
				eventId: action.payload.eventId,
				promoId: action.payload.promoId,
			};
		},
		RESET_DISCOUNT_STATE() {
			return { ...initialDiscountState };
		},
	},
});

export const { SET_DISCOUNT_SHEET_VISIBLE, SET_EVENT_DATA, RESET_DISCOUNT_STATE } = discountSlice.actions;

/**
 * Opens and closes the discount bottom sheet
 */
export const setDiscountSheetVisibility = (val: boolean) => (
	dispatch: Dispatch,
) => {
	dispatch(SET_DISCOUNT_SHEET_VISIBLE(val));
};

/**
 * Store the event and promo ids
 */
export const setEventData = (eventId: string, promoId: string) => (
	dispatch: Dispatch,
) => {
	dispatch(SET_EVENT_DATA({ eventId, promoId }));
};

/** Reset state */
export const resetDiscountState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_DISCOUNT_STATE());
};


export default discountSlice.reducer;
