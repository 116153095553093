import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IAppPreferencesState } from './app-preferences.types';

export const initialAppPreferencesState: IAppPreferencesState = {
	showCalorieInfo: true,
};

const appPreferencesSlice = createSlice({
	name: 'appPreferences',
	initialState: initialAppPreferencesState,
	reducers: {
		SET_SHOW_CALORIE_INFO(state, action) {
			return {
				showCalorieInfo: action.payload,
			};
		},
	},
});

export const { SET_SHOW_CALORIE_INFO } = appPreferencesSlice.actions;

export const setShowCalorieInfo = (visible: boolean) => (
	dispatch: Dispatch,
) => {
	dispatch(SET_SHOW_CALORIE_INFO(visible));
};

export default appPreferencesSlice.reducer;
