import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IVouchersApiState, IVoucherValidation } from './vouchers.types';

import { TServiceType } from 'modules/basket/basket.types';

export const initialVouchersApiState: IVouchersApiState = {
	eventsInProgress: 0,
	voucher: undefined,
};

const vouchersApiSlice = createSlice({
	name: 'vouchersApi',
	initialState: initialVouchersApiState,
	reducers: {
		CHECK_VOUCHER(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CHECK_VOUCHER_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				voucher: action.payload.data,
			};
		},
		CHECK_VOUCHER_FAIL(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				voucher: undefined,
			};
		},
	},
});

export const { CHECK_VOUCHER } = vouchersApiSlice.actions;

/**
 * Sends a request to the backend to check if a voucher is valid or not
 */
export const checkVoucherCodeIsValid = ({
	venueId,
	serviceType,
	voucherCode,
}: {
	venueId: string;
	serviceType: TServiceType;
	voucherCode: string;
}) => async (dispatch: Dispatch): Promise<IVoucherValidation | null> => {
	const response = await dispatch(
		CHECK_VOUCHER({
			request: {
				method: 'get',
				url: `/1/venues/${venueId}/${serviceType}/check-voucher?voucherCode=${voucherCode}`,
			},
		}),
	);
	if (response.payload?.status !== 200) return null;
	return response.payload.data as IVoucherValidation;
};

export default vouchersApiSlice.reducer;
