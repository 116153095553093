import { createIntlCache, createIntl } from 'react-intl';

import localeEN from './locale-en.json';

import { TLocale } from 'app.types';

export const localeMessages = {
	en: localeEN,
};

const i18nConfig = (locale: TLocale) => ({
	locale,
	messages: localeMessages[locale],
});

// Create a cache for react-intl plugin
const cache = createIntlCache();

// Create instance of react-intl + export it
export const intl = createIntl(i18nConfig('en'), cache);

export default i18nConfig;
