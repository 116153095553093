import { INavigationGroup, INavigationItem } from '../navigation.types';

import { IActiveBasket } from 'modules/basket/basket.types';
import { IActivePayGoOrder } from 'modules/pay-go/pay-go.types';

/** Checks the active basket to see if it should be shown */
const showBackButton = (activeBasket?: IActiveBasket): boolean =>
	// If no active basket with a venueId, then nowerhere to go back to
	!!activeBasket?.venueId &&
	// If active basket is Pay and Go, then we shouldn't go back via navigation
	activeBasket.paymentOption !== 'PayAndGo';

/** Checks if you have an active P&G order */
const showBackToBill = (
	activeBasket?: IActiveBasket,
	activePayGoOrder?: IActivePayGoOrder,
): boolean =>
	// If no active basket with a venueId, then nowerhere to go back to
	!!activeBasket?.venueId &&
	// If active basket is Pay and Go, and we have a POS id
	activeBasket.paymentOption === 'PayAndGo' &&
	!!activePayGoOrder?.posId;

interface IArgs {
	activeBasket?: IActiveBasket;
	activePayGoOrder?: IActivePayGoOrder;
	loggedIn: boolean;
	signOut: () => void;
	pathname?: string;
}

/**
 * Builds the tree of data for the apps main navigation.
 */
export default function buildNavTree({
	activeBasket,
	activePayGoOrder,
	pathname,
	loggedIn,
	signOut,
}: IArgs): INavigationGroup[] {
	const backBtn: INavigationItem | null = showBackButton(activeBasket)
		? {
			id: 'back',
			messageId: 'navigation.backToOrder',
			url: `/menu/${activeBasket!.venueId}`, // activeBasket checked in showBackButton method
			icon: 'navBell',
		  }
		: null;

	const backToBill: INavigationItem | null = showBackToBill(
		activeBasket,
		activePayGoOrder,
	)
		? {
			id: 'back',
			messageId: 'navigation.backToBill',
			url: `/venue/${activeBasket!.venueId}/pay-go/order/${
				activePayGoOrder!.posId
			}`, // activeBasket and activePayGoOrder checked in showBackToBill method
			icon: 'navBell',
		  }
		: null;

	const searchBtn: INavigationItem = {
		id: 'search',
		messageId: 'navigation.findVenue',
		url: '/venues/?autofocus=true',
		icon: 'search',
	};

	if (!loggedIn) {
		return [
			{
				id: 'top-group',
				items: [backBtn, backToBill, searchBtn].filter(
					(item): item is INavigationItem => !!item,
				),
			},
		];
	}

	const ordersBtn: INavigationItem = {
		id: 'orders',
		messageId: 'navigation.orders',
		icon: 'navOrders',
		url: '/order-history/',
	};

	const editProfileBtn: INavigationItem = {
		id: 'edit',
		messageId: 'navigation.profile',
		icon: 'navProfile',
		url: '/profile/edit/',
	};

	const allergensBtn: INavigationItem = {
		id: 'allergens',
		messageId: 'navigation.allergensAndDietary',
		icon: 'navAllergens',
		url: '/profile/allergens/',
	};

	const signOutBtn: INavigationItem = {
		id: 'sign-out',
		messageId: 'navigation.signOut',
		icon: 'navLogOut',
		onClick: signOut,
		// Do not immediately close the nav menu so the confirmation message popup
		// is less jarring
		preventCloseOnClick: true,
	};

	return [
		{
			id: 'top-group',
			items: [backBtn, backToBill, searchBtn, ordersBtn].filter(
				(item): item is INavigationItem => !!item,
			),
		},
		{
			id: 'account-group',
			groupMessageId: 'navigation.heading.account',
			items: [editProfileBtn, allergensBtn, signOutBtn],
		},
	];
}
