import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { openNav } from './navigation.slice';

import Icon from 'components/icons/icon.component';
import { isInAndroidWebview } from 'helpers/device.helper';
import { RootState } from 'modules/core/state/root.reducer';

const StyledNavButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 42px;
	width: 42px;
	color: inherit;
	background: none;
	cursor: pointer;
	border: none;
	border-radius: 0;
	svg {
		color: inherit;
	}
`;

const NavButton: FunctionComponent<{ className?: string }> = ({
	className,
}) => {
	const dispatch = useDispatch();

	// Get tastecard promo id from state
	const { promoId } = useSelector((state: RootState) => state.discount);

	function open() {
		dispatch(openNav());
	}

	if (isInAndroidWebview()) return null;

	return !promoId ? (
		<StyledNavButton
			className={className}
			onClick={open}
			type="button"
			aria-label="Open menu"
		>
			<Icon name="hamburger" width={18} height={18} />
		</StyledNavButton>
	) : null;
};

export default NavButton;
