/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import { captureEvent, Severity } from '@sentry/react';

import { tracker } from 'modules/core/logging/initialise-logs.helper';

/** Log events to sentry and track in openreplay */
const eventLogger = (
	error: {
		message: string;
		level?: Severity;
		extra: any;
	},
	captureSentry = true,
) => {
	switch (error.level) {
	case Severity.Info:
	case Severity.Log: {
		console.log(error);
		break;
	}
	default: {
		console.error(error);
	}
	}
	// If we have sentry config
	if (process.env.REACT_APP_SENTRY_DSN && captureSentry) {
		// Log error in sentry
		captureEvent(error);
	}
	// Track error in OpenReplay
	tracker.event('error', error);
};

export default eventLogger;
