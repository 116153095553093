import { History } from 'history';

import { ReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { getActiveBasket, resetBasketState } from 'modules/basket/basket.slice';
import { IBasket, IOrderItem } from 'modules/basket/basket.types';
import confirmBasketWipe from 'modules/basket/helpers/confirm-wipe.helper';
import { resetCheckoutState } from 'modules/checkout/checkout.slice';
import { intl } from 'modules/core/i18n/i18n.config';
import {
	returnToActiveOnplOrder,
	hasActiveOnplOrder,
} from 'modules/order/helpers/onpl.helper';
import { resetOrderState } from 'modules/order/order.slice';
import { IVenue } from 'modules/venue/venue.types';

/** Get user's active basket and return user to their order */
const handleActiveOnplOrder = async (
	dispatch: ReduxDispatch,
	history: History,
	basket: IBasket,
) => {
	await dispatch(getActiveBasket());
	await returnToActiveOnplOrder(intl, basket, history, dispatch, false);
};

/** Check if user has an active session and return them to it */
export const handleExistingSession = async (
	dispatch: ReduxDispatch,
	history: History,
	basketItems: IOrderItem[],
	fullBasket?: IBasket,
	activeVenue?: IVenue,
	basketVenue?: IVenue,
): Promise<boolean> => {
	// If user has an active ONPL basket - return them to it
	if (fullBasket && hasActiveOnplOrder(fullBasket)) {
		await handleActiveOnplOrder(dispatch, history, fullBasket);
		return Promise.resolve(true);
	}
	// If user has an existing basket (not ONPL)
	if (activeVenue && basketItems.length > 0) {
		// Check if the user wants to wipe the basket
		const wipeBasket = await confirmBasketWipe(
			intl,
			dispatch,
			activeVenue,
			basketVenue,
		);
		if (!wipeBasket) {
			// Return user to menu for their active basket
			basketVenue && history.push(`/menu/${basketVenue.id}`);
			return Promise.resolve(true);
		}
	}

	// Reset states
	dispatch(resetOrderState());
	dispatch(resetBasketState());
	dispatch(resetCheckoutState());

	return Promise.resolve(false);
};
