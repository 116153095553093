import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IOrderHistoryState, IVisit } from './order-history.types';

export const initialOrderHistoryState: IOrderHistoryState = {
	pageNumber: 0,
	visits: [],
	hasMore: true,
};

const orderHistorySlice = createSlice({
	name: 'orderHistory',
	initialState: initialOrderHistoryState,
	reducers: {
		SET_HAS_MORE(state, action) {
			return {
				...state,
				hasMore: action.payload,
			};
		},
		SET_PAGE_NUMBER(state, action) {
			return {
				...state,
				pageNumber: action.payload,
			};
		},
		ADD_VISITS(state, action) {
			// Turn previous and new into objects
			const prevVisits = Object.fromEntries(
				state.visits.map((visit) => [visit.id, visit]),
			);
			const newVisits = Object.fromEntries(
				action.payload.map((visit: IVisit) => [visit.id, visit]),
			);
			// Combine them together. Prevents duplicates
			const combined = {
				...prevVisits,
				...newVisits,
			};
			return {
				...state,
				// Turn the combined object back into an array of visits
				visits: Object.values(combined),
			};
		},
		SET_ACTIVE_VISIT(state, action) {
			return {
				...state,
				activeVisit: action.payload,
			};
		},
		RESET_ORDER_HISTORY_STATE(state) {
			return {
				...initialOrderHistoryState,
			};
		},
	},
});

export const {
	SET_HAS_MORE,
	SET_PAGE_NUMBER,
	ADD_VISITS,
	SET_ACTIVE_VISIT,
	RESET_ORDER_HISTORY_STATE,
} = orderHistorySlice.actions;

/** Thunk to set the page number in the state */
export const setPageNumber = (num: number) => (dispatch: Dispatch) =>
	dispatch(SET_PAGE_NUMBER(num));

/** Thunk to set hasMore in the state */
export const setHasMore = (val: boolean) => (dispatch: Dispatch) =>
	dispatch(SET_HAS_MORE(val));

/** Thunk to adds visits to the state */
export const addVisits = (visits: IVisit[]) => (dispatch: Dispatch) =>
	dispatch(ADD_VISITS(visits));

/** Sets the active visit id in the state */
export const setActiveVisit = (visit?: IVisit) => async (dispatch: Dispatch) =>
	dispatch(SET_ACTIVE_VISIT(visit));

/** Resets the order history state */
export const resetOrderHistoryState = () => async (dispatch: Dispatch) =>
	dispatch(RESET_ORDER_HISTORY_STATE());

export default orderHistorySlice.reducer;
