import { TEcommerceItem } from '../analytics.types';
import priceToEcommercePrice from './price-to-ecommerce-price.helper';

import { IProduct, ISuggestedProduct } from 'modules/product/product.types';

/**
 * Converts a IProduct or ISuggested product item to a google analytics ecommerce item
 * Also accepts an optional menuID, whch should match an ecommerce item list
 */
export default function productToEcommerceItem(
	product: IProduct | ISuggestedProduct,
	menuId?: string,
): TEcommerceItem {
	const price = priceToEcommercePrice(product.price);
	return {
		...price,
		item_id: product.id,
		item_name: product.name,
		quantity: product.quantity,
		item_list_id: menuId,
	};
}
