import { createTransform } from 'redux-persist';

/** Reset `currentCategoryId` when rehydrating store */
export const CurrentCategoryTransformer = createTransform(
	// Transform state being rehydrated
	(outboundState: { currentCategoryId: string | null }) => {
		if (outboundState.currentCategoryId) {
			return { ...outboundState, currentCategoryId: null };
		}
		return outboundState;
	},
);
