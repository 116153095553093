import { IEcommerceItemList, TEcommerceItem } from '../analytics.types';
import productToEcommerceItem from './product-to-ecommerce-item.helper';

import { IMenu } from 'modules/menu/menu.types';

/**
 * Converts an IMenu object to a google analytics item list object
 */
export default function menuToEcommerceItemList(
	menu: IMenu,
): IEcommerceItemList {
	const items = menu.menuCategories.reduce((prev, category) => {
		const nextCategoryItems = category.products.map((product) =>
			productToEcommerceItem(product),
		);
		return [...prev, ...nextCategoryItems];
	}, [] as TEcommerceItem[]);

	return {
		item_list_id: menu.title,
		item_list_name: menu.id,
		items,
	};
}
