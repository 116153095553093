import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
	clearFeatureToggles,
	setFeatureToggles,
} from './feature-toggles.slice';

const FeatureToggles: FunctionComponent = () => {
	const { search } = useLocation();

	const dispatch = useDispatch();

	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		if (searchParams.has('feature-reset')) {
			dispatch(clearFeatureToggles());
			return;
		}
		const features = searchParams.getAll('feature');
		if (features.length > 0) {
			dispatch(setFeatureToggles(features));
		}
	}, [search, dispatch]);

	return null;
};

export default FeatureToggles;
