import { Severity } from '@sentry/react';
import { SweetAlertOptions } from 'sweetalert2';

import { fireDialog } from 'modules/core/dialog/dialog.service';
import errorLogger from 'modules/core/error/helpers/error-logger.helper';

interface IArgs {
	error: Error;
	errorLevel?: Severity;
	dialog?: Partial<SweetAlertOptions>;
	message?: string;
}

/**
 * Generic error thrown by web ordering application
 * handled wih optional logging and presenting the user with an error dialog
 */
export default class GenericError extends Error {
	private error: Error;

	private errorLevel: Severity;

	private dialog?: Partial<SweetAlertOptions>;

	constructor({
		error,
		errorLevel = Severity.Warning,
		dialog,
		message,
	}: IArgs) {
		super(message || error.message);
		this.error = error;
		this.errorLevel = errorLevel;
		this.dialog = dialog;
	}

	/**
	 * Logs the error and displays a dialog to the user if this.dialog is set
	 */
	logAndShowDialog(errorLevelOverwrite?: Severity): void {
		errorLogger(this.error, errorLevelOverwrite ?? this.errorLevel);
		if (this.dialog) {
			fireDialog(this.dialog);
		}
	}
}
