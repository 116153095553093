import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { ISubscriptionState } from './subscription.types';

// Create initial state
export const initialSubscriptionState: ISubscriptionState = {
	eventsInProgress: 0,
	isPaysheetVisible: false,
	isPaymentVisible: false,
};

const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState: initialSubscriptionState,
	reducers: {
		GET_SUBSCRIPTION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_SUBSCRIPTION_SUCCESS(state, action) {
			return {
				...state,
				activeSubscription: action.payload?.data,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_SUBSCRIPTION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SUBSCRIPTION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CREATE_SUBSCRIPTION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CREATE_SUBSCRIPTION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		RESET_SUBSCRIPTION_STATE(state) {
			return {
				...initialSubscriptionState,
			};
		},
		SET_PAYSHEET_VISIBILITY(state, action) {
			return {
				...state,
				isPaysheetVisible: action.payload,
			};
		},
		SET_PAYMENT_VISIBILITY(state, action) {
			return {
				...state,
				isPaymentVisible: action.payload,
			};
		},
		CANCEL_SUBSCRIPTION(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		CANCEL_SUBSCRIPTION_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		CANCEL_SUBSCRIPTION_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	GET_SUBSCRIPTION,
	GET_SUBSCRIPTION_FAIL,
	GET_SUBSCRIPTION_SUCCESS,
	RESET_SUBSCRIPTION_STATE,
	SET_PAYSHEET_VISIBILITY,
	SET_PAYMENT_VISIBILITY,
	CREATE_SUBSCRIPTION,
	CREATE_SUBSCRIPTION_FAIL,
	CREATE_SUBSCRIPTION_SUCCESS,
	CANCEL_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION_FAIL,
	CANCEL_SUBSCRIPTION_SUCCESS,
} = subscriptionSlice.actions;

/** Reset state */
export const resetSubscriptionState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_SUBSCRIPTION_STATE());
};

/** Get subscription */
export const getSubscription = (brandId: string) => async (
	dispatch: Dispatch,
) => {
	// Create request
	await dispatch(
		GET_SUBSCRIPTION({
			request: {
				method: 'get',
				url: `1/subscription/brand/${brandId}`,
			},
		}),
	);
};

/** Create subscription */
export const createSubscription = (
	subscriptionId: string,
	paymentMethodId: string,
) => async (dispatch: Dispatch) => {
	// Create request
	const response = await dispatch(
		CREATE_SUBSCRIPTION({
			request: {
				method: 'post',
				url: `1/subscription/${subscriptionId}`,
				data: { stripePaymentMethodId: paymentMethodId },
			},
		}),
	);

	return response.payload?.status === 200 ? response.payload?.data : false;
};

/** Show/hide paysheet */
export const setSubscriptionPaysheetVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PAYSHEET_VISIBILITY(visible));
};

/** Show/hide payment bottom sheet */
export const setSubscriptionPaymentVisibility = (visible: boolean) => async (
	dispatch: Dispatch,
) => {
	await dispatch(SET_PAYMENT_VISIBILITY(visible));
};

/** Cancel subscription */
export const cancelSubscription = (subscriptionId: string) => async (
	dispatch: Dispatch,
) => {
	const response = await dispatch(
		CANCEL_SUBSCRIPTION({
			request: {
				method: 'delete',
				url: `1/subscription/${subscriptionId}`,
			},
		}),
	);

	return response.payload?.status === 200 ? response.payload?.data : false;
};

export default subscriptionSlice.reducer;
