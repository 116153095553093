import React, { FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import styled from 'styled-components';
import { ModalProvider } from 'styled-react-modal';

import store, { persistor } from './modules/core/state/store.config';

import brand from 'assets/styles/variables/brand';
import structure from 'assets/styles/variables/structure';
import { BottomSheetProvider } from 'components/bottom-sheet/bottom-sheet-provider.component';
import { modalBackground } from 'components/modal/modal.component';
import Toasts from 'components/toast/toast.component';
import useNetworkDiagnoistics from 'hooks/use-network-diagnoistics.hook';
import AskForAngelaDetails from 'modules/ask-for-angela/ask-for-angela-details/ask-for-angela-details.component';
import ErrorFallback from 'modules/core/error/error-fallback.component';
import DefaultTheme from 'modules/core/theme/default-theme-provider.component';
import WindowProvider from 'modules/core/window/window.provider';

const AppRouter = lazy(
	() => import('./modules/core/routing/app-router.component'),
);
const BrandProvider = lazy(
	() => import('modules/brand/brand-provider.component'),
);
const Dialog = lazy(() => import('modules/core/dialog/dialog.component'));
const I18nProvider = lazy(
	() => import('./modules/core/i18n/i18n-provider.component'),
);
const PusherComponent = lazy(
	() => import('modules/core/pusher/pusher.component'),
);
const StoreVersion = lazy(
	() => import('modules/core/state/store-version/store-version.component'),
);
const Theme = lazy(() => import('modules/core/theme/theme-provider.component'));
const PayGoProvider = lazy(() => import('modules/pay-go/pay-go-provider.component'));

const StyledAppWrapper = styled.div`
	width: 100%;
	max-width: ${structure.container.maxWidth};
	margin: 0 auto;
	background: ${brand.white};
	position: relative;
`;

/** Renders app component */
const App: FunctionComponent = () => {
	useEffect(() => {
		if (!window.androidWebview) {
			window.androidWebview = {};
		}
	}, []);
	useNetworkDiagnoistics();
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<Suspense fallback={null}>
				<Provider store={store}>
					<HelmetProvider>
						<PersistGate loading={null} persistor={persistor}>
							<StoreVersion />
							<I18nProvider locale="en">
								<Theme>
									<ModalProvider backgroundComponent={modalBackground}>
										<BottomSheetProvider>
											<PusherComponent>
												<PayGoProvider>
													<StyledAppWrapper>
														<WindowProvider />
														<BrandProvider>
															<Toasts />
															<AppRouter />
															<AskForAngelaDetails />
														</BrandProvider>
													</StyledAppWrapper>
												</PayGoProvider>
											</PusherComponent>
										</BottomSheetProvider>
									</ModalProvider>
								</Theme>
								<DefaultTheme>
									<Dialog />
								</DefaultTheme>
							</I18nProvider>
						</PersistGate>
					</HelmetProvider>
				</Provider>
			</Suspense>
		</ErrorBoundary>
	);
};

export default App;
