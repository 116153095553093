import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import AskForAngelaBase from '../ask-for-angela-base/ask-for-angela-base.component';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { ReactComponent as Logo } from 'assets/svg/ask-for-angela/ask-for-angela-logo.svg';
import gradientLarge from 'assets/svg/ask-for-angela/gradient-large.svg';
import gradientSmall from 'assets/svg/ask-for-angela/gradient-small.svg';
import { ReactComponent as Person } from 'assets/svg/ask-for-angela/person.svg';
import { ReactComponent as SpeachBubbles } from 'assets/svg/ask-for-angela/speach-bubbles.svg';
import TrackImpression from 'modules/core/analytics/components/track-impression.component';
import { ECustomEvent } from 'modules/core/analytics/events/custom.event';

const StyledLogo = styled(Logo)``;

const StyledText = styled.span`
	position: relative;
	z-index: 3;
`;

const StyledSpeachBubbles = styled(SpeachBubbles)`
	position: absolute;
	z-index: 1;
`;

const StyledPerson = styled(Person)`
	width: 90px;
	height: 78px;
	right: 10px;
	bottom: -6px;
	position: absolute;
	z-index: 2;
`;

const StyledButton = styled(AskForAngelaBase)<{
	slim?: boolean;
}>`
	position: relative;
	display: flex;
	background-position: right -1px center;
	background-size: auto 100%;
	background-color: white;
	background-repeat: no-repeat;
	text-align: left;
	font-weight: ${fonts.weights.light};
	font-size: ${fonts.sizes.small};
	line-height: ${fonts.lineHeight.small};
	cursor: pointer;
	width: 100%;
	z-index: 1;
	${({ slim }) =>
		slim
			? css`
					width: calc(100% - 30px);
					margin: 18px 15px 8px 15px;
					border-radius: 5px;
					flex-direction: row;
					align-items: center;
					min-height: 40px;
					background-image: url(${gradientSmall});
					border: 1px solid ${brand.askForAngelaBorderLighter};
					padding: 12px 15px;
					line-height: 1;
					${StyledLogo} {
						height: 11px;
						width: 80px;
						margin-right: 8px;
						margin-bottom: 2px;
					}
					${StyledSpeachBubbles} {
						width: 36px;
						height: 31px;
						bottom: 1px;
						right: 12px;
						opacity: 0.7;
					}
			  `
			: css`
					min-height: 80px;
					flex-direction: column;
					border-radius: 10px;
					border: 1px solid ${brand.askForAngelaBorder};
					background-image: url(${gradientLarge});
					padding: 16px 70px 12px 15px;
					strong {
						font-weight: ${fonts.weights.semibold};
					}
					${StyledLogo} {
						height: 13px;
						width: 94px;
						margin-bottom: 8px;
					}
					${StyledSpeachBubbles} {
						width: 56px;
						height: 38px;
						bottom: 36px;
						right: 55px;
					}
			  `}
`;

interface IComponentProps {
	slim?: boolean;
	onVenue?: boolean;
}

const AskForAngelaBanner: FunctionComponent<IComponentProps> = ({
	slim,
	onVenue = true,
}) => {
	return (
		<>
			<TrackImpression
				as="div"
				event={ECustomEvent.askForAngelaBannerImpression}
				multiple
			/>
			<StyledButton
				slim={slim}
				testId="ask-for-angela-banner"
				onVenue={onVenue}
			>
				<StyledLogo />
				<StyledText>
					<FormattedMessage
						id={`askForAngela.banner.${slim ? 'slim' : 'full'}.message`}
					/>
				</StyledText>
				<StyledSpeachBubbles />
				{!slim && <StyledPerson />}
			</StyledButton>
		</>
	);
};

export default AskForAngelaBanner;
