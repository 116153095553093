import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { ISplitBillItem, ISplitBillState } from './split-bill.types';

import { IBasketPromotion, IPrice, ITip } from 'modules/basket/basket.types';

// Create initial state
export const initialSplitBillState: ISplitBillState = {
	eventsInProgress: 0,
	isSplitBillModalOpen: false,
	totalAmount: 0,
	amountToPay: 0,
	itemsSplitAmount: 0,
	promotions: [],
	currentSplitAmount: 0,
	confirmedSplitAmount: 0,
	subtotal: 0,
	serviceCharge: 0,
	coverCount: 0,
	items: [],
	splitBillMethod: null,
	splitByPeople: null,
};

const splitBillSlice = createSlice({
	name: 'splitBill',
	initialState: initialSplitBillState,
	reducers: {
		RESET_SPLIT_BILL_STATE(state) {
			return {
				...initialSplitBillState,
				splitByPeople: state.splitByPeople,
			};
		},
		SET_SPLIT_BILL_MODAL_OPEN(state, action) {
			return {
				...state,
				isSplitBillModalOpen: action.payload,
			};
		},
		SET_TOTAL_AMOUNT(state, action) {
			return {
				...state,
				totalAmount: action.payload,
			};
		},
		SET_AMOUNT_TO_PAY(state, action) {
			return {
				...state,
				amountToPay: action.payload,
			};
		},
		SET_TIP(state, action) {
			return {
				...state,
				tip: action.payload,
			};
		},
		SET_SERVICE_CHARGE(state, action) {
			return {
				...state,
				serviceCharge: action.payload,
			};
		},
		SET_CURRENT_SPLIT_AMOUNT(state, action) {
			return {
				...state,
				currentSplitAmount: action.payload,
			};
		},
		SET_CONFIRMED_SPLIT_AMOUNT(state, action) {
			return {
				...state,
				confirmedSplitAmount: action.payload,
			};
		},
		SET_COVER_COUNT(state, action) {
			return {
				...state,
				coverCount: action.payload,
			};
		},
		SET_ITEMS(state, action) {
			return {
				...state,
				items: action.payload,
			};
		},
		SET_CURRENT_SERVICE_CHARGE(state, action) {
			return {
				...state,
				currentServiceCharge: action.payload,
			};
		},
		SET_TILL_ADJUSTMENT(state, action) {
			return {
				...state,
				tillAdjustmentAmount: action.payload,
			};
		},
		SET_DISCOUNT(state, action) {
			return {
				...state,
				discountAmount: action.payload,
			};
		},
		SET_SUBTOTAL(state, action) {
			return {
				...state,
				subtotal: action.payload,
			};
		},
		SET_ITEMS_SPLIT_AMOUNT(state, action) {
			return {
				...state,
				itemsSplitAmount: action.payload,
			};
		},
		SET_PROMOTIONS(state, action) {
			return {
				...state,
				promotions: action.payload,
			};
		},
		SET_SPLIT_BILL_METHOD(
			state,
			action: PayloadAction<ISplitBillState['splitBillMethod']>,
		) {
			return {
				...state,
				splitBillMethod: action.payload,
			};
		},
		SET_SPLIT_BY_PEOPLE(
			state,
			action: PayloadAction<ISplitBillState['splitByPeople']>,
		) {
			return {
				...state,
				splitByPeople: action.payload,
			};
		},
	},
});

// Destructure and export the plain action creators
export const {
	RESET_SPLIT_BILL_STATE,
	SET_SPLIT_BILL_MODAL_OPEN,
	SET_TOTAL_AMOUNT,
	SET_AMOUNT_TO_PAY,
	SET_TIP,
	SET_SERVICE_CHARGE,
	SET_CURRENT_SPLIT_AMOUNT,
	SET_CONFIRMED_SPLIT_AMOUNT,
	SET_COVER_COUNT,
	SET_ITEMS,
	SET_CURRENT_SERVICE_CHARGE,
	SET_TILL_ADJUSTMENT,
	SET_DISCOUNT,
	SET_SUBTOTAL,
	SET_ITEMS_SPLIT_AMOUNT,
	SET_PROMOTIONS,
	SET_SPLIT_BILL_METHOD,
	SET_SPLIT_BY_PEOPLE,
} = splitBillSlice.actions;

/** Reset state */
export const resetSplitBillState = () => async (dispatch: Dispatch) => {
	await dispatch(RESET_SPLIT_BILL_STATE());
};

/** Set split bill modal open */
export const setSplitBillModalOpen = (open: boolean) => async (
	dispatch: Dispatch,
) => dispatch(SET_SPLIT_BILL_MODAL_OPEN(open));

export const setSplitBillTotalAmount = (amount: number) => async (
	dispatch: Dispatch,
) => dispatch(SET_TOTAL_AMOUNT(amount));

export const setSplitBillAmountToPay = (amount: number) => async (
	dispatch: Dispatch,
) => dispatch(SET_AMOUNT_TO_PAY(amount));

export const setSplitBillTip = (tip?: ITip) => async (dispatch: Dispatch) =>
	dispatch(SET_TIP(tip));

export const setSplitBillServiceCharge = (serviceCharge: number) => async (
	dispatch: Dispatch,
) => dispatch(SET_SERVICE_CHARGE(serviceCharge));

export const setCurrentSplitAmount = (splitAmount?: number) => async (
	dispatch: Dispatch,
) => dispatch(SET_CURRENT_SPLIT_AMOUNT(splitAmount));

export const setConfirmedSplitAmount = (splitAmount?: number) => async (
	dispatch: Dispatch,
) => dispatch(SET_CONFIRMED_SPLIT_AMOUNT(splitAmount));

export const setCoverCount = (coverCount?: number) => async (
	dispatch: Dispatch,
) => dispatch(SET_COVER_COUNT(coverCount));

export const setSplitBillItems = (items: ISplitBillItem[]) => async (
	dispatch: Dispatch,
) => dispatch(SET_ITEMS(items));

export const setCurrentServiceCharge = (serviceCharge: IPrice) => (
	dispatch: Dispatch,
) => dispatch(SET_CURRENT_SERVICE_CHARGE(serviceCharge));

export const setTillAdjustment = (tillAdjustment: IPrice) => (
	dispatch: Dispatch,
) => dispatch(SET_TILL_ADJUSTMENT(tillAdjustment));

export const setDiscount = (discount: IPrice) => (dispatch: Dispatch) =>
	dispatch(SET_DISCOUNT(discount));

export const setSubtotal = (subtotal?: number) => (dispatch: Dispatch) =>
	dispatch(SET_SUBTOTAL(subtotal));

export const setItemsSplitAmount = (amount?: number) => (dispatch: Dispatch) =>
	dispatch(SET_ITEMS_SPLIT_AMOUNT(amount));

export const setPromotions = (promotions?: IBasketPromotion[]) => (
	dispatch: Dispatch,
) => dispatch(SET_PROMOTIONS(promotions));

export const setSplitBillMethod = (
	splitBillMethod: ISplitBillState['splitBillMethod'],
) => {
	return (dispatch: Dispatch) => {
		dispatch(SET_SPLIT_BILL_METHOD(splitBillMethod));
	};
};

export const setSplitByPeople = (
	splitByPeople: ISplitBillState['splitByPeople'],
) => {
	return (dispatch: Dispatch) => {
		dispatch(SET_SPLIT_BY_PEOPLE(splitByPeople));
	};
};

export default splitBillSlice.reducer;
