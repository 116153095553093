import { motion } from 'framer-motion';
import { rgba } from 'polished';
import React, { FunctionComponent, ReactNode, ReactChild } from 'react';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import structure from 'assets/styles/variables/structure';
import Button from 'components/button/button.component';
import Container from 'components/container/container.component';
import Icon from 'components/icons/icon.component';
import animations from 'helpers/animations.helper';

const StyledModalWrapper = styled.div<{
	backgroundColour?: string;
}>`
	position: fixed;
	width: 100%;
	height: 100%;
	padding: 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-color: ${({ backgroundColour }) =>
		backgroundColour ?? rgba(brand.black, 0.8)};
	top: 0;
	left: 0;
	z-index: 22;
	${({ backgroundColour }) =>
		backgroundColour === 'transparent'
			? css`
					pointer-events: none;
					${StyledModal} {
						pointer-events: auto;
					}
			  `
			: null}
`;

const StyledModal = styled(motion.div)<{ allowOverflow?: boolean, scrollable?: boolean }>`
	width: 100%;
	max-width: ${structure.container.maxWidth};
	margin: 0 auto;
	border-radius: 15px 15px 0 0;
	display: flex;
	overflow-y: auto;
	overflow-x: hidden;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	background: ${brand.white};
	position: absolute;
	bottom: 0;
	${({ allowOverflow }) =>
		allowOverflow &&
		css`
			overflow: visible;
		`}

	${({ scrollable }) =>
		scrollable &&
		css`
			overflow-y: auto;
			max-height: 100%;
		`}

`;

const StyledHeader = styled.header`
	width: 100%;
	height: 0;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	z-index: 2;

	.btn-close {
		margin: 11px 0 0 auto;
	}
`;

interface IStyledHeroProps {
	heroBorder: boolean;
	heroColour?: string;
	heroImage?: boolean;
}

const StyledHero = styled(motion.section)<IStyledHeroProps>`
	width: 100%;
	padding: ${({ heroImage }) => (heroImage ? '20px 0 0' : '20px 0 15px 0')};
	line-height: ${fonts.lineHeight.med};
	font-weight: ${fonts.weights.regular};
	position: relative;
	background: ${({ heroColour }) => heroColour ?? 'transparent'};

	${({ heroBorder }) =>
		heroBorder && `border-bottom: 1px solid ${brand.borders};`}

	h2 {
		margin-bottom: 5px;
	}

	p {
		color: ${brand.textLight};

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const StyledHeroImage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

interface IComponentProps {
	children: ReactNode;
	handleClose?: Function;
	heroText?: string;
	heroTitle?: string;
	heroImage?: ReactNode;
	heroColour?: string;
	heroBorder?: boolean;
	heroCloseColour?: string;
	hero?: ReactChild;
	backgroundColour?: string;
	allowOverflow?: boolean;
	scrollable?: boolean;
}

/** Renders bottom sheet component */
const BottomSheet: FunctionComponent<IComponentProps> = ({
	handleClose,
	children,
	heroText,
	heroTitle,
	heroBorder,
	heroImage,
	heroColour,
	heroCloseColour,
	hero,
	backgroundColour,
	allowOverflow,
	scrollable,
}) => (
	<StyledModalWrapper backgroundColour={backgroundColour}>
		<StyledModal
			{...animations.fadeInUp}
			transition={{ delay: 0, duration: 0.3 }}
			exit={animations.fadeOutDown.animate}
			test-id="bottom-sheet"
			allowOverflow
			scrollable={scrollable}
		>
			{handleClose && (
				<StyledHeader>
					<Button
						className="btn-close"
						variant="icon"
						onClick={() => handleClose()}
						aria-label="Close"
					>
						<Icon
							name="close"
							width={24}
							height={24}
							colour={heroCloseColour ?? brand.text}
						/>
					</Button>
				</StyledHeader>
			)}
			{hero}
			{!hero && (heroTitle || heroText || heroImage) && (
				<StyledHero
					heroBorder={!!heroBorder}
					heroColour={heroColour}
					heroImage={!!heroImage}
				>
					<Container>
						{!!heroImage && <StyledHeroImage>{heroImage}</StyledHeroImage>}
						{heroTitle && <h2>{heroTitle}</h2>}
						{heroText && <p>{heroText}</p>}
					</Container>
				</StyledHero>
			)}
			{children}
		</StyledModal>
	</StyledModalWrapper>
);

export default BottomSheet;
