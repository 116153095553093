import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
	transform: rotate(90deg);
	animation: spin 2s linear infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

const StyledLine = styled.path`
	animation: lineDraw 2s infinite;

	@keyframes lineDraw {
		0% {
			stroke-dashoffset: -98px;
			stroke-dasharray: 5px 98px;
		}
		10% {
			stroke-dashoffset: -98px;
			stroke-dasharray: 5px 98px;
		}
		55% {
			stroke-dashoffset: 0px;
			stroke-dasharray: 98px 98px;
		}
		100% {
			stroke-dashoffset: 0px;
			stroke-dasharray: 0px 98px;
		}
	}
`;

/** Renders loading animation component */
const LoadingAnimation: FunctionComponent = () => (
	<StyledSVG fill="none" viewBox="0 0 46 46">
		<StyledLine
			className="line"
			stroke="currentColor"
			strokeWidth="3"
			d="M2.2 22.981a20.642 20.642 0 006.095 14.695 20.692 20.692 0 0014.709 6.086 20.694 20.694 0 0014.712-6.087c8.113-8.104 8.112-21.285 0-29.388A20.69 20.69 0 0023.004 2.2"
		/>
	</StyledSVG>
);
export default LoadingAnimation;
