import { IEcommercePrice } from '../analytics.types';

import currenciesPrecision from 'helpers/currencies-precision.data';
import { isValidCurrencyCode } from 'helpers/currency-helpers.helper';
import { IPrice } from 'modules/basket/basket.types';

/**
 * Converts an IPrice object to a Google analtics ecommerce price
 */
export default function priceToEcommercePrice(price: IPrice): IEcommercePrice {
	const currency = isValidCurrencyCode(price.currencyCode)
		? price.currencyCode
		: 'GBP';

	const precision = currenciesPrecision[currency];

	return {
		value: Number((price.units / 10 ** precision).toFixed(precision)),
		currency,
	};
}
