import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IProfileState } from './profile.types';

export const initialProfileState: IProfileState = {
	eventsInProgress: 0,
};

const profileSlice = createSlice({
	name: 'profile',
	initialState: initialProfileState,
	reducers: {
		GET_PREFERENCES(state, action) {
			return { ...state, eventsInProgress: state.eventsInProgress + 1 };
		},
		GET_PREFERENCES_SUCCESS(state, action) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
				preferenceOptions: action.payload.data,
			};
		},
		GET_PREFERENCES_FAIL(state, action) {
			return { ...state, eventsInProgress: state.eventsInProgress - 1 };
		},
	},
});

export const {
	GET_PREFERENCES,
	GET_PREFERENCES_FAIL,
	GET_PREFERENCES_SUCCESS,
} = profileSlice.actions;

/**
 * Gets the preference options from the backend
 */
export const getPreferenceOptions = () => async (dispatch: Dispatch) => {
	const response = await dispatch(
		GET_PREFERENCES({
			request: {
				method: 'get',
				url: '1/preferences',
			},
		}),
	);

	return response.payload?.data;
};

export default profileSlice.reducer;
