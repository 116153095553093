import { IVenueAddress } from '../venue.types';

/** Format venue address  */
const formatVenueAddress = (venueAddress: IVenueAddress) =>
	Object.values(venueAddress)
		// Remove empty address lines
		.filter((line) => line !== '')
		// Join together with a space
		.join(', ');

export default formatVenueAddress;
