/** Font faces */
const fontFaces = `
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-regular-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-regular-webfont.woff'}) format('woff');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-light-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-light-webfont.woff'}) format('woff');
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-lightitalic-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-lightitalic-webfont.woff'}) format('woff');
		font-weight: 300;
		font-style: italic;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-medium-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-medium-webfont.woff'}) format('woff');
		font-weight: 500;
		font-style: normal;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-semibold-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-semibold-webfont.woff'}) format('woff');
		font-weight: 600;
		font-style: normal;
	}
	@font-face {
		font-family: 'Montserrat';
		src: local('Montserrat'), local('Montserrat'),
			url(${'/fonts/montserrat-bold-webfont.woff2'}) format('woff2'),
			url(${'/fonts/montserrat-bold-webfont.woff'}) format('woff');
		font-weight: 700;
		font-style: normal;
	}
`;

export default fontFaces;
