import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSubdomain } from 'tldts';

import { RootState } from 'modules/core/state/root.reducer';
import { setEventData } from 'modules/discounts/discount.slice';

/**
 * Retrieve the 'eventId' URL parameter, which will be comprised of
 * an event and a promo id, in this fashion:
 * eventId_promoId
 *
 * This parameter is only added when coming from a Tastecard integration
 */
export const useRetrieveEventParameters = () => {
	const dispatch = useDispatch();
	const subDomain = getSubdomain(window.location.href);

	// Get tastecard promo id from state
	const { eventId, promoId } = useSelector(
		(state: RootState) => state.discount,
	);

	// Get data from url params
	const { search } = useLocation();
	const eventIdParameter = new URLSearchParams(search).get('eventID');
	if (eventIdParameter) {
		const eventData = eventIdParameter.split('_');

		if (
			eventData.length === 2 &&
			eventId !== eventData[0] &&
			promoId !== eventData[1]
		) {
			let newEventId = eventData[0];
			const newPromoId = eventData[1];

			if (subDomain && subDomain.includes('gbk-o2')) {
				newEventId = newEventId.concat('-o2');
			}
			dispatch(setEventData(newEventId, newPromoId));
		}
	}
};
