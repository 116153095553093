import { SweetAlertOptions } from 'sweetalert2';

import { intl } from '../i18n/i18n.config';

/** Default dialog configuration */
const dialogConfig: SweetAlertOptions = {
	reverseButtons: false,
	customClass: {
		container: 'swal-container',
		popup: 'swal-popup',
		header: 'swal-header',
		title: 'swal-title',
		closeButton: 'swal-button-close',
		icon: 'swal-icon',
		image: 'swal-image',
		content: 'swal-content',
		input: 'swal-input',
		actions: 'swal-action-container',
		confirmButton: 'swal-button-confirm',
		cancelButton: 'swal-button-cancel',
		footer: 'swal-footer',
	},
	confirmButtonText: intl.formatMessage({
		id: 'sweetAlert.dialog.button.confirm',
	}),
	target: '#dialog-target',
};

export default dialogConfig;
