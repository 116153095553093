/** Check if given string is a member of a string enum */
export const isSomeEnumValue = <T extends { [k: string]: string }>(
	something: unknown,
	enumObject: T,
): something is T[keyof T] =>
	typeof something === 'string' &&
	Object.values(enumObject).includes(something);

/** Remove punctuation from the end of a string */
export const trimPunctuation = (content: string) =>
	[',', '!', ',', '?', ':', ';', "'", '"'].some((suffix) =>
		content.endsWith(suffix),
	)
		? content.slice(0, -1)
		: content;

/** Truncate a string and add an ellipsis */
export const truncateWords = (
	content: string,
	wordLimit: number,
	elipsis: boolean = true,
) => {
	if (content.split(' ').length <= wordLimit) {
		return content;
	}

	// Truncate the text
	let text = content.split(' ').slice(0, wordLimit).join(' ');
	// Remove punctuation
	text = [',', '!', ',', '?', ':', ';', "'", '"'].some((suffix) =>
		text.endsWith(suffix),
	)
		? text.slice(0, -1)
		: text;
	// Return with ellipsis
	return elipsis ? `${text}...` : text;
};

/** Truncate a string and add an ellipsis */
export const truncateCharacters = (
	input: string,
	characterLimit: number,
	elipsis: boolean = true,
) => {
	if (input.length > characterLimit) {
		return elipsis
			? `${input.substring(0, characterLimit)}...`
			: `${input.substring(0, characterLimit)}`;
	}
	return input;
};

/** Add zero padding to a number */
export const zeroPad = (num: number, places: number) => {
	const zero = places - num.toString().length + 1;
	return Array(+(zero > 0 && zero)).join('0') + num;
};

/** Convert string to kebab-case */
export const kebabCase = (str?: string) =>
	str &&
	str
		.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
		?.map((x) => x.toLowerCase())
		.join('-');

/** Convert string to camelCase */
export const camelCase = (str?: string) =>
	str &&
	str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

/** Convert string to PascalCase */
export const pascalCase = (str?: string) =>
	str &&
	str
		.match(/[a-z]+/gi)
		?.map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
		.join('');

/** Convert string to human case - all lowercase and spaces */
export const humanCase = (str?: string) =>
	str &&
	str
		.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
		?.map((x) => x.toLowerCase())
		.join(' ');

/** Convert string to Title case - first letter of each word uppercase */
export const titleCase = (str?: string) =>
	str &&
	str
		.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
		?.map((x) => x.toLowerCase())
		.join(' ')
		.replace(
			/\w\S*/g,
			(m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase(),
		);

/** First character of string to capital */
export const capitalize = (str: string) => {
	if (typeof str !== 'string') return '';
	return str.charAt(0).toUpperCase() + str.slice(1);
};

/** Convert metres to miles to 1 decimal place */
export const metresToMiles = (distance: number) =>
	Math.round((distance * 0.000621371192 + Number.EPSILON) * 10) / 10;

/** Shallow removal of duplicates from an array, preserving the order of the first instance of the item. */
export const uniqueArray = <T>(arr: T[]): T[] => Array.from(new Set(arr));

/** Returns the users preferred lacales. */
export const getUserlocales = (): string[] => {
	const defaults = ['en-GB'];

	// Ideally use languages to get users list of preferences.
	if (window.navigator.languages) {
		return uniqueArray([...window.navigator.languages, ...defaults]);
	}

	// If full list of languages isn't availble, then return their only language and the defaults
	if (window.navigator.language) {
		return uniqueArray([window.navigator.language, ...defaults]);
	}

	// Must be cast to any as these properties aren't on the modern navigator definition
	// @ts-ignore
	const navigator = (window.navigator as unknown) as { [key: string]: string };
	const fallback =
		navigator.userLanguage ||
		navigator.browserLanguage ||
		navigator.systemLanguage;

	return uniqueArray([fallback, ...defaults]);
};
