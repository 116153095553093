import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import AskForAngelaBase from '../ask-for-angela-base/ask-for-angela-base.component';

import brand from 'assets/styles/variables/brand';
import { ReactComponent as Logo } from 'assets/svg/ask-for-angela/ask-for-angela-logo.svg';

const StyledButtonInner = styled.span`
	display: block;
	padding: 6px 8px 4px;
	background: ${brand.askForAngela};
	border-radius: 6px;
	transition: 0.1s background-color;
`;
const StyledButton = styled(AskForAngelaBase)`
	cursor: pointer;
	padding: 10px;
	position: absolute;
	right: 0;
	top: 0;
	display: block;
	border: none;
	background: none;
	outline: none;
	&:focus-visible ${StyledButtonInner} {
		outline: 2px solid black;
	}
	&:hover ${StyledButtonInner} {
		background: ${brand.askForAngelaDark};
	}
`;

const StyledLogo = styled(Logo)`
	width: 84px;
	height: 12px;
	path {
		fill: ${brand.white};
	}
`;

const AskForAngelaButton: FunctionComponent = () => {
	return (
		<StyledButton onVenue>
			<StyledButtonInner>
				<StyledLogo />
			</StyledButtonInner>
		</StyledButton>
	);
};

export default AskForAngelaButton;
