import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IServiceWaitTimeState } from './service-wait-time.types';

export const initialServiceWaitTimeState: IServiceWaitTimeState = {
	eventsInProgress: 0,
	hasSeenWaitTimeSheet: false,
};

const serviceWaitTimeSlice = createSlice({
	name: 'serviceWaitTime',
	initialState: initialServiceWaitTimeState,
	reducers: {
		GET_WAIT_TIMES(state, _) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress + 1,
			};
		},
		GET_WAIT_TIMES_SUCCESS(state, action) {
			return {
				...state,
				food: {
					displayUntil: action.payload.data.foodWaitingUntil,
					enabled: action.payload.data.foodWaitingEnabled,
					waitTime: action.payload.data.foodWaitingTime,
				},
				drink: {
					displayUntil: action.payload.data.drinksWaitingUntil,
					enabled: action.payload.data.drinksWaitingEnabled,
					waitTime: action.payload.data.drinksWaitingTime,
				},
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		GET_WAIT_TIMES_FAIL(state) {
			return {
				...state,
				eventsInProgress: state.eventsInProgress - 1,
			};
		},
		SET_HAS_SEEN_SHEET(state, action) {
			return {
				...state,
				hasSeenWaitTimeSheet: action.payload,
			};
		},
		RESET_WAIT_TIMES() {
			return initialServiceWaitTimeState;
		},
	},
});

export const {
	GET_WAIT_TIMES,
	GET_WAIT_TIMES_FAIL,
	GET_WAIT_TIMES_SUCCESS,
	SET_HAS_SEEN_SHEET,
	RESET_WAIT_TIMES,
} = serviceWaitTimeSlice.actions;

/** Get service wait times for a venue */
export const getServiceWaitTimes = (id?: string) => async (
	dispatch: Dispatch,
) =>
	dispatch(
		GET_WAIT_TIMES({
			request: {
				method: 'get',
				url: `1/venues/${id}/waiting-times`,
			},
		}),
	);

/** Set has seen sheet */
export const setHasSeenWaitTimeSheet = (hasSeen: boolean) => (
	dispatch: Dispatch,
) => dispatch(SET_HAS_SEEN_SHEET(hasSeen));

/** Reset wait time state */
export const resetWaitTimeState = () => (dispatch: Dispatch) =>
	dispatch(RESET_WAIT_TIMES);

export default serviceWaitTimeSlice.reducer;
