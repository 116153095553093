import React, { FunctionComponent, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import brand from 'assets/styles/variables/brand';
import fonts from 'assets/styles/variables/fonts';
import { mq } from 'assets/styles/variables/responsive';
import Icon from 'components/icons/icon.component';
import { TIcons } from 'components/icons/icons.config';

const navItemStyles = css`
	display: flex;
	align-items: center;
	background: transparent;
	border: none;
	height: 50px;
	padding: 0;
	border-radius: 0;
	width: 100%;
	font-size: ${fonts.sizes.large};
	color: ${brand.text};
	text-decoration: none;
	cursor: pointer;
	margin-bottom: 4px;
	font-weight: ${fonts.weights.default};
	.icon {
		margin-right: 6px;
		flex: 0 0 auto;
		opacity: 0.5;
	}
	.chevron {
		margin-left: auto;
		flex: 0 0 auto;
	}
	&:hover,
	&:focus-visible {
		text-decoration: none;
		.icon {
			opacity: 0.7;
		}
	}
	&.disabled,
	&:disabled {
		pointer-events: none;
		cursor: not-allowed;
		opacity: 0.6;
	}
	${mq.mobile`
		font-size: ${fonts.sizes.med};
	`}
`;

const StyledNavLink = styled(Link)`
	${navItemStyles}
`;
const StyledNavBtn = styled.button`
	${navItemStyles}
`;

interface IComponentProps {
	url?: string;
	onClick?: (event: MouseEvent) => void;
	messageId: string;
	icon: TIcons;
	disabled?: boolean;
}

const NavItem: FunctionComponent<IComponentProps> = ({
	url,
	onClick,
	messageId,
	icon,
	disabled,
}) => {
	// This will be the same regardless
	const content = (
		<>
			<Icon className="icon" width={30} height={14} name={icon} />
			<span>
				<FormattedMessage id={messageId} />
			</span>
			<Icon className="chevron" width={14} height={14} name="chevron" />
		</>
	);

	if (url) {
		return (
			<StyledNavLink
				onClick={onClick}
				to={url || '/'}
				className={disabled ? 'disabled' : ''}
			>
				{content}
			</StyledNavLink>
		);
	}

	return (
		<StyledNavBtn onClick={onClick} type="button" disabled={disabled}>
			{content}
		</StyledNavBtn>
	);
};

export default NavItem;
