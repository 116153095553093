import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledDot = styled.path`
	transform-origin: 6px 6px;
	animation: dotDraw 2s infinite;

	@keyframes dotDraw {
		0% {
			transform: scale(1);
		}
		87.5% {
			transform: scale(1);
		}
		90% {
			transform: scale(0.75);
		}
		93% {
			transform: scale(1);
		}
		97% {
			transform: scale(0.75);
		}
		100% {
			transform: scale(1);
		}
	}
`;

const StyledLine = styled.path`
	animation: lineDraw 2s infinite;

	@keyframes lineDraw {
		0% {
			stroke-dashoffset: -98px;
			stroke-dasharray: 5px 98px;
		}
		10% {
			stroke-dashoffset: -98px;
			stroke-dasharray: 5px 98px;
		}
		55% {
			stroke-dashoffset: 0px;
			stroke-dasharray: 98px 98px;
		}
		100% {
			stroke-dashoffset: 0px;
			stroke-dasharray: 0px 98px;
		}
	}
`;

/** Renders logo animation component */
const LogoAnimation: FunctionComponent = () => (
	<svg fill="none" viewBox="0 0 46 46">
		<StyledDot
			className="dot"
			fill="currentColor"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="4"
			d="M8.33 6.76c.891 0 1.61.724 1.61 1.6 0 .876-.719 1.6-1.61 1.6-.89 0-1.61-.724-1.61-1.6 0-.876.72-1.6 1.61-1.6z"
		/>
		<StyledLine
			className="line"
			stroke="currentColor"
			strokeLinecap="round"
			strokeWidth="4"
			d="M2.2 22.981a20.642 20.642 0 006.095 14.695 20.692 20.692 0 0014.709 6.086 20.694 20.694 0 0014.712-6.087c8.113-8.104 8.112-21.285 0-29.388A20.69 20.69 0 0023.004 2.2"
		/>
	</svg>
);
export default LogoAnimation;
