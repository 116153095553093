import React, { useEffect } from 'react';

import customEvent, { ECustomEvent } from '../events/custom.event';

import useIntersectionObserver from 'hooks/use-intersection-observer.hook';

// Tags supported by this element
type TTags = 'div' | 'span' | 'button' | 'a';

// Types for the elementProps argument
interface IElementProps<T extends TTags>
	extends React.HTMLProps<HTMLElementTagNameMap[T]> {
	'data-testid'?: string;
}

// Generic is used to add correct typing to ref and elementProps. extends TTags constrians the acceptable tags
interface IComponentProps<T extends TTags>
	extends React.PropsWithChildren<unknown> {
	// Tag to render as
	as: T;
	// Event to trigger
	event: ECustomEvent;
	// Adds support for styled components
	className?: string;
	// Options to pass to intersection observer
	observerOptions?: IntersectionObserverInit;
	// Props to forward onto the element
	elementProps?: IElementProps<T>;
	// IF the event should be tracked multiple times per session
	multiple?: boolean;
}

/**
 * Tracks impressions using a custom event when the element is visible to the user
 * This element is isomorphic and can render one of 'div' | 'span' | 'button' | 'a'.
 * Support styled components `styled(TrackImpression)`
 */
const TrackImpression = <T extends TTags>({
	// Need to use uppercase variable name for react to use it as an element
	as: Tag,
	children,
	event,
	className,
	observerOptions,
	elementProps,
	multiple,
}: IComponentProps<T>) => {
	const { ref, isVisible } = useIntersectionObserver<HTMLElementTagNameMap[T]>(
		observerOptions,
	);

	useEffect(() => {
		isVisible && customEvent(event, multiple);
	}, [isVisible]);

	return (
		// TS ignore required as ref typing wasn't infering correctly.
		// @ts-ignore
		<Tag className={className} ref={ref} {...elementProps}>
			{children}
		</Tag>
	);
};

// defaultProps defined to satisfy linting rules
TrackImpression.defaultProps = {
	className: '',
	observerOptions: undefined,
	elementProps: {},
	multiple: false,
};

export default TrackImpression;
