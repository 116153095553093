import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getVenue, resetVenueState } from '../venue.slice';
import { ETableServiceFeature, IVenue } from '../venue.types';
import {
	checkServiceTypeAvailability,
	formatSelectedTableServiceFeature,
	setTableServiceFeature,
} from './helpers/configure-session.helper';
import { handleExistingSession } from './helpers/existing-session.helper';
import handleVenueLinkError from './helpers/handle-venue-link-error.helper';
import { createBasketSession } from './helpers/venue-link-basket.helper';

import NoResults from 'components/no-results/no-results.component';
import { useReduxDispatch } from 'helpers/use-redux-dispatch.helper';
import { useRetrieveEventParameters } from 'hooks/url-parameters.hook';
import useOnpl from 'hooks/use-onpl.hook';
import GenericError from 'modules/core/error/generic-error.class';
import { RootState } from 'modules/core/state/root.reducer';
import { resetDiscountState } from 'modules/discounts/discount.slice';

const VenueLinkPage: FunctionComponent = () => {
	// Get hooks
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useReduxDispatch();

	const { isOnpl } = useOnpl();

	const { venue: basketVenue, items: basketItems, fullBasket } = useSelector(
		(state: RootState) => state.basket,
	);

	// Get data from url params
	const {
		venueId,
		serviceType,
		tableServiceFeature,
		tableNumber,
		posId,
	} = useParams<{
		venueId: string;
		serviceType: string;
		tableServiceFeature: string;
		tableNumber: string;
		posId: string;
	}>();

	useRetrieveEventParameters();

	/** Turn supplied URL parameter into keyof typeof ETableServiceFeature */
	const selectedTableServiceFeature = React.useMemo<
		keyof typeof ETableServiceFeature | undefined
	>(() => formatSelectedTableServiceFeature(tableServiceFeature), [
		tableServiceFeature,
	]);

	/**
	 * Parses the URL, retrives data and routes through functions to set state, etc.
	 * */
	const handleVenueLink = async () => {
		let selectedServiceType = serviceType;
		if (!serviceType && tableNumber) {
			selectedServiceType = 'table-service';
		}

		// Reset the venue state
		dispatch(resetVenueState());

		try {
			// Get venue details
			const venueData: IVenue = await dispatch(getVenue(venueId));

			if (!venueData) {
				throw new GenericError({
					error: {
						name: 'venueLinkError - handleVenueLink',
						message: 'Venue not found',
					},
					dialog: {
						title: intl.formatMessage({
							id: 'errors.general.title',
						}),
						text: intl.formatMessage({
							id: 'errors.venue.notFound.title',
						}),
					},
				});
			}

			// Clear Tastecard promotion
			await dispatch(resetDiscountState());

			// Return user to existing basket/order if one exists
			const hasExistingSession = await handleExistingSession(
				dispatch,
				history,
				basketItems,
				fullBasket,
				venueData,
				basketVenue,
			);
			if (hasExistingSession) return;

			// Get services from venueData
			const { services } = venueData;

			if (!services) {
				throw new GenericError({
					error: {
						name: 'venueLinkError - handleVenueLink',
						message: 'Venue has no services',
					},
					dialog: {
						title: intl.formatMessage({
							id: 'errors.general.title',
						}),
						text: intl.formatMessage({
							id: 'errors.venueLink.noServices',
						}),
					},
				});
			}

			// Check service type is available
			await checkServiceTypeAvailability(
				dispatch,
				venueId,
				services,
				selectedServiceType,
			);

			// Create basket for click and collect
			if (selectedServiceType === 'click-and-collect') {
				createBasketSession(
					dispatch,
					history,
					venueData,
					'clickAndCollect',
					'OrderAndPay',
				);
				return;
			}

			// Setup and create basket for table service
			if (selectedServiceType === 'table-service') {
				const paymentOption = setTableServiceFeature(
					services,
					selectedTableServiceFeature,
				);

				createBasketSession(
					dispatch,
					history,
					venueData,
					'tableService',
					paymentOption,
					tableNumber,
					posId,
					isOnpl || venueData.services?.tableService?.onplEnabled,
				);

				return;
			}

			throw new GenericError({
				error: {
					name: 'venueLinkError - handleVenueLink',
					message: 'Requested service type not supported',
				},
				dialog: {
					title: intl.formatMessage({
						id: 'errors.general.title',
					}),
					text: intl.formatMessage({
						id: 'errors.venueLink.serviceType',
					}),
				},
			});
		} catch (error: unknown) {
			handleVenueLinkError(history, venueId, error);
		}
	};

	// On page load
	useEffect(() => {
		handleVenueLink();
	}, []);

	return (
		<NoResults
			titleLocale="venue.link.title"
			textLocale="venue.link.text"
			fullHeight={true}
		/>
	);
};

export default VenueLinkPage;
